<!--
Fire Roll Call Home Page Button

Renders an expansion panel with a link to the Fire Roll Call.
-->
<template>
    <v-expansion-panels
        v-if="GET_currentUser.userLevel === 'SA' || GET_currentUser.userAccessAndAbilities.includes('Fire Marshall')"
        flat>
        <v-expansion-panel class="rounded-lg">

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex align-center">

                    <!--Icon-->
                    <v-icon class="icons8-fires ma-n1" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">Fire Roll Call</app-text>

                </div>

                <!--Text-->
                <app-text class="mt-4">Start a fire Roll call</app-text>


            </v-expansion-panel-header>

            <!--Body-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Action Button-->
                <app-btn @click.native="MIX_go('/fireRollCall')"
                         block
                         :height="buttonSizeDefault"
                         label="Fire Roll Call"/>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "fireRollCallHomePageCard",

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),
    },

}
</script>
