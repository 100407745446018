<template>
    <div class="viewport-maxwidth">
        <v-progress-linear v-if="loading === true" indeterminate color="primary"></v-progress-linear>

        <!-- Start of USER-INPUT -->
        <v-row class="justify-center" no-gutters>
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-window v-model="step">

                    <!--Step 1 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Step 1-->

                    <v-window-item :value="1">

                        <v-form ref="registerUser" v-model="valid" lazy-validation>

                            <!--User Type-->
                            <v-select @change="handleUserTypeSelection"
                                      :background-color="inputBackgroundColor"
                                      class="rounded-lg mb-4 pt-4"
                                      :error="errors.tempUserLevel"
                                      :error-messages="errors.tempUserLevelErrorMessage"
                                      filled
                                      flat
                                      hide-details="auto"
                                      :items="userTypes"
                                      label="User Type"
                                      outlined
                                      v-model="tempUserLevel"/>

                            <!--Position-->
                            <app-input v-if="isPositionVisible"
                                       input-type="textInput"
                                       class="mb-4"
                                       :error="errors.userPosition"
                                       :error-messages="errors.userPositionErrorMessage"
                                       label="Position"
                                       v-model.trim="form.userPosition"/>

                            <!--Email-->
                            <app-input input-type="textInput"
                                       class="mb-4"
                                       :error="errors.userEmail"
                                       :error-messages="errors.userEmailErrorMessage"
                                       label="Email"
                                       v-model.trim="form.userEmail"/>

                            <!--Name-->
                            <app-input input-type="textInput"
                                       class="mb-4"
                                       :error="errors.userName"
                                       :error-messages="errors.userNameErrorMessage"
                                       label="Full Name"
                                       v-model.trim="form.userName"/>

                            <!--Contact Number-->
                            <app-input input-type="textInput"
                                       class="mb-4"
                                       :error="errors.userTelephone"
                                       :error-messages="errors.userTelephoneErrorMessage"
                                       label="Contact Number"
                                       v-model.trim="form.userTelephone"/>

                            <!--Admin Reg Code-->
                            <v-text-field v-if="$route.params.id ==='admin'"
                                          :background-color="inputBackgroundColor"
                                          class="mb-1"
                                          filled
                                          label="Admin Registration Code"
                                          v-model="adminRegCode"/>

                            <!--Terms and Conditions-->
                            <v-checkbox class="d-flex align-start mt-4"
                                        color="primary"
                                        :error="errors.privacyPolicyConsent"
                                        hide-details
                                        required
                                        v-model="form.privacyPolicyConsent">
                                <template v-slot:label>
                                    <div>
										<span class="font-weight-bold"> {{ $t('firebaseAuth.register.terms') }}
											<span @click='privacyDialog = true' class="primary--text font-weight-bold">
												{{ $t('firebaseAuth.register.termsLink') }}</span>
										</span>
                                    </div>
                                </template>
                            </v-checkbox>

                        </v-form>

                        <!--Action buttons-->
                        <v-row no-gutters class="mt-8 mb-4">

                            <!--Login-->
                            <v-col :class="$vuetify.breakpoint.width < 400 ? 'mb-4' : 'pr-2'"
                                   :cols="$vuetify.breakpoint.width < 400 ? 12 : 6">
                                <app-btn @click.native="MIX_go('/login')"
                                         block
                                         color="grey"
                                         label="Back to Login"/>
                            </v-col>

                            <v-spacer/>

                            <!--Next-->
                            <v-col :class="$vuetify.breakpoint.width < 400 ? 'mb-0' : 'pl-2'"
                                   :cols="$vuetify.breakpoint.width < 400 ? 12 : 6">
                                <app-btn @click.native="validateForm"
                                         block
                                         color="primary"
                                         label="Next"/>
                            </v-col>

                        </v-row>

                    </v-window-item>

                    <!--Step 2 - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Step 2-->

                    <v-window-item :value="2">

                        <v-form ref="userPassword" v-model="valid1" lazy-validation>

                            <!--Password-->
                            <v-text-field @click:append="showPasswordIcon = !showPasswordIcon"
                                          :append-icon="showPasswordIcon ? 'icons8-eye' : 'icons8-invisible'"
                                          :background-color="inputBackgroundColor"
                                          class="rounded-lg mb-4"
                                          :error="passwordErrors.password"
                                          :error-messages="passwordErrors.passwordErrorMessage"
                                          filled
                                          flat
                                          hide-details="auto"
                                          :label="$t('fields.password')"
                                          outlined
                                          required
                                          :type="showPasswordIcon ? 'text' : 'password'"
                                          v-model.trim="password"/>

                            <!--Confirm Password-->
                            <v-text-field @click:append="showPasswordIcon1 = !showPasswordIcon1"
                                          :append-icon="showPasswordIcon1 ? 'icons8-eye' : 'icons8-invisible'"
                                          :background-color="inputBackgroundColor"
                                          class="rounded-lg"
                                          :error="passwordErrors.confirmPassword"
                                          :error-messages="passwordErrors.confirmPasswordErrorMessage"
                                          filled
                                          flat
                                          hide-details="auto"
                                          :label="$t('fields.confirmPassword')"
                                          outlined
                                          required
                                          :type="showPasswordIcon1 ? 'text' : 'password'"
                                          v-model.trim="confirmPassword"/>

                        </v-form>

                        <!--Buttons-->
                        <v-row class="my-4">

                            <!--Back-->
                            <v-col cols="6">
                                <app-btn @click.native="step--"
                                         block
                                         color="grey"
                                         label="Back"/>
                            </v-col>

                            <v-spacer/>

                            <!--Register-->
                            <v-col cols="6">
                                <app-btn @click.native="validatePasswords"
                                         block
                                         color="primary"
                                         :disabled="loading"
                                         label="Register"
                                         :loading="loading"/>
                            </v-col>

                        </v-row>

                    </v-window-item>

                    <!-- Error message -->
                    <v-window-item :value="3">
                        <div class="pa-4 text-center" v-if="accountCreated === true">
                            <v-progress-linear indeterminate color="primary"></v-progress-linear>
                            <h3 class="title font-weight-light mb-2">Welcome</h3>
                            <span class="caption grey--text">Thanks for signing up! You will be redirected to the home page</span>
                        </div>
                        <div class="pa-4 text-center" v-else>
                            <h3 class="title font-weight-light mb-2">Something went wrong</h3>
                            <h4 v-if="registerErrorMessage != null">{{ registerErrorMessage }}</h4>
                            <span class="caption grey--text">Try again!</span>
                        </div>
                    </v-window-item>

                </v-window>
            </v-col>
        </v-row>

        <!-- End of USER-INPUT -->
        <v-dialog style="z-index: 3000 !important;" v-model="privacyDialog" fullscreen hide-overlay
                  transition="dialog-bottom-transition">
            <v-card class="background">
                <v-toolbar dark color="secondary heading5 black--text">Privacy and Terms & Conditions

                    <v-spacer/>

                    <v-icon class="black--text" @click="privacyDialog = false">mdi-close</v-icon>

                </v-toolbar>

                <PrivacyTermsConditions/>

                <v-divider/>

            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import PrivacyTermsConditions from "@/components/termsAndConditions.vue";
import PhotoUpload_button_component from "../photoUpload/photoUpload_button_component";

export default {
    name: "Register",

    components: {
        PhotoUpload_button_component,
        PrivacyTermsConditions,
    },

    data() {
        return {
            accountCreated: true, // show thank you/ try gain message at the end
            adminRegCode: '',
            confirmPassword: '', // check password
            errors: {
                tempUserLevel: false,
                tempUserLevelErrorMessage: '',
                userEmail: false,
                userEmailErrorMessage: '',
                userName: false,
                userNameErrorMessage: '',
                userPosition: false,
                userPositionErrorMessage: '',
                userTelephone: false,
                userTelephoneErrorMessage: '',
                privacyPolicyConsent: false,
            },
            form: {
                id: '',
                authId: '',
                lastLocation: {
                    locationId: '',
                    locationName: ''
                },
                lastSite: {
                    siteId: '',
                    siteName: ''
                },
                privacyPolicyConsent: false,
                profilePicFileURL: null,
                swappMethod: null,
                swappStatus: 0,
                userAccessAndAbilities: [],
                userAddress: {
                    userAddressLine1: '',
                    userAddressLine2: '',
                    userAddressLine3: '',
                    userTown: '',
                    userCounty: '',
                    userPostcode: ''
                },
                userEmail: '',
                userPosition: '',
                userLevel: '',
                userName: '',
                userRole: '',
                usualSite: '',
                userStatus: 'PENDING',
                userTelephone: '',
                userType: '',
                warriorsTeam: '',
                createdDateTime: '',
                createdUserData: {
                    userEmail: '',
                    userId: '',
                    userName: ''
                },
                modifiedDateTime: '',
                modifiedUserData: {
                    userEmail: '',
                    userId: '',
                    userName: ''
                }
            },
            isPositionVisible: false,
            inputBackgroundColor: 'white',
            loading: false,
            loader1: null,
            password: '', // password input
            passwordErrors: {
                password: false,
                passwordErrorMessage: '',
                confirmPassword: false,
                confirmPasswordErrorMessage: '',
                passwordMatch: false,
            },
            privacyDialog: false,
            registerErrorMessage: null,
            showPasswordIcon: false,
            showPasswordIcon1: false,
            step: 1,
            tempUserLevel: '',
            valid: true,
            valid1: true,

            //check if user was created in admin dashboard
            userInLocalStorage: false,
            userInLocalStorageId: null,

            userTypes: [
                'Staff',
                'Contractor',
                'Visitor',
                'Warriors',
                'Warriors Women',
            ]
        };
    },

    methods: {

        /**
         * Handle User Type Selection
         *
         * Set the user level code depending on the user type selection.
         * Toggle job title field visibility.
         */
        handleUserTypeSelection() {
            const t = this
            let tempUserLevel = t.tempUserLevel

            // Tag the Warriors team as Mens or Womens Players
            if (t.tempUserLevel === 'Warriors') {
                t.form.warriorsTeam = 'Mens'
                tempUserLevel = 'Player'
            } else if (t.tempUserLevel === 'Warriors Women') {
                t.form.warriorsTeam = 'Womens'
                tempUserLevel = 'Player'
            } else {
                t.form.warriorsTeam = ''
            }

            t.form.userType = tempUserLevel
            t.form.userRole = 'User'
            t.form.userLevel = tempUserLevel.split('')[0] + 'U'
            t.isPositionVisible = !['Visitor'].includes(tempUserLevel)

            // Auto approve visitors
            if (t.form.userType === 'Visitor') {
                t.form.userStatus = 'APPROVED'
            }

            // If the User is a Contractor, check for a id token
            if (t.form.userType === 'Contractor') {
                t.checkUrlParams()
            }
        },

        /**
         * Check URL Params
         *
         * If the registering user is a Contractor, check if they have an invitation link.
         * If they have, add it to the User object.
         */
        checkUrlParams() {
            const t = this
            const idToken = t.$route.params ? t.$route.params : ''

            if (idToken) {
                t.form.contractorCompanyId = idToken.id
            }

        },

        /**
         * Validate Form
         *
         * Validate form fields as required.
         * If there are no errors, call to next().
         * If there are errors render them in their respective fields.
         */
        validateForm() {
            const t = this

            t.errors.userEmail = false
            t.errors.userName = false
            t.errors.userTelephone = false
            t.errors.tempUserLevel = false
            t.errors.userPosition = false
            t.errors.privacyPolicyConsent = false

            // No email is present
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is required'
            }
            // Email is not valid
            else if (!/.+@.+\..+/.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email is not valid'
            }

            // No name is present
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name is required'
            }

            // No contact number is present
            if (!t.form.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Contact number is required'
            }
            // Contact landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) && (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Contact mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Contact numbers must start 01, 02, 03 or 07
            else if (['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // No user type has been selected
            if (!t.tempUserLevel) {
                t.errors.tempUserLevel = true
                t.errors.tempUserLevelErrorMessage = 'User type is required'
            }

            // Is a staff member but no job title has been selected
            if (!['VISITOR'].includes(t.tempUserLevel.toUpperCase()) && !t.form.userPosition.trim()) {
                t.errors.userPosition = true
                t.errors.userPositionErrorMessage = 'Job Title is required'
            }

            // If no job title has been selected
            if (!t.form.privacyPolicyConsent) {
                t.errors.privacyPolicyConsent = true
            }

            // If there are no errors, call for the next section
            if (!Object.values(t.errors).includes(true)) {
                t.next()
            }

        },

        next: async function () {
            if (this.$refs.registerUser.validate()) {

                if (this.$route.params.id === 'admin') {

                    let result = await this.MIX_FIREBASE_checkAdminRegCode(this.adminRegCode)

                    if ((result.code === 1) && (result.data === true)) {
                        this.form.userLevel = 'SA'
                        this.form.userRole = 'Admin';
                        this.form.userType = 'Staff';
                        this.step++; // * move to the next step
                    } else {
                        this.MIX_alert(-1, 'Admin Registration Code is Incorrect, please try again.')
                    }

                } else {
                    this.step++; // * move to the next step
                }

            } else {
                // ! did not pass validation
                this.MIX_alert(-1, 'Fill out all the fields correctly in order to proceed.', null, null);
            }
        },

        async checkUserInLocalStorage(email) {
            const t = this

            return new Promise(function (resolve, reject) {
                t.$firebase.db.collection("users").where('userEmail', '==', email).get().then(function (querySnapshot) {
                    if (querySnapshot.docs.length === 0) {
                        t.userInLocalStorage = false;
                        return resolve(t.userInLocalStorage);
                    } else {
                        t.userInLocalStorage = true;
                        t.userInLocalStorageId = querySnapshot.docs[0].id;
                        return resolve(t.userInLocalStorage);
                    }
                })
                    .catch(function (error) {
                        return reject(error)
                    });
            });
        },

        setUserDetails(uid) {
            const t = this

            t.form.id = uid // * assign firebase auth user id to firebase user in collection
            t.form.createdDateTime = t.$moment().format("x")
            t.form.createdUserData.userEmail = t.form.userEmail
            t.form.createdUserData.userName = t.form.userName
            t.form.createdUserData.userId = uid
        },

        sendVerificationEmail(currUser) {
            const t = this;

            currUser.sendEmailVerification()
                .then(function () {
                    t.MIX_alert(2, "An account verification email has been sent to you.", null, null)
                })
                .catch(error => {
                    // ! verification email error
                    console.log('Send verification email error: ', error)
                    t.MIX_alert(-1, error, null, null)
                })
        },

        redirectToHomepage() {
            const t = this
            setTimeout(() => {
                t.$router.push("/").catch(error => {
                    console.log(error.message)
                });
            }, 1500); // ? added time out to allow the current user to be set
        },

        /**
         * Validate Passwords
         *
         * Validate password fields as required.
         * If there are no errors, call to register().
         * If there are errors render them in their respective fields.
         */
        validatePasswords() {
            const t = this

            // Reset any errors
            t.passwordErrors.password = false
            t.passwordErrors.passwordErrorMessage = ''
            t.passwordErrors.confirmPassword = false
            t.passwordErrors.confirmPasswordErrorMessage = ''
            t.passwordErrors.passwordMatch = false

            // No password is present
            if (!t.password.trim()) {
                t.passwordErrors.password = true
                t.passwordErrors.passwordErrorMessage = 'Password is required'
            }
            // Password is less than 6 characters
            else if (t.password.trim().length < 6) {
                t.passwordErrors.password = true
                t.passwordErrors.passwordErrorMessage = 'Password must be 6 characters or more'
            }

            // No confirm password is present
            if (!t.confirmPassword.trim()) {
                t.passwordErrors.confirmPassword = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Password confirmation is required'
            }
            // Confirm password is less than 6 characters
            else if (t.confirmPassword.trim().length < 6) {
                t.passwordErrors.confirmPassword = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Password must be 6 characters or more'
            }

            // Passwords don't match
            if (t.password !== t.confirmPassword) {
                t.passwordErrors.passwordMatch = true
                t.passwordErrors.confirmPasswordErrorMessage = 'Passwords don\'t match'
            }

            // If there are no errors, call to register
            if (!Object.values(t.passwordErrors).includes(true)) {
                t.register()
            }

        },

        async register() {
            const t = this

            // if (this.$refs.userPassword.validate()) {

                this.step++ // * move to the next step
                t.form.userEmail = t.form.userEmail.toLowerCase()

                // * CHECK IF USER WAS CREATED FROM THE ADMIN DASHBOARD
                const userExists = await this.checkUserInLocalStorage(t.form.userEmail)

                // * if the user exists in the db
                if (userExists) {

                    // * create user in authentication
                    t.$firebase.auth.createUserWithEmailAndPassword(t.form.userEmail, t.password)
                        .then(user => {

                            // * set user details
                            t.setUserDetails(user.user.uid)

                            // * update the user from the collection
                            const fields = {
                                id: t.userInLocalStorageId,
                                authId: user.user.uid,
                                modifiedDateTime: t.form.modifiedDateTime,
                                modifiedUserData: t.form.modifiedUserData,
                                userName: t.form.userName,
                                userPosition: t.form.userPosition,
                                userTelephone: t.form.userTelephone,
                                userLevel: t.form.userLevel,
                                userRole: t.form.userRole,
                                userType: t.form.userType
                            };

                            t.$firebase.db.collection("users").doc(t.userInLocalStorageId).update(fields).then((Result) => {
                                this.MIX_alert(1, 'Your account was created.', null, null);
                                t.$store.commit("SET_currentUser", user);
                            }).catch((err) => {
                                this.MIX_alert(-1, err, null, null);
                                console.log(err);
                            });

                            t.accountCreated = true;
                            // * assign this user to the current logged in user
                            t.$store.commit("SET_currentUser", user);
                            this.MIX_alert(1, "Your account was created.", null, null);

                            // * send verification email to user
                            t.sendVerificationEmail(t.$firebase.auth.currentUser);

                            // * redirect user to homepage
                            t.redirectToHomepage();
                        })
                        .catch((err) => {
                            // ! firebase registering error
                            t.registerErrorMessage = err;
                            t.accountCreated = false;
                            console.log("firebase registering error: " + err);
                            t.MIX_alert(-1, err, null, null);
                        });
                } else {
                    // * create user
                    t.$firebase.auth.createUserWithEmailAndPassword(t.form.userEmail, t.password).then((user) => {
                        // * set user details
                        t.setUserDetails(user.user.uid);

                        // * add to the users collection
                        t.form.authId = user.user.uid;
                        t.$firebase.db.collection("users").doc(user.user.uid).set(t.form).then((Result) => {
                        }).catch((err) => {
                            console.log(err);
                        });

                        t.accountCreated = true;
                        // * assign this user to the current logged in user
                        t.$store.commit("SET_currentUser", user);
                        this.MIX_alert(1, "Your account was created.", null, null);

                        // * send verification email to user
                        t.sendVerificationEmail(t.$firebase.auth.currentUser);

                        // * redirect user to homepage
                        t.redirectToHomepage();
                    })
                        .catch((err) => {
                            // ! firebase registering error
                            t.registerErrorMessage = err;
                            t.accountCreated = false;
                            console.log("firebase registering error: " + err);
                            t.MIX_alert(-1, err, null, null);
                        });
                }

            // } else {
            //     // ! user did not pass input validation
            //     t.accountCreated = false;
            //     t.MIX_alert(-1, "Fill out all the fields correctly in order to proceed.", null, null);
            // }
        },

    },

    watch: {
        loader1() {
            const l = this.loader1;
            this[l] = !this[l];

            setTimeout(() => (this[l] = false), 3000);

            this.loader1 = null;
        },
    },
}
</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
}
</style>