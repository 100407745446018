<!--
REGISTER

Renders the registration form, containing the following:
	- Email (input)
	- Full Name (input)
	- Contact Number (input)
	- Position (input)
	- T&C's (checkbox)
	- Log In (button)
	- Next (button)
	- Register (link)
	- Rest Password (link)
-->
<template>
    <v-row class="d-flex justify-center ma-4" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8">

            <!--Header-->
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">

                    <!--Logo-->
                    <div class="d-flex justify-center">
                        <v-img :src="require('@/assets/company_logo_full.svg')" max-width="256"/>
                    </div>

                    <v-divider class="my-4"/>

                </v-col>
            </v-row>

            <!--Title-->
            <v-row class="justify-center" no-gutters>
                <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">

                    <!--Title-->
                    <app-text category="text-large" class="primary--text">Registration</app-text>

                    <!--Instruction-->
                    <app-text class="mt-4">
                        Please use the form below to register as a user.
                    </app-text>

                </v-col>
            </v-row>

            <!--Registration Form-->
            <v-row class="d-flex justify-center" no-gutters>
                <v-col cols="12" xs="12" sm="8" md="6">

                    <!--Form-->
                    <firebase-auth-register/>

                </v-col>
            </v-row>

            <!--Links-->
            <v-row class="d-flex justify-center text-center">
                <v-col cols="12" xs="12" sm="8">

                    <!--Register-->
                    <app-text class="justify-center mb-4">Don't have an account?
                        <router-link :to="{ path: 'register' }" replace class="btn btn-link">
                            Register
                        </router-link>
                    </app-text>

                    <!--Reset-->
                    <app-text class="justify-center">Forgot Password?
                        <router-link :to="{ path: 'reset-password' }" replace class="btn btn-link">
                            Reset password
                        </router-link>
                    </app-text>

                </v-col>
            </v-row>

        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "Register",
};
</script>
