<template>
    <app-text category="text-large"
              class="primary--text"
              style="width: 100%">

        Welcome

        <span class="accent--text">{{ GET_currentUser.userName.split(" ")[0] }}</span>

    </app-text>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "welcomeUserHomePageCard",

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),
    },

}
</script>

<style scoped>

</style>
