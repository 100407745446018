<template>
    <v-expansion-panels v-if="GET_currentUser.userLevel !== 'VU'" flat>
        <v-expansion-panel class="rounded-lg">

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex align-center">

                    <!--Icon-->
                    <v-icon class="icons8-schedule ma-n1" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">
                        Schedule
                    </app-text>

                </div>

                <!--Text-->
                <app-text v-if="!scheduleError" category="text-default-bold" class="mt-4">
                    <span class="font-weight-regular">Tomorrow - </span>{{ tomorrowsScheduleData }}
                </app-text>
                <app-text v-else class="mt-4">
                    {{ scheduleError }}
                </app-text>

            </v-expansion-panel-header>

            <!--Mon-Fri schedule-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Mon-Fri-->
                <div style="display: grid; grid-template-columns: auto 1fr;">
                    <span class="grey--text mr-4">Mon:</span>{{ scheduleData.monday }}<br>
                    <span class="grey--text">Tue:</span>{{ scheduleData.tuesday }}<br>
                    <span class="grey--text">Wed:</span>{{ scheduleData.wednesday }}<br>
                    <span class="grey--text">Thu:</span>{{ scheduleData.thursday }}<br>
                    <span class="grey--text">Fri:</span>{{ scheduleData.friday }}<br>
                    <span class="grey--text">Sat:</span>{{ scheduleData.saturday }}<br>
                    <span class="grey--text">Sun:</span>{{ scheduleData.sunday }}<br>
                </div>

                <!--Update Button-->
                <app-btn @click.native="MIX_go('/userSchedule')"
                        block
                         class="mt-4"
                        label="Update Schedule"/>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "userScheduleHomePageCard",

    props: ['scheduleError', 'tomorrowsScheduleData', 'scheduleData'],

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),
    },

}
</script>
