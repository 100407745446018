// * COMMENT STORE MODULE
const fsDialog_store = {
    namespaced: true, // to be case sensitive
    // * STATE
    state: {
        fsDialog: { show: false, title: '', dynamicComponent: 'fsdialogtest' },
    },
    // * MUTATIONS
    mutations:{
        MUT_fsDialog(state, data) { state.fsDialog = data },
    },
    // * GETTERS
    getters:{
        GET_fsDialog(state) { return state.fsDialog },
    },
    // * ACTIONS
    actions: {
        ACT_fsDialog({commit}, payload){ commit('MUT_fsDialog', payload) },
    }
};
export default fsDialog_store;
