<template>
    <div class="text-center">
        <v-dialog v-model="dialog"
                  transition="dialog-bottom-transition"
                  max-width="512">

            <!--Activator-->
            <template v-slot:activator="{ on, attrs }">

                <!--Invite button-->
                <div class="ussad-activatorButton-container pr-0">
                    <v-btn class="rounded-lg"
                           color="primary"
                           depressed
                           :height="buttonSizeDefault"
                           v-on="on">
                        <v-icon class="icons8-email-send mr-2"/>
                        <app-text>Invite</app-text>
                    </v-btn>
                </div>

            </template>

            <!--Dialog content-->
            <v-card>

                <!--Toolbar-->
                <v-toolbar color="primary">

                    <app-text category="text-medium" class="white--text ml-2">Invite Contractor</app-text>

                    <v-spacer/>

                    <v-btn @click="dialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white"/>
                    </v-btn>

                </v-toolbar>

                <!--Body-->
                <div class="ussad-body-container">

                    <!--Instructional text-->
                    <app-text>
                        Contractors can be invited with a special link.
                        Add as many email addresses as required.
                    </app-text>

                    <!--Instructional text-->
                    <app-text class="mt-4">
                        You can copy the link from here should you need to send it externally:
                    </app-text>

                    <!--Email link-->
                    <app-text category="text-default-bold" class="text-break mt-4">
                        https://sixways.swapp.work/register/{{ contractorCompanyId }}
                    </app-text>

                    <v-divider class="my-4"/>

                    <!--Email input-->
                    <div class="cid-emailInput">
                        <app-input input-type="textInput"
                                   :error="errors.emailAddress"
                                   :error-messages="errors.emailAddressErrorMessage"
                                   label="Email Address"
                                   type="email"
                                   placeholder="Email address"
                                   v-model="emailAddress"/>

                        <v-icon @click="validateEmail"
                                class="icons8-add ml-4"
                                color="success"
                                :disabled="!emailAddress"
                                size="48"/>
                    </div>

                    <!--Email addresses to invite-->
                    <div>

                        <app-text class="mt-4">Email addresses to invite:</app-text>
                        <app-text v-if="!emailAddresses.length" class="grey--text ma-4 mb-0">
                            None added
                        </app-text>

                        <!--Emails-->
                        <div v-for="email in emailAddresses" class="mt-4">

                            <div class="d-flex">
                                <v-icon class="icons8-checkmark-yes mr-4" color="success"/>
                                <app-text>{{ email }}</app-text>
                            </div>

                        </div>

                    </div>

                </div>

                <v-divider class="ma-4 mt-0"/>

                <!--Send button-->
                <div class="d-flex pa-4 pt-0">
                    <v-spacer/>
                    <app-btn @click.native="inviteUsers"
                             block
                             color="success"
                             :disabled="!emailAddresses.length"
                             icon="icons8-checkmark-yes"
                             :label="computedInviteButtonText"/>
                </div>

            </v-card>

        </v-dialog>
    </div>
</template>

<script>
export default {

    props: ['contractorCompanyId'],

    data: () => ({
        dialog: false,
        emailAddress: '',
        emailAddresses: [],
        errors: {
            emailAddress: false,
            emailAddressErrorMessage: '',
        }
    }),

    computed: {

        /**
         * Computed Invite Button Text
         *
         * Return the correct button text based on the number of recipients.
         *
         * @returns {string} button text
         */
        computedInviteButtonText() {
            const t = this

            return t.emailAddresses.length > 1 ? 'Send Invites' : 'Send Invite'
        }

    },

    methods: {

        /**
         * Validate Email
         *
         * Validate the email entry for a basic formatted email address.
         */
        validateEmail() {
            const t = this
            const emailRegex = /.+@.+\..+/

            t.errors.emailAddress = false
            t.errors.emailAddressErrorMessage = ''

            if (!emailRegex.test(t.emailAddress)) {
                t.errors.emailAddress = true
                t.errors.emailAddressErrorMessage = 'Not a valid email address'
            }

            if (!Object.values(t.errors).includes(true)) {
                t.addEmailAddress()
            }
        },

        /**
         * Add Email Address
         *
         * Add the email address(es) to the addresses array for sending.
         */
        addEmailAddress() {
            const t = this

            t.emailAddresses.push(t.emailAddress)
            t.emailAddress = ''
        },

        /**
         * Invite Users
         *
         * Call the Firebase function to send invites to as many recipients as required.
         */
        inviteUsers() {
            const t = this

            const invite = t.$firebase.functions.httpsCallable('inviteUsers')
            invite({emailAddresses: t.emailAddresses, contractorCompanyId: t.$props.contractorCompanyId})

            .then(result => {
                console.log('contractorsInviteDialog - result: ', result)
                t.dialog = false
            })
            .catch(error => {
                console.log('contractorsInviteDialog - error: ', error)
            })

        },

    }

}
</script>

<style>
.ussad-activatorButton-container {
    display: flex;
    justify-content: flex-end;

    padding: 0 16px;
}

.ussad-body-container {
    padding: 16px;
}

.ussad-acceptButton-container {
    display: flex;
    justify-content: flex-end;

    padding: 0 16px 16px 16px;
}

.cid-emailInput {
    display: grid;
    grid-template-columns: 1fr auto;
}
</style>
