import Vue from 'vue';
import App from './App.vue';
import router from '@/router.js';
import store from '@/store.js';

// * VUETIFY AND GLOBAL STYLES / ICONS / ANIMATIONS
import vuetify from '@/vuetify.js';
import "@/assets/css/styles.min.css"; // Import Icons8 Font Library CSS file
import '../node_modules/animate.css/animate.min.css'; // import animate css

// * GLOBAL MIXIN
import mixin from './mixin.js';
Vue.use(mixin);

// * SERVICE WORKER INTEGRATION
import './registerServiceWorker';
import sw_mixin from '@/sw_mixin.js'; // Import Service Work Mixing for Global SW Functions
Vue.use(sw_mixin); // Use Service Worker Mixing within Vie

// * PROGRESS BAR IMPORT
require('./modules/progressBar/progressBar_import.js') // Require Progress Bar Components and Mixins

// * FIREBASE INTEGRATION
require('./modules/auth/firebase_import.js') // Require Firebase Components and Mixins
const firebase = require("@/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_mixin from '@/modules/firebase/firebase_mixin.js'; // Import Firebase Mixin for Global CRUD Functions
Vue.use(firebase_mixin); // User Firebase Mixin within Vue
import firebase_component from '@/modules/firebase/firebase_component.vue';
Vue.component('firebase', firebase_component);

// * MOMENTJS INTEGRATION
import moment from 'moment';
Vue.prototype.$moment = moment;

// * GLOBAL COMPONENTS * // * GLOBAL COMPONENTS * // * GLOBAL COMPONENTS * // * GLOBAL COMPONENTS * //

// App button
import appbutton from './components/base/app_button.vue';
Vue.component('appbutton', appbutton)
// App copyright (Footer)
import appcopyright from './components/base/app_copyright.vue';
Vue.component('appcopyright', appcopyright)
// App Text
import appText from './components/base/app_text.vue';
Vue.component('app-text', appText)
// App BTN
import appBtn from './components/commonComponents/appBtn.vue';
Vue.component('appBtn', appBtn)
// Page Title
import pageTitle from './components/commonComponents/pageTitle';
Vue.component('pageTitle', pageTitle)
// App Input
import appInput from './components/commonComponents/appInput';
Vue.component('appInput', appInput)

// Check Network Connection
import checkNetwork_component from '@/modules/checkNetwork/checkNetwork_component.vue';
Vue.component('checkNetwork', checkNetwork_component);

// * MENU ITEMS LIST
import menu_list from '@/components/structural/menu-list.vue';
Vue.component('menuList', menu_list);

// * RIGHT PANEL LAYOUT
import rightPanel from "./components/structural/right-panel.vue";
Vue.component('rightpanel', rightPanel)

// * CONFIRMBOX COMPONENTS
import confirmBox from "./modules/confirmBox/confirmBox_component.vue";
Vue.component('confirmbox', confirmBox)
// * CONFIRMBOX MIXINS
import confirmBox_mixin from '@/modules/confirmBox/confirmBox_mixin.js';
Vue.use(confirmBox_mixin);

// * alertbox mixin import and use
import alertbox_mixin from '@/modules/alertbox/alertbox_mixin.js';
Vue.use(alertbox_mixin);

// * alertbox component import and register
import alertbox_component from '@/modules/alertbox/alertbox_component.vue';
Vue.component('alertbox', alertbox_component);

// * upload files component
// import dragdrop from "@/modules/file-upload/drag-drop.vue";
// Vue.component('dragdrop', dragdrop);
import uploadFilesButton from "@/modules/file-upload/upload-files-button.vue";
Vue.component('uploadFilesButton', uploadFilesButton);

// * PHOTOUPLOAD COMPONENTS
import photoUpload from "./modules/photoUpload/photoUpload_button_component.vue";
Vue.component('photoupload', photoUpload)
// * PHOTOUPLOAD MIXINS
import photoUpload_mixin from '@/modules/photoUpload/photoUpload_mixin.js';
Vue.use(photoUpload_mixin);

// * PHOTOVIEWER COMPONENTS
import photoViewer from "./modules/photoViewer/photoViewer_component.vue";
Vue.component('photoviewer', photoViewer)
// * PHOTOUPLOAD MIXINS
import photoViewer_mixin from '@/modules/photoViewer/photoViewer_mixin.js';
Vue.use(photoViewer_mixin);

// * FS DIALOG - Full Screen Dialog with Dynamic Component
import fsDialog_mixin from '@/modules/fsDialog/fsDialog_mixin.js';
Vue.use(fsDialog_mixin);
import fsDialog_component from '@/modules/fsDialog/fsDialog_component.vue';
Vue.component('fsdialog', fsDialog_component);
import fsDialog_test from '@/modules/fsDialog/fsDialog_test.vue';
Vue.component('fsdialogtest', fsDialog_test);

// * PU DIALOG - Popup Screen Dialog with Dynamic Component
import puDialog_mixin from '@/modules/puDialog/puDialog_mixin.js';
Vue.use(puDialog_mixin);
import puDialog_component from '@/modules/puDialog/puDialog_component.vue';
Vue.component('pudialog', puDialog_component);
import puDialog_test from '@/modules/puDialog/puDialog_test.vue';
Vue.component('pudialogtest', puDialog_test);

// * CONCERNS COMPONENTS
import concern from "./components/concern.vue";
Vue.component('concern', concern);

// * CONTRACTOR COMPONENTS
import contractor from "./components/contractor.vue";
Vue.component('contractor', contractor);

// * CONTRACTOR AREA COMPONENTS
import contractorArea from "./components/contractorArea.vue";
Vue.component('contractorArea', contractorArea);

// * FIRE ROLL CALL COMPONENTS
import fireRollCall from "./components/fireRollCall.vue";
Vue.component('fireRollCall', fireRollCall);

// * OBSERVATIONS COMPONENTS
import observation from "./components/observation.vue";
Vue.component('observation', observation);

// * QUESTIONS
import question2 from "./components/question.vue";
Vue.component('question', question2);

// * SITES COMPONENTS
import sites from "./components/site.vue";
Vue.component('sites', sites);

// * SITE PERMIT
import sitePermit from "./components/sitePermit.vue";
Vue.component('sitePermit', sitePermit);

// * STAFF DIRECTORY COMPONENTS
import staffDirectory from "./components/staff.vue";
Vue.component('staffDirectory', staffDirectory);

//confirmation mixin import and register
import confirmation_mixin from '@/modules/confirmation/confirmation_mixin.js';
Vue.use(confirmation_mixin);

//  confirmation component import and register
import confirmation_component from '@/modules/confirmation/confirmation_component.vue';
Vue.component('confirmation', confirmation_component);

// * LOCATIONS
import location_mixin from '@/modules/location/location_mixin.js';
Vue.use(location_mixin);
import location_component from '@/modules/location/location_component.vue';
Vue.component('location', location_component);

// scan qr code component
import qr_code_reader from '@/modules/qr-code-reader/qr_code_reader_component.vue';
Vue.component('qrCodeReader', qr_code_reader);

//generate qr code mixin
import qr_code_reader_mixins from '@/modules/qr-code-reader/qr_code_reader_mixin.js';
Vue.use(qr_code_reader_mixins);

// generate qr code component dialog
import qr_code_generator_dialog from '@/modules/qr-code-generator/qr_code_generator_dialog_component.vue';
Vue.component('qrCodeGeneratorDialog', qr_code_generator_dialog);

// generate qr code component card
import qr_code_generator_card from '@/modules/qr-code-generator/qr_code_generator_card_component.vue';
Vue.component('qrCodeGeneratorCard', qr_code_generator_card);

 //generate qr code mixin
import qr_code_generator_mixins from '@/modules/qr-code-generator/qr_code_generator_mixin.js';
Vue.use(qr_code_generator_mixins);

// * LANGUAGE TRANSLATOR IMPORT
import i18n from './i18n'; // language translator
// * Vue Number Animation
import VueNumber from 'vue-number-animation'
Vue.use(VueNumber);

//lodash
import _ from "lodash-es";
import groupBy from "lodash-es/groupBy";
import find from "lodash-es/find";
import filter from "lodash-es/filter";
import sortBy from "lodash-es/sortBy";
// import orderBy from "lodash-es/orderBy";
import uniqBy from "lodash-es/uniqBy";
import uniq from "lodash-es/uniq";
import map from "lodash-es/map";
import has from "lodash-es/has";
import pick from "lodash-es/pick";
import omit from "lodash-es/omit";
import unionBy from "lodash-es/unionBy";
import reduce from "lodash-es/reduce";

Vue.prototype.$_ = _;

Vue.prototype.$groupBy = groupBy;
Vue.prototype.$omit = omit;
Vue.prototype.$has = has;
Vue.prototype.$uniqBy = uniqBy;
Vue.prototype.$uniq = uniq;
Vue.prototype.$filter = filter;
Vue.prototype.$pick = pick;
Vue.prototype.$find = find;
Vue.prototype.$sortBy = sortBy;
Vue.prototype.$reduce = reduce;
Vue.prototype.$moment = moment;

//Vue.prototype.$orderBy = orderBy;
Vue.prototype.$map = map;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app');
