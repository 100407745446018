<!--
Questions

Renders the Questions page which displays a table containing the individual question data.

The page also contains a Question form where the user can add/edit/delete questions.
-->
<template>
    <v-row no-gutters style="height: 100%">

        <!--Left panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4 ma-0">

            <!--Header-->
            <v-row class="ma-0" no-gutters>

                <!--Title-->
                <v-col cols="12" class="mb-4 pa-0">
                    <page-title icon="icons8-ask-question" pageTitle="Questions"/>
                </v-col>

                <!--Search-->
                <v-col :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6"
                       class="pa-0 mb-4">

                    <app-input input-type="textInput"
                               clearable
                               :label="$t('filters.search')"
                               :append-icon="'icons8-search'"
                               v-model="search"/>
                </v-col>

                <!--New Question Button-->
                <v-col class="d-flex justify-end align-end pa-0 mb-4">

                    <app-btn @click.native="openItem('', 'New', false)"
                             :block="$vuetify.breakpoint.width <= 600"
                             color="primary"
                             icon="icons8-ask-question"
                             label="New"/>
                </v-col>

            </v-row>

            <!--Table-->
            <v-row class="ma-0" no-gutters>
                <v-col v-if="$vuetify.breakpoint.lgAndUp" class="pa-0">

                    <v-data-table class="rounded-lg que-table"
                                  :headers="computedHeaders"
                                  :items="tableData"
                                  :search="search" sort-by="questionName">

                        <!--Id-->
                        <template v-slot:item.id="{ item }">
                            <app-text category="text-small">{{ item.id }}</app-text>
                        </template>

                        <!--Question-->
                        <template v-slot:item.questionName="{ item }">
                            <!--Title-->
                            <app-text category="text-small-bold" class="mb-2">{{ item.questionTitle }}</app-text>
                            <!--Question-->
                            <app-text category="text-small">{{ item.questionName }}</app-text>
                        </template>

                        <!--Sites-->
                        <template v-slot:item.questionSiteIDs="{ item }">
                            <div v-for="site in item.questionSiteIDs" class="bottomBorder">
                                <app-text category="text-small">{{ site.siteName }}</app-text>
                            </div>
                        </template>

                        <!--Type-->
                        <template v-slot:item.questionTypes="{ item }">
                            <div v-for="type in item.questionTypes" class="bottomBorder">
                                <app-text category="text-small">{{ type }}</app-text>
                            </div>
                        </template>

                        <!--User Categories-->
                        <template v-slot:item.questionUserCategories="{ item }">
                            <div v-for="category in item.questionUserCategories" class="bottomBorder">
                                <app-text category="text-small">{{ category }}</app-text>
                            </div>
                        </template>

                        <!--Answers-->
                        <template v-slot:item.questionAnswers="{ item }">
                            <div v-for="answer in item.questionAnswers" class="bottomBorder">
                                <app-text category="text-small">{{ answer }}</app-text>
                            </div>
                        </template>

                        <!--Correct Answers-->
                        <template v-slot:item.questionCorrectAnswers="{ item }">
                            <div v-for="answer in item.questionCorrectAnswers" class="bottomBorder">
                                <app-text category="text-small">{{ answer }}</app-text>
                            </div>
                        </template>

                        <!--Action-->
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex align-center justify-end" style="height: 100%">
                                <v-btn @click="openItem(item.id, 'View', false)"
                                       depressed class="white text--red">Open
                                    <v-icon color="primary" class="icons8-forward"/>
                                </v-btn>
                            </div>
                        </template>

                    </v-data-table>
                </v-col>
            </v-row>

            <!--Mobile Cards-->
            <v-row class="ma-0" no-gutters>
                <v-col v-if="$vuetify.breakpoint.mdAndDown">
                    <div v-for="question in filteredTableData" :key="question.id">

                        <v-card @click="openItem(question.id, 'View', false)"
                                class="rounded-lg mb-4 pa-4"
                                flat>

                            <app-text category="text-default-bold" class="mb-2">{{ question.questionTitle }}</app-text>
                            <app-text>{{ question.questionName }}</app-text>

                        </v-card>

                    </div>
                </v-col>
            </v-row>

        </v-col>

        <!--Right panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animate__faster"
                    leave-active-class="animate__animated animate__fadeOut animate__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
        </transition>

    </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {

    name: "Questions",

    data: () => ({
        title: 'Questions',
        collection: 'questions',
        collectionItem: 'question',
        collectionTitle: 'Questions',
        collectionItemTitle: 'Question',
        headers: [
            {text: 'Id', value: 'id', align: 'left', sortable: false, hidden: true},
            {text: 'Types', value: 'questionTypes', align: 'left', sortable: false},
            {text: 'User Categories', value: 'questionUserCategories', align: 'left', sortable: false},
            {text: 'Question', value: 'questionName', align: 'left', sortable: false},
            {text: 'Sites', value: 'questionSiteIDs', align: 'left', sortable: false},
            {text: 'Answers', value: 'questionAnswers', align: 'left', sortable: false},
            {text: 'Correct Answers', value: 'questionCorrectAnswers', align: 'left', sortable: false},
            {text: '', value: 'actions', align: 'right', sortable: false},
        ],
        search: '',
        tableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
            GET_lookupValues: 'GET_lookupValues',
        }),
        /**
         * Computed Headers
         *
         * Returns a filtered headers array which is only those not marked as hidden.
         */
        computedHeaders() {
            const t = this

            return t.headers.filter(header => !header.hidden)
        },

        /**
         * Filtered Table Data
         *
         * Take the tableData and return an array of question filtered by the search string.
         *
         * @returns array - users filtered by search string
         */
        filteredTableData() {
            const t = this

            return t.tableData.filter(question => question.questionName.toLowerCase().match(t.search.toLowerCase()))
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openQuestion",
            ACT_openQuestion: "ACT_openQuestion",
        }),

        /**
         * Open Item
         *
         * Call for data of the selected document.
         * Open the Right Panel (form).
         * If the viewport is <= 'large', make the form full-width, if not, split it 50/50.
         *
         * @returns {Promise<void>}
         */
        async openItem(id, process, popup) {
            const t = this
            const item = {}

            t.dynamicComponentKey++
            item.process = process
            item.popup = popup

            if (item.process !== 'New') {
                const itemResult = await t.MIX_readDocumentById(t.collection, id)
                item.data = itemResult.data
            } else {
                item.data = null
            }

            t.ACT_openItem(item)

            // Open form based on device size
            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem);
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)
            }
        },

        /**
         * Get Questions Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Questions collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getQuestionsCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection(t.collection)
            collection.onSnapshot(snapShot => {

                // Clear the table data to avoid duplications
                t.tableData = []

                snapShot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    }
                })
            })
        }
    },

    async mounted() {
        const t = this

        // Get Questions collection data
        await t.getQuestionsCollectionData()
    }

}
</script>

<style>
/*Vertically center the table headers  */
.que-table th {
    vertical-align: middle;
}

/*Vertically align the table row to the text bottom (baseline of top text line)  */
.que-table tr {
    /*vertical-align: text-bottom;*/
}

.bottomBorder {
    border-bottom: 1px solid lightgrey;
    padding: 8px 0;
}

.bottomBorder:last-child {
    border-bottom: none;
}

</style>
