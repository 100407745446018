<!--
Contractors

Renders the Contractors page which displays a table containing the Contractor company data.

The page also contains a Contractor form where the authorised User can add/edit/delete Contractors.
-->
<template>
    <v-row no-gutters style="height: 100%">

        <!--Left panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4 ma-0">

            <!--Header-->
            <v-row class="ma-0" no-gutters>

                <!--Title-->
                <v-col cols="12" class="mb-4 pa-0">
                    <page-title icon="icons8-business-building" pageTitle="Contractors"/>
                </v-col>

                <!--Search-->
                <v-col :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6"
                       class="pa-0 mb-4">

                    <app-input input-type="textInput"
                               clearable
                               :label="$t('filters.search')"
                               :append-icon="'icons8-search'"
                               v-model="search"/>

                </v-col>

                <!--New Contractor Button-->
                <v-col class="d-flex justify-end align-end pa-0 mb-4">

                    <app-btn @click.native="openItem('', 'New', false)"
                             :block="$vuetify.breakpoint.width <= 600"
                             color="primary"
                             icon="icons8-business-building"
                             label="New Contractor"/>

                </v-col>

            </v-row>

            <!--Table-->
            <v-row v-if="$vuetify.breakpoint.width >= 600" class="ma-0" no-gutters>
                <v-col class="pa-0">

                    <v-data-table class="rounded-lg"
                                  :headers="computedHeaders"
                                  :items="filteredTableData"
                                  :search="search"
                                  sort-by="contractorName">

                        <!--Id-->
                        <template v-slot:item.id="{ item }">
                            <app-text category="text-small">{{ item.id }}</app-text>
                        </template>

                        <!--Status-->
                        <template v-slot:item.contractorStatus="{ item }">
                            <app-text category="text-small">{{ item.contractorStatus }}</app-text>
                        </template>

                        <!--Name-->
                        <template v-slot:item.contractorName="{ item }">
                            <app-text category="text-small">{{ item.contractorName }}</app-text>
                        </template>

                        <!--Contact Name-->
                        <template v-slot:item.contractorContactName="{ item }">
                            <app-text category="text-small">{{ item.contractorContactName }}</app-text>
                        </template>

                        <!--Telephone-->
                        <template v-slot:item.contractorTelephone="{ item }">
                            <app-text category="text-small">{{ item.contractorTelephone }}</app-text>
                        </template>

                        <!--Email-->
                        <template v-slot:item.contractorEmail="{ item }">
                            <app-text category="text-small">{{ item.contractorEmail }}</app-text>
                        </template>

                        <!--Action-->
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex align-center justify-end" style="height: 100%">
                                <v-btn @click="openItem(item.id, 'View', false)"
                                       depressed class="white text--red">Open
                                    <v-icon color="primary" class="icons8-forward"/>
                                </v-btn>
                            </div>
                        </template>

                    </v-data-table>

                </v-col>
            </v-row>

            <!--Mobile Cards-->
            <v-row class="ma-0" no-gutters>
                <v-col v-if="$vuetify.breakpoint.width < 600">
                    <div v-for="contractor in tableData" :key="contractor.id">

                        <v-card @click="openItem(contractor.id, 'View', false)"
                                class="rounded-lg mb-4 pa-4"
                                flat>

                            <!--Name-->
                            <app-text category="text-default-bold" class="mb-2">
                                {{ contractor.contractorName }}
                            </app-text>

                            <!--Contact details-->
                            <div class="d-flex">

                                <!--Contact name-->
                                <div class="d-flex align-center">
                                    <v-icon class="icons8-person mr-2" color="primary" size="16"/>
                                    <app-text class="grey--text mr-4">
                                        {{ contractor.contractorContactName }}
                                    </app-text>
                                </div>

                                <!--Contact telephone-->
                                <div class="d-flex align-center">
                                    <v-icon class="icons8-phone mr-2" color="primary" size="16"/>
                                    <app-text class="grey--text">
                                        {{ contractor.contractorTelephone }}
                                    </app-text>
                                </div>

                            </div>

                        </v-card>

                    </div>
                </v-col>
            </v-row>

        </v-col>

        <!--Right panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animate__faster"
                    leave-active-class="animate__animated animate__fadeOut animate__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
        </transition>

    </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {

    name: "Contractors",

    data: () => ({
        title: 'Contractors',
        collection: 'contractors',
        collectionItem: 'contractor',
        collectionTitle: 'Contractors',
        collectionItemTitle: 'Contractor',
        headers: [
            {text: 'Id', value: 'id', align: 'left', sortable: false, hidden: true},
            {text: 'Name', value: 'contractorName', align: 'left', sortable: false},
            {text: 'Contact Name', value: 'contractorContactName', align: 'left', sortable: false},
            {text: 'Telephone', value: 'contractorTelephone', align: 'left', sortable: false},
            {text: 'Email', value: 'contractorEmail', align: 'left', sortable: false},
            {text: '', value: 'actions', align: 'right', sortable: false, width: '134px'},
        ],
        search: '',
        tableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
        }),

        /**
         * Computed Headers
         *
         * Returns a filtered headers array which is only those not marked as hidden.
         */
        computedHeaders() {
            const t = this

            return t.headers.filter(header => !header.hidden)
        },

        /**
         * Filtered Table Data
         *
         * Take the tableData and return an array of filtered Contractors.
         *
         * @returns array - filtered users
         */
        filteredTableData() {
            const t = this
            let tableData = t.tableData

            // Filter by Search
            if (t.search) {
                tableData = tableData.filter(
                    contractor => contractor.contractorName.toUpperCase().match(t.search.toUpperCase()))
            }

            return tableData
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openContractor",
            ACT_openContractor: "ACT_openContractor",
        }),

        /**
         * Open Item
         *
         * Call for data of the selected document.
         * Open the Right Panel (form).
         * If the viewport is <= 'large', make the form full-width, if not, split it 50/50.
         *
         * @returns {Promise<void>}
         */
        async openItem(id, process, popup) {
            const t = this
            const item = {}

            t.dynamicComponentKey++
            item.process = process
            item.popup = popup

            if (item.process !== 'New') {
                const itemResult = await t.MIX_readDocumentById(t.collection, id)
                item.data = itemResult.data
            } else {
                item.data = null
            }

            t.ACT_openItem(item)

            // Open form based on device size
            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem);
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)
            }
        },

        /**
         * Get Contractors Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Contractors collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        async getContractorsCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('contractors')
            collection.onSnapshot(snapShot => {

                // Clear the table data to avoid duplications
                t.tableData = []

                snapShot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    }
                })
            })
        }
    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getContractorsCollectionData()
    }

}
</script>
