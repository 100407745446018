<template>
    <v-expansion-panels flat>
        <v-expansion-panel class="rounded-lg">

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex align-center">

                    <!--Icon-->
                    <v-icon class="icons8-business-building ma-n1" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">Site Permit Approval</app-text>

                </div>

                <!--Message-->
                <app-text class="mt-4">{{ awaitingApprovalMessage }}</app-text>

            </v-expansion-panel-header>

            <!--Awaiting approval-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Users-->
                <div v-for="permit in sitePermitCollectionData" class="mb-4">

                    <v-divider class="mb-4"/>

                    <app-text category="text-default-bold">{{ permit.contractor.contractorName }}</app-text>
                    <app-text>{{ permit.user.userName }}</app-text>

                </div>

                <!--Action button-->
                <app-btn @click.native="MIX_go('staff-directory')"
                         block
                         label="Show Accounts"/>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {

    name: "sitePermitsHomePageCard",

    data: () => ({

        sitePermitCollectionData: [],

    }),

    computed: {

        /**
         * Awaiting Approval Message
         *
         * Return a message containing the number of permits.
         *
         * @returns {string}
         */
        awaitingApprovalMessage() {
            const t = this
            let message

            if (t.sitePermitCollectionData.length === 1) {
                message = `You have 1 permit awaiting approval`
            } else {
                message = `You have ${t.sitePermitCollectionData.length} permits awaiting approval`
            }

            return message
        }

    },

    methods: {

        async getSitePermitCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sitePermits').where("status", "==", "Pending")
            collection.onSnapshot(snapshot => {

                // Clear the array to avoid duplications
                t.sitePermitCollectionData = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    if (!document.hasOwnProperty('delete')) {
                        t.sitePermitCollectionData.push(document)
                    }

                })
            })
        },

    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getSitePermitCollectionData()

    },

}
</script>

<style scoped>

</style>
