<!--
Contractor

Renders the form to add/edit/delete Contractors.
-->
<template>
    <div>

        <!--Header-->
        <v-row class="ma-0 pa-4" no-gutters>

            <!--Title-->
            <app-text>{{ form.contractorName ? form.contractorName : 'New' }}</app-text>

            <v-spacer/>

            <!--Action buttons-->
            <div>

                <!--Edit | Delete-->
                <div v-if="tabs === 'company'">

                    <!--Edit-->
                    <v-icon v-if="formMode !== 'New'"
                            @click.native="editDocument"
                            class="icons8-edit"
                            :class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
                            :color="formReadOnly ? 'warning' : 'white'"
                            size="32"/>

                    <!--Delete-->
                    <v-icon v-if="formMode !== 'New'"
                            @click.native="deleteDocument(form.id)"
                            class="icons8-trash frc-icon"
                            color="error"
                            size="32"/>

                </div>

                <!--Invite Contractor-->
                <contractors-invite-dialog v-if="tabs === 'users'" :contractorCompanyId="form.id"/>

            </div>

        </v-row>

        <!--Page Content-->
        <v-row class="ma-0" no-gutters>

            <!--Tabs-->
            <v-tabs v-model="tabs" :height="buttonSizeDefault">

                <v-tabs-slider color="accent"/>

                <!--Company-->
                <v-tab href="#company">
                    <app-text category="text-small">Company</app-text>
                </v-tab>

                <!--Users-->
                <v-tab :disabled="!form.id" href="#users">
                    <app-text category="text-small">Users</app-text>
                </v-tab>

            </v-tabs>

            <!--Tab Content-->
            <v-tabs-items v-model="tabs" class="appbackground" style="width: 100%">

                <!--Company Tab Content-->
                <v-tab-item value="company">

                    <!--Instructions-->
                    <app-text class="ma-4">
                        Complete the following form to create a new Contractor.
                    </app-text>

                    <v-divider class="ma-4"/>

                    <!--Form-->
                    <v-form>

                        <v-row class="px-4" no-gutters>

                            <!--Contractor Name -->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                                           :error="errors.contractorName"
                                           :error-messages="errors.contractorNameErrorMessage"
                                           label="Contractor Name"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-business-building"
                                           v-model.trim="form.contractorName"/>
                            </v-col>

                            <!--Contact-->
                            <v-col class="mt-4 mb-2 ml-2" cols="12">
                                <app-text category="text-medium" class="grey--text">Contact</app-text>
                            </v-col>

                            <!--Contact Name-->
                            <v-col cols="12">
                                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="pa-0">
                                    <app-input input-type="textInput"
                                               :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                               :error="errors.contractorContactName"
                                               :error-messages="errors.contractorContactNameErrorMessage"
                                               label="Contact Name"
                                               :is-form-read-only="formReadOnly"
                                               prepend-inner-icon="icons8-person"
                                               v-model.trim="form.contractorContactName"/>
                                </v-col>
                            </v-col>

                            <!--Telephone-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'mt-4 pr-2' : 'mt-4'"
                                           :error="errors.contractorTelephone"
                                           :error-messages="errors.contractorTelephoneErrorMessage"
                                           label="Telephone"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-phone"
                                           type="tel"
                                           v-model.trim="form.contractorTelephone"/>
                            </v-col>

                            <!--Email-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'mt-4 pl-2' : 'mt-4'"
                                           :error="errors.contractorEmail"
                                           :error-messages="errors.contractorEmailErrorMessage"
                                           label="Email"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-email"
                                           type="email"
                                           v-model.trim="form.contractorEmail"/>
                            </v-col>

                            <!--Address-->
                            <v-col class="mt-4 mb-2 ml-2" cols="12">
                                <app-text category="text-medium" class="grey--text">Address</app-text>
                            </v-col>

                            <!--Address Line 1-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                           :error="errors.contractorAddressLine1"
                                           :error-messages="errors.contractorAddressLine1ErrorMessage"
                                           label="Address Line 1"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-company"
                                           v-model.trim="form.contractorAddressLine1"/>
                            </v-col>

                            <!--Address Line 2-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                           label="Address Line 2"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-company"
                                           v-model.trim="form.contractorAddressLine2"/>
                            </v-col>

                            <!--Address Line 3-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                           label="Address Line 3"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-company"
                                           v-model.trim="form.contractorAddressLine3"/>
                            </v-col>

                            <!--Town-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                           :error="errors.contractorAddressTown"
                                           :error-messages="errors.contractorAddressTownErrorMessage"
                                           label="Town"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-company"
                                           v-model.trim="form.contractorAddressTown"/>
                            </v-col>

                            <!--County-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'pr-2' : 'mt-4'"
                                           label="County"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-company"
                                           v-model.trim="form.contractorAddressCounty"/>
                            </v-col>

                            <!--Postcode-->
                            <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6">
                                <app-input input-type="textInput"
                                           class="mt-4"
                                           :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'"
                                           :error="errors.contractorAddressPostcode"
                                           :error-messages="errors.contractorAddressPostcodeErrorMessage"
                                           label="Postcode"
                                           :is-form-read-only="formReadOnly"
                                           prepend-inner-icon="icons8-company"
                                           v-model.trim="form.contractorAddressPostcode"/>
                            </v-col>

                        </v-row>

                        <!--Save button-->
                        <div class="d-flex ma-4 mt-4">
                            <v-spacer/>
                            <app-btn v-if="formMode === 'Edit' || formMode === 'New'"
                                     @click.native="validateForm"
                                     color="success"
                                     icon="icons8-business-building"
                                     label="Create"/>
                        </div>

                        <v-divider/>

                        <!--Meta data-->
                        <div class="ma-4">
                            <app-text category="text-small" class="grey--text">Meta data</app-text>
                        </div>

                    </v-form>

                </v-tab-item>

                <!--Users Tab Content-->
                <v-tab-item value="users" class="pa-4">

                    <!--Instructions-->
                    <app-text>
                        Complete the following form to add a new Worker.
                    </app-text>

                    <v-divider class="my-4"/>

                    <!--Users Table-->
                    <v-data-table v-if="$vuetify.breakpoint.width >= 600" class="rounded-lg"
                                  :headers="computedUsersHeaders"
                                  :items="computedUsers"
                                  sort-by="userName">

                    </v-data-table>

                    <!--Mobile cards-->
                    <div v-if="$vuetify.breakpoint.width < 600">
                        <div v-for="user in computedUsers">

                            <v-card class="mb-4 pa-4" flat>

                                <!--User name-->
                                <app-text category="text-default-bold">{{ user.userName }}</app-text>

                                <!--Contact number-->
                                <div class="d-flex mt-2">
                                    <v-icon class="icons8-phone mr-2" color="primary" size="16"/>
                                    <app-text class="grey--text">
                                        {{ user.userTelephone }}
                                    </app-text>
                                </div>

                            </v-card>

                        </div>
                    </div>

                </v-tab-item>

            </v-tabs-items>

        </v-row>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ContractorsInviteDialog from "../views/contractors/contractorsInviteDialog/contractorsInviteDialog";

export default {

    name: "Contractor",

    components: {ContractorsInviteDialog},

    data: () => ({
        collection: 'contractors',
        collectionTitle: 'Contractors',
        collectionItem: 'contractor',
        collectionItemTitle: 'Contractor',
        contractorUsersCollectionData: [],
        errors: {
            contractorName: false,
            contractorNameErrorMessage: '',
            contractorContactName: false,
            contractorContactNameErrorMessage: '',
            contractorTelephone: false,
            contractorTelephoneErrorMessage: '',
            contractorEmail: false,
            contractorEmailErrorMessage: '',
            contractorAddressLine1: false,
            contractorAddressLine1ErrorMessage: '',
            contractorAddressTown: false,
            contractorAddressTownErrorMessage: '',
            contractorAddressPostcode: false,
            contractorAddressPostcodeErrorMessage: '',
        },
        form: {
            id: '',
            contractorName: '',
            contractorAddressLine1: '',
            contractorAddressLine2: '',
            contractorAddressLine3: '',
            contractorAddressTown: '',
            contractorAddressCounty: '',
            contractorAddressPostcode: '',
            contractorTelephone: '',
            contractorEmail: '',
            contractorContactName: '',
            contractorStatus: '',
        },
        formMode: 'New',
        formReadOnly: false,
        tabs: 'contractor',
        usersHeader: [
            {text: 'Id', value: 'id', align: 'left', sortable: false, hidden: true},
            {text: 'Name', value: 'userName', align: 'left', sortable: false},
            {text: 'Telephone', value: 'userTelephone', align: 'left', sortable: false},
            {text: 'Email', value: 'userEmail', align: 'left', sortable: false},
            {text: '', value: 'actions', align: 'left', sortable: false, width: '134px'},
        ],
    }),

    computed: {
        ...mapGetters({
            GET_openItem: 'GET_openContractor',
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
        }),

        computedUsersHeaders() {
            const t = this

            return t.usersHeader.filter(header => !header.hidden)
        },

        computedUsers() {
            const t = this

            return t.contractorUsersCollectionData.filter(user => user.contractorCompanyId === t.form.id)
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openContractor",
            ACT_openContractor: "ACT_openContractor"
        }),

        async getContractorUsersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection("users").where("userType", "==", "Contractor")
            collection.onSnapshot(snapshot => {

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    if (!document.hasOwnProperty('delete')) {
                        t.contractorUsersCollectionData.push(document)
                    }

                })

            })
        },

        /**
         * Validate
         *
         * Validates the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const emailRegex = /.+@.+\..+/
            const postcodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/

            t.errors.contractorName = false
            t.errors.contractorNameErrorMessage = ''
            t.errors.contractorContactName = false
            t.errors.contractorContactNameErrorMessage = ''
            t.errors.contractorTelephone = false
            t.errors.contractorTelephoneErrorMessage = ''
            t.errors.contractorEmail = false
            t.errors.contractorEmailErrorMessage = ''
            t.errors.contractorAddressLine1 = false
            t.errors.contractorAddressLine1ErrorMessage = ''
            t.errors.contractorAddressTown = false
            t.errors.contractorAddressTownErrorMessage = ''
            t.errors.contractorAddressPostcode = false
            t.errors.contractorAddressPostcodeErrorMessage = ''

            // Contractor Company Name
            if (!t.form.contractorName.trim()) {
                t.errors.contractorName = true
                t.errors.contractorNameErrorMessage = 'Name required'
            }

            // Contractor Contact Name
            if (!t.form.contractorContactName.trim()) {
                t.errors.contractorContactName = true
                t.errors.contractorContactNameErrorMessage = 'Contact Name required'
            } else if (t.form.contractorContactName.trim().length < 2) {
                t.errors.contractorContactName = true
                t.errors.contractorContactNameErrorMessage = 'Contact Name must be more than 2 characters'
            }

            // Contact Telephone
            if (!t.form.contractorTelephone.trim()) {
                t.errors.contractorTelephone = true
                t.errors.contractorTelephoneErrorMessage = 'Telephone Number required'
            }
            // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.contractorTelephone.trim()[1]) && (t.form.contractorTelephone.trim().length < 10 || t.form.contractorTelephone.trim().length > 11)) {
                t.errors.contractorTelephone = true
                t.errors.contractorTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.contractorTelephone.trim()[1]) && t.form.contractorTelephone.trim().length !== 11) {
                t.errors.contractorTelephone = true
                t.errors.contractorTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Number must start 01, 02, 03 or 07
            else if (!['0'].includes(t.form.contractorTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.contractorTelephone[1])) {
                t.errors.contractorTelephone = true
                t.errors.contractorTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Contact email
            if (!t.form.contractorEmail.trim()) {
                t.errors.contractorEmail = true
                t.errors.contractorEmailErrorMessage = 'Contact Email required'
            } else if (!emailRegex.test(t.form.contractorEmail)) {
                t.errors.contractorEmail = true
                t.errors.contractorEmailErrorMessage = 'Contact Email required'
            }

            // Address Line 1
            if (!t.form.contractorAddressLine1.trim()) {
                t.errors.contractorAddressLine1 = true
                t.errors.contractorAddressLine1ErrorMessage = 'Address Line 1 required'
            }

            // Address Town
            if (!t.form.contractorAddressTown.trim()) {
                t.errors.contractorAddressTown = true
                t.errors.contractorAddressTownErrorMessage = 'Address Town required'
            }

            // Address Postcode
            if (!t.form.contractorAddressPostcode.trim()) {
                t.errors.contractorAddressPostcode = true
                t.errors.contractorAddressPostcodeErrorMessage = 'Postcode required'
            } else if (!postcodeRegex.test(t.form.contractorAddressPostcode)) {
                t.errors.contractorAddressPostcode = true
                t.errors.contractorAddressPostcodeErrorMessage = 'Postcode not valid'
            }

            // Check if any errors are left
            if (!Object.values(t.errors).includes(true)) {
                this.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Save or update the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let createDocumentResult

            // If this is new document, create one
            if (!t.form.id) {
                t.form.id = t.MIX_generateId()
                createDocumentResult = await t.MIX_createDocument(t.collection, t.form)
            }

            // If this is an existing document, update it
            else {
                createDocumentResult = await t.MIX_updateDocument(t.collection, t.form)
            }

            // If the document saved/updated successfully, set the form back to viewable state
            if (createDocumentResult.code === 1) {
                t.formMode = 'View'
                t.formReadOnly = true
                await t.refreshDocumentView();
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Edit Document
         *
         * Toggle the form between an editable, and a viewable state.
         */
        editDocument() {
            const t = this

            if (t.formReadOnly) {
                t.formMode = 'Edit'
                t.formReadOnly = false
            } else {
                t.formMode = 'View'
                t.formReadOnly = true
                t.refreshDocumentView()
            }

        },

        /**
         * Delete Document
         *
         * Call for the confirmation box to delete a document.
         * The deletion is handled in this file's watch -> GET_confirmBoxResult handler function.
         */
        deleteDocument: function (id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: t.collection,
                    id: id,
                    title: `Delete ${t.collectionItemTitle}`,
                    description: `Are you sure you want to delete this ${t.collectionItemTitle}: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong></div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        /**
         * Refresh Document View
         *
         * Load the new document and sets the payload onto ACT_openItem
         */
        async refreshDocumentView() {
            const t = this;

            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            t.form = itemResult.data
            let payload = {}
            payload.process = 'View';
            payload.popup = false;
            payload.data = itemResult.data;

            await t.ACT_openItem(payload);
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        }

    },

    watch: {
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'questions') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (deleteDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Deleted`, deleteDocumentResult.data, null);
                            await t.refreshDocumentView();
                        } else {
                            t.MIX_alert(-1, `Error Deleting Question`, null, null);
                        }
                    }
                } else if (t.GET_confirmBoxResult.data.collection === 'questions/destroy') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const destroyDocumentResult = await t.MIX_destroyDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (destroyDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Destroyed`, destroyDocumentResult.data, null);
                            t.MIX_setPanelLayout(12, 0, false, '', '');
                        } else {
                            t.MIX_alert(-1, `Error Destroying Question`, null, null);
                        }
                    }
                }

            }, deep: true
        }
    },

    async mounted() {
        const t = this

        // Get and populate form data
        const openItem = await t.GET_openItem
        t.formMode = openItem.process;
        if (openItem.process === 'New') {
            t.formReadOnly = false
        } else {
            t.form = openItem.data
            t.formReadOnly = true
        }

        // if (openItem.popup === false) {
        // 	t.updateBreadcrumbs()
        // }

        await t.getContractorUsersCollectionData()
    }

}
</script>
