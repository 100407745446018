var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticStyle:{"height":"100%"},attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-4 ma-0",attrs:{"cols":_vm.GET_panelLayout.leftPanel}},[_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-4 pa-0",attrs:{"cols":"12"}},[_c('page-title',{attrs:{"icon":"icons8-business-building","pageTitle":"Contractor's Area"}})],1),_c('v-col',{staticClass:"pa-0 mb-4",attrs:{"cols":_vm.$vuetify.breakpoint.width <= 600 ? 12 : 6}},[_c('app-input',{attrs:{"input-type":"textInput","clearable":"","label":_vm.$t('filters.search'),"append-icon":'icons8-search'},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex justify-end align-end pa-0 mb-4"},[_c('contractors-invite-dialog',{attrs:{"contractorCompanyId":_vm.GET_currentUser.contractorCompanyId}})],1)],1),(_vm.$vuetify.breakpoint.width >= 600)?_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0"},[_c('v-data-table',{staticClass:"rounded-lg",attrs:{"headers":_vm.computedHeaders,"items":_vm.computedTableData,"search":_vm.search,"sort-by":"userName"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.id))])]}},{key:"item.userStatus",fn:function(ref){
var item = ref.item;
return [(item.userStatus === 'PENDING')?_c('v-icon',{staticClass:"icons8-circle",attrs:{"color":"warning","size":"32"}}):_vm._e(),(item.userStatus === 'APPROVED')?_c('v-icon',{staticClass:"icons8-checkmark-yes",attrs:{"color":"success","size":"32"}}):_vm._e(),(item.userStatus === 'SUSPENDED')?_c('v-icon',{staticClass:"icons8-private",attrs:{"color":"error","size":"32"}}):_vm._e(),(item.userStatus === 'ARCHIVED')?_c('v-icon',{staticClass:"icons8-xbox-a",attrs:{"color":"grey","size":"32"}}):_vm._e()]}},{key:"item.userName",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.userName))])]}},{key:"item.userTelephone",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.userTelephone))])]}},{key:"item.userEmail",fn:function(ref){
var item = ref.item;
return [_c('app-text',{attrs:{"category":"text-small"}},[_vm._v(_vm._s(item.userEmail))])]}},{key:"item.sitePermitStatus",fn:function(ref){
var item = ref.item;
return [(item.sitePermitStatus === 'Pending')?_c('v-icon',{staticClass:"icons8-circle",attrs:{"color":"warning","size":"32"}}):_vm._e(),(item.sitePermitStatus === 'Approved')?_c('v-icon',{staticClass:"icons8-checkmark-yes",attrs:{"color":"success","size":"32"}}):_vm._e(),(item.sitePermitStatus === 'Rejected')?_c('v-icon',{staticClass:"icons8-cancel",attrs:{"color":"error","size":"32"}}):_vm._e(),(item.sitePermitStatus === 'Suspended')?_c('v-icon',{staticClass:"icons8-private",attrs:{"color":"error","size":"32"}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end",staticStyle:{"height":"100%"}},[_c('v-btn',{staticClass:"white text--red",attrs:{"depressed":""},on:{"click":function($event){return _vm.openItem(item.id, 'View', false)}}},[_vm._v("Open "),_c('v-icon',{staticClass:"icons8-forward",attrs:{"color":"primary"}})],1)],1)]}}],null,false,4078486006)})],1)],1):_vm._e(),_c('v-row',{staticClass:"ma-0",attrs:{"no-gutters":""}},[(_vm.$vuetify.breakpoint.width < 600)?_c('v-col',_vm._l((_vm.filteredTableData),function(contractor){return _c('div',{key:contractor.id},[_c('v-card',{staticClass:"rounded-lg mb-4 pa-4",attrs:{"flat":""},on:{"click":function($event){return _vm.openItem(contractor.id, 'View', false)}}},[_c('app-text',{staticClass:"mb-2",attrs:{"category":"text-default-bold"}},[_vm._v(" "+_vm._s(contractor.userName)+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"icons8-person mr-2",attrs:{"color":"primary","size":"16"}}),_c('app-text',{staticClass:"grey--text mr-4"},[_vm._v(" "+_vm._s(contractor.userTelephone)+" ")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"icons8-phone mr-2",attrs:{"color":"primary","size":"16"}}),_c('app-text',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(contractor.userEmail)+" ")])],1)],1)],1)}),0):_vm._e()],1)],1),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animate__animated animate__fadeIn animate__faster","leave-active-class":"animate__animated animate__fadeOut animate__faster","mode":"out-in"}},[_c('rightpanel')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }