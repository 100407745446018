<!--
IT Support

Renders a form for logging IT issues.
When submitting the form it is added to the 'itSupport' collection which is then picked up by a Firebase Function
and sent as an email.
-->
<template>
    <v-row class="ma-4" no-gutters>

        <!--Title-->
        <page-title icon="icons8-computer-support" pageTitle="IT Support"/>

        <!--Instructional text-->
        <v-col cols="12">
            <app-text>Use this form to log a request for IT support</app-text>
        </v-col>

        <!--Divider-->
        <v-col cols="12">
            <v-divider class="mt-4"/>
        </v-col>

        <!--IT support form-->
        <v-form class="mt-4" style="width: 100%">
            <v-row no-gutters>
                <v-col>

                    <!--Title-->
                    <app-input inputType="textInput"
                               :error="errors.supportTitle"
                               label="Title"
                               v-model="form.supportTitle"/>

                    <!--Device type | Asset number-->
                    <v-row no-gutters>

                        <!--Device type-->
                        <v-col :cols="$vuetify.breakpoint.width > 840 ? 6 : 12">
                            <app-input inputType="textInput"
                                       class="mt-4"
                                       :class="$vuetify.breakpoint.width > 840 && 'pr-2'"
                                       :error="errors.supportDeviceType"
                                       label="Device Type"
                                       v-model="form.supportDeviceType"/>
                        </v-col>

                        <!--Asset Number-->
                        <v-col :cols="$vuetify.breakpoint.width > 840 ? 6 : 12">
                            <app-input inputType="textInput"
                                       class="mt-4"
                                       :class="$vuetify.breakpoint.width > 840 && 'pl-2'"
                                       :error="errors.supportAssetNumber"
                                       label="Asset Number"
                                       v-model="form.supportAssetNumber"/>
                        </v-col>

                    </v-row>

                    <!--Description-->
                    <app-input inputType="textArea"
                               class="mt-4"
                               :error="errors.supportDescription"
                               label="Description"
                               rows="8"
                               v-model="form.supportDescription"/>

                    <!--Action buttons-->
                    <v-col class="d-flex mt-4 pa-0">

                        <v-spacer/>

                        <!--Submit-->
                        <app-btn @click.native="validate"
                                 color="success"
                                 label="Submit" icon="icons8-checkmark-yes"/>

                    </v-col>

                </v-col>
            </v-row>
        </v-form>

    </v-row>
</template>

<script>
export default {

    name: "itSupport",

    data: () => ({
        form: {
            supportTitle: '',
            supportDeviceType: '',
            supportAssetNumber: '',
            supportDescription: '',

            createdDateTime: '',
            createdUserData: {},
            modifiedDateTime: '',
            modifiedUserData: {}
        },
        errors: {
            supportTitle: false,
            supportDeviceType: false,
            supportAssetNumber: false,
            supportDescription: false,
        },
    }),

    methods: {

        /**
         * Validate
         *
         * Validate fields for presence, if there are no errors, call to save.
         */
        validate() {
            const t = this

            t.form.supportTitle.trim() ? t.errors.supportTitle = false : t.errors.supportTitle = true
            t.form.supportDeviceType.trim() ? t.errors.supportDeviceType = false : t.errors.supportDeviceType = true
            t.form.supportAssetNumber.trim() ? t.errors.supportAssetNumber = false : t.errors.supportAssetNumber = true
            t.form.supportDescription.trim() ? t.errors.supportDescription = false : t.errors.supportDescription = true

            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Save Questions
         *
         * Save the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        saveDocument: async function () {
            const t = this

            // Create document
            const createDocumentResult = await t.MIX_createDocument('itSupport', t.form)

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok.
         *  -> 'Error' alert if something when wrong.
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `IT support request sent`, null, null)
                t.clearFieldsOnSuccessfulSubmission()
            } else {
                t.MIX_alert(-1, `Error sending IT support request`, null, null)
            }
        },

        /**
         * Clear Fields On Successful Submission
         *
         * If the submission was successful, clear the fields to assert completeness.
         */
        clearFieldsOnSuccessfulSubmission() {
            const t = this

            t.form.supportTitle = ''
            t.form.supportDeviceType = ''
            t.form.supportAssetNumber = ''
            t.form.supportDescription = ''
        }

    },

    async mounted() {
        const t = this

    }
}
</script>
