<!--
Covid User Card

Render the users as cards with the following data:
 - Profile picture
 - Name
 - Player position
 - Swapp status

To save space and for improved readability, the Covid Answers are contained in a dialog with the activation button
colour-coded to signify if the user has passed the Covid screening for the day or not.
-->
<template>
    <div class="cpc-container">

        <!--Image-->
        <div>
            <v-img v-if="user.profilePicFileURL"
                   :src="user.profilePicFileURL"
                   alt="player"
                   aspect-ratio="1"
                   class="cpc-image"
                   cover
                   width="100%"/>

            <v-img v-else
                   :src="require('@/assets/user.png')"
                   alt="player"
                   class="cpc-image"
                   cover
                   width="100%"/>
        </div>

        <!--Name-->
        <app-text category="text-default-bold" class="white primary--text justify-center mb-0">
            {{ user.userName }}
        </app-text>

        <!--Position-->
        <app-text category="text-default-bold" class="white accent--text justify-center">
            {{ user.playerPosition ? user.playerPosition : '-' }}
        </app-text>

        <!--Swapp status-->
        <app-text category="text-default-bold"
                  class="justify-center"
                  :class="computedSwappStatus.color + '--text'">
            {{ computedSwappStatus.label }}
        </app-text>

        <covid-answers-dialog v-on:updateCovidAnswers="updateCovidAnswers" :user="user" :questions="questions" :temperature="temperature"/>

    </div>
</template>

<script>
import covidAnswersDialog from "./covidAnswersDialog/covidAnswersDialog";

export default {
    name: "covidUserCard",

    props: ['user', 'numberOfQuestions'],

    components: {covidAnswersDialog},

    data: () => ({

        questionnaire: {},
        questions: [],
        temperature: '-',

    }),

    computed: {

        /**
         * Computed Swapp Status
         *
         * Return either In or Out depending on the user's SWAPP status.
         *
         * @returns {{color: string, label: string}} swapp status to render
         */
        computedSwappStatus() {
            const t = this
            let status = {
                color: '',
                label: '',
            }

            if (t.$props.user.swappStatus) {
                status.color = 'success'
                status.label = 'IN'
            } else {
                status.color = 'grey'
                status.label = 'OUT'
            }

            return status
        },

        /**
         * Computed Temperature
         *
         * Return a color based on the temperature value.
         *
         * @returns {color} String containing the color
         */
        computedTemperature() {
            const t = this
            let color

            if (t.temperature > 37.8) {
                color = 'error'
            } else if (t.temperature <= 37.8) {
                color = 'success'
            } else {
                color = 'grey'
            }

            return color
        },

    },

    methods: {

        /**
         * Initialise Questions
         *
         * Process the questions to have a proper array of question/answers to render.
         * If the user has not answered any questions either ever (new user), or today, render hyphens in their place.
         */
        initialiseQuestions() {
            const t = this
            const userData = t.$props.user
            const dateTimeThisMorning = t.$moment().startOf('day').add(3, 'hours').format('x')

            // If the questions have ever been answered
            if (userData.answers.length) {

                // loop over the questionnaire
                userData.answers.forEach(questionnaire => {

                    // Get todays answers
                    if (questionnaire.createdDateTime > dateTimeThisMorning) {
                        t.questionnaire = questionnaire
                        t.temperature = questionnaire.temperature
                    }

                    // If the questions have NOT been answered today
                    else {
                        let missingQuestionsArr = []
                        const missingQuestionObj = {
                            answer: '-',
                            question: {
                                questionTitle: 'missing'
                            }
                        }

                        const numberOfQuestions = t.$props.numberOfQuestions
                        for (let i = 0; i < numberOfQuestions; i++) {
                            missingQuestionsArr.push(missingQuestionObj)
                        }

                        t.questions = missingQuestionsArr
                    }
                })

                // Sort todays answers by question title
                if (t.questionnaire.questions) {
                    t.questions = t.questionnaire.questions.sort((a, b) => {
                        return a.questionTitle > b.questionTitle ? 1 : -1
                    })
                }

            }

            // If the questions have NOT ever been answered
            else {
                let missingQuestionsArr = []
                const missingQuestionObj = {
                    answer: '-',
                    question: {
                        questionTitle: 'missing'
                    }
                }

                const numberOfQuestions = t.$props.numberOfQuestions
                for (let i = 0; i < numberOfQuestions; i++) {
                    missingQuestionsArr.push(missingQuestionObj)
                }

                t.questions = missingQuestionsArr
            }

        },

        /**
         * Render Answer Color
         *
         * Render the correct color based on the answer.
         *
         * @param answer - the answer to base the color on.
         * @returns {string} - the color to render.
         */
        renderAnswerColor(answer) {
            let color = ''

            if (answer === 'Yes') {
                color = 'error'
            } else if (answer === 'No') {
                color = 'success'
            } else {
                color = 'grey'
            }

            return color
        },

         /**
         * Reload the questions if covid answers have been edited. Trigger emitted from covid answers dialog.
         *
         */
        updateCovidAnswers() {
            const t = this

            t.initialiseQuestions()
        },


    },

    mounted() {
        const t = this

        t.initialiseQuestions()
    }

}
</script>

<style>

.cpc-container {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background: white;
    border-radius: 8px;
    padding: 8px;
}

.cpc-image {
    border-radius: 4px;
    margin-bottom: 16px;
}

</style>
