<!--
User Profile

Render the user's profile and schedule data.
From here the user can adjust their details and also be advised on missing information.
-->
<template>
    <div>

        <!--Header (Title | Instructional text)-->
        <v-row class="flex-column ma-4" no-gutters>

            <div class="d-flex">

                <!--Page title-->
                <page-title icon="icons8-person" page-title="My Profile"/>

                <v-spacer/>

                <!--Edit-->
                <v-icon @click.native="editDocument"
                        class="icons8-edit"
                        :class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
                        :color="formReadOnly ? 'warning' : 'white'"
                        size="32"/>

            </div>

            <!--Instructional text-->
            <app-text>
                This is your profile.
                From here you can update your personal, and work details.
            </app-text>

        </v-row>

        <v-divider class="ma-4"/>

        <!--Form-->
        <v-row class="ma-0 px-4" no-gutters>

            <!--Profile Picture | User Details-->
            <v-row no-gutters style="width: 100%">

                <!--Profile picture-->
                <v-col
                    class="d-flex justify-center align-center pa-2 rounded-lg white profileImage-container"
                    :class="$vuetify.breakpoint.width >= 600 ? 'flex-grow-0 mr-4' : ''"
                    :cols="$vuetify.breakpoint.width < 600 && 12"
                    style="height: 272px; width: 272px">

                    <!--If an image is present, render it-->
                    <div v-if="form.profilePicFileURL && !tempImage"
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="form.profilePicFileURL"
                               class="rounded-lg" cover height="254" width="254"/>

                        <!--Upload button-->
                        <div v-if="formMode === 'Edit'"
                             style="position: absolute; z-index: 9; margin-top: 200px">

                            <photoupload class="mr-n4"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                    <!--If a tempImage (upload preview) is present, render it-->
                    <div v-else-if="tempImage"
                         class="d-flex flex-column align-center">

                        <!--Image-->
                        <v-img :src="tempImage"
                               class="rounded" cover height="216" width="216"/>

                        <!--Upload button-->
                        <div style="position: absolute; z-index: 9; margin-top: 152px">

                            <photoupload class="mr-n4"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                    <!--If neither an image or tempImage is present, render an icon-->
                    <div v-else class="d-flex flex-column align-center" style="width: 216px">

                        <!--Image-->
                        <v-icon class="icons8-customer" size="216"/>

                        <!--Upload button-->
                        <div v-if="formMode === 'New' || formMode === 'Edit'"
                             style="position: absolute; z-index: 9; margin-top: 152px">

                            <photoupload class="mr-n4"
                                         style="width: 100%"
                                         allowedtypes="image/*"
                                         :docLink="{collection: 'users', documentId: form.id}"
                                         folder="users-profile-pictures"/>

                        </div>

                    </div>

                </v-col>

                <!-- User details -->
                <v-col :class="$vuetify.breakpoint.width >= 600 && 'flex-grow-1'"
                       :cols="$vuetify.breakpoint.width < 600 && 12">

                    <!--Name-->
                    <app-input input-type="textInput"
                               :class="$vuetify.breakpoint.width < 600 && 'mt-4'"
                               :error="errors.userName"
                               :error-messages="errors.userNameErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Full Name"
                               v-model.trim="form.userName"/>

                    <!--Position-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userPosition"
                               :error-messages="errors.userPositionErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Position"
                               v-model.trim="form.userPosition"/>

                    <!--Email-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userEmail"
                               :error-messages="errors.userEmailErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Email"
                               v-model.trim="form.userEmail"/>

                    <!--Telephone-->
                    <app-input input-type="textInput"
                               class="mt-4"
                               :error="errors.userTelephone"
                               :error-messages="errors.userTelephoneErrorMessage"
                               :is-form-read-only="formReadOnly"
                               label="Telephone"
                               type="number"
                               v-model="form.userTelephone"/>

                </v-col>

            </v-row>

            <!-- DOB -->
            <v-col class="mt-4" :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                   cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <date-picker v-on:emitDate="handleUserDOBPicker"
                                 appendIcon="mdi-calendar"
                                 :date="form.userDOB"
                                 :isFormReadOnly="formReadOnly"
                                 label="Date Of Birth"
                                 :minDate="[100, 'years', 'past']"
                                 :maxDate="[16, 'years', 'past']"/>
            </v-col>

           <!-- Start Date -->
            <v-col class="mt-4" :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                   cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <date-picker v-on:emitDate="handleUserStartDatePicker"
                                 appendIcon="mdi-calendar"
                                 :date="form.userStartDate"
                                 :isFormReadOnly="formReadOnly"
                                 label="Start Date"
                                 :minDate="[50, 'years', 'past']"
                                 :maxDate="[0, 'years', 'future']"/>
            </v-col>

            <!-- User Category -->
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="select"
                           class="rounded-lg mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                           :is-form-read-only="formReadOnly"
                           :items="userCategoriesOptionsData"
                           item-text="text"
                           item-value="value"
                           label="User Category"
                           v-model="form.userCategory">
                    <template v-slot:item="data">
                        <app-text>{{ data.item }}</app-text>
                    </template>
                </app-input>
            </v-col>

            <!-- Primary Work Location -->
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="select"
                           class="rounded-lg mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                           :error="errors.usualSite"
                           :error-messages="errors.usualSiteErrorMessage"
                           :is-form-read-only="formReadOnly"
                           :items="sitesCollectionData"
                           item-text="siteName"
                           label="Primary Work Location"
                           v-model="form.usualSite">
                    <template v-slot:item="data">
                        <app-text>{{ data.item.siteName }}</app-text>
                    </template>
                </app-input>
            </v-col>

            <!--Position-->
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                           :is-form-read-only="formReadOnly"
                           label="Player Position"
                           v-model="form.playerPosition"/>
            </v-col>

            <!-- Address -->
            <v-col class="mt-4" cols="12">
                <app-text category="text-medium" class="darkgrey--text">Address</app-text>
                <v-divider class="mt-2"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                           :is-form-read-only="formReadOnly"
                           label="Address Line 1"
                           v-model="form.userAddress.userAddressLine1"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                           :is-form-read-only="formReadOnly"
                           label="Address Line 2"
                           v-model="form.userAddress.userAddressLine2"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                           :is-form-read-only="formReadOnly"
                           label="Address Line 3"
                           v-model="form.userAddress.userAddressLine3"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                           :is-form-read-only="formReadOnly"
                           label="Town"
                           v-model="form.userAddress.userTown"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                           :is-form-read-only="formReadOnly"
                           label="County"
                           v-model="form.userAddress.userCounty"/>
            </v-col>
            <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                           :error="errors.userPostcode"
                           :error-messages="errors.userPostcodeErrorMessage"
                           :is-form-read-only="formReadOnly"
                           label="Postcode"
                           v-model="form.userAddress.userPostcode"/>
            </v-col>

            <!-- Next of Kin -->
            <v-col class="mt-4" cols="12">
                <app-text category="text-medium" class="darkgrey--text">Next of Kin</app-text>
                <v-divider class="mt-2"/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                           :error="errors.NOKName"
                           :error-messages="errors.NOKNameErrorMessage"
                           :is-form-read-only="formReadOnly"
                           label="Name"
                           v-model="form.NOKName"/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'px-2'"
                           :is-form-read-only="formReadOnly"
                           label="Relationship"
                           v-model="form.NOKRelationship"/>
            </v-col>
            <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <app-input input-type="textInput"
                           class="mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                           :error="errors.NOKTelNo"
                           :error-messages="errors.NOKTelNoErrorMessage"
                           :is-form-read-only="formReadOnly"
                           label="Telephone Number"
                           type="number"
                           v-model="form.NOKTelNo"/>
            </v-col>

            <v-row v-if="['SA'].includes(GET_currentUser.userLevel)" no-gutters>

                <!--Access & Abilities | Observation Responsibilities-->
                <v-col class="mt-4" cols="12">
                    <app-text category="text-medium" class="darkgrey--text">Access, Abilities and
                        Observation Responsibilities
                    </app-text>
                    <v-divider class="mt-2"/>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                    <app-input input-type="select"
                               chips
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                               deletable-chips
                               :disabled="true"
                               :is-form-read-only="formReadOnly"
                               :items="userAccessAbilitiesOptionsData"
                               label="Access and Abilities"
                               multiple
                               small-chips
                               v-model="form.userAccessAndAbilities"/>
                </v-col>
                <v-col cols="12" xs="12" sm="6">
                    <app-input input-type="select"
                               chips
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                               deletable-chips
                               :disabled="true"
                               :is-form-read-only="formReadOnly"
                               :items="observationTypesOptionsData"
                               label="Observation Responsibilities"
                               multiple
                               small-chips
                               v-model="form.userObservationResponsibilities"/>
                </v-col>

                <!-- Configuration (User Type | User Role | User Status) -->
                <v-col class="mt-4" cols="12">
                    <app-text category="text-medium" class="darkgrey--text">Configuration</app-text>
                    <v-divider class="mt-2"/>
                </v-col>

                <!--User type-->
                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <app-input input-type="select"
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                               :disabled="true"
                               :is-form-read-only="formReadOnly"
                               :items="userTypesOptionsData"
                               label="User type"
                               v-model="form.userType">
                        <template v-slot:item="data">
                            <app-text>{{ data.item }}</app-text>
                        </template>
                    </app-input>
                </v-col>

                <!--User role-->
                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <app-input input-type="select"
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 && 'px-2'"
                               :disabled="true"
                               :is-form-read-only="formReadOnly"
                               :items="computedUserRoles"
                               label="User Role"
                               v-model="form.userRole">
                        <template v-slot:item="data">
                            <app-text>{{ data.item }}</app-text>
                        </template>
                    </app-input>
                </v-col>

                <!--User status-->
                <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <app-input input-type="select"
                               class="mt-4"
                               :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                               :disabled="true"
                               :is-form-read-only="formReadOnly"
                               :items="userStatusOptionsData"
                               label="User Status"
                               v-model="form.userStatus">
                        <template v-slot:item="data">
                            <app-text>{{ data.item }}</app-text>
                        </template>
                    </app-input>
                </v-col>
            </v-row>

            <!--Save-->
            <v-col cols="12" class="d-flex justify-end my-4">
                <app-btn v-if="formMode === 'Edit' || formMode === 'New'"
                         @click.native="validateForm"
                         color="success"
                         icon="icons8-save"
                         label="Save"/>
            </v-col>

        </v-row>

    </div>
</template>

<script>
import {mapGetters} from "vuex";
import DatePicker from "../../modules/datePicker/datePicker_component"
import userSchedule from "../userSchedule/userSchedule";

export default {
    name: "userProfile",

    components: {
        DatePicker,
        userSchedule
    },

    data: () => ({

        errors: {
            userName: false,
            userNameErrorMessage: '',
            userPosition: false,
            userPositionErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',

            userPostcode: false,
            userPostcodeErrorMessage: '',
            NOKName: false,
            NOKNameErrorMessage: '',
            NOKTelNo: false,
            NOKTelNoErrorMessage: '',
            usualSite: false,
            usualSiteErrorMessage: '',
        },


        formMode: 'View',
        formReadOnly: true,
        formBackground: 'grey lighten-3',
        form: {
            id: '',
            userName: '',
            userAddress: {
                userAddressLine1: '',
                userAddressLine2: '',
                userAddressLine3: '',
                userTown: '',
                userCounty: '',
                userPostcode: '',
            },
            NOKName: '',
            NOKRelationship: '',
            NOKTelNo: '',
            playerPosition: '',
            profilePicFileURL: null,
            userDOB: '',
            userEmail: '',
            userPosition: '',
            userLevel: '',
            userStartDate: '',
            userTelephone: '',
            usualSite: '',

            createdUserData: {},
            createdDateTime: '',
            modifiedUserData: {},
            modifiedDateTime: '',
        },

        // Profile picture
        types: "image/*",
        storagePathProfilePic: "users-profile-pictures",
        photoUploadResult: {},
        tempImage: '',

        sitesCollectionData: [],

    }),

    computed: {
        ...mapGetters({
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),

        /**
         * Computed User Roles
         *
         * Return the correct user roles for the specified user type.
         * @returns {Array} user roles as strings
         */
        computedUserRoles() {
            const t = this
            let roles = []
            const type = t.form.userType

            if (type === 'Staff') {
                roles = ['Admin', 'Manager', 'User']
            } else if (type === 'Contractor') {
                roles = ['User']
                t.form.userRole = 'User'
            } else if (type === 'Player') {
                roles = ['User']
                t.form.userRole = 'User'
            } else if (type === 'Visitor') {
                roles = ['User']
                t.form.userRole = 'User'
            } else if (type === 'Developer') {
                roles = ['User']
                t.form.userRole = 'User'
            }

            return roles
        },

    },

    methods: {

        /**
         * Get User Collection Data
         *
         * Fetch user collection data for the current user and sync it to the form.
         *
         * @returns {Promise<void>}
         */
        async getUserCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            if (doc.exists) {
                t.userCollectionData = doc.data()
                t.form = doc.data()
            } else {
                console.log('UserProfile error fetch ing user data')
            }

        },

        /**
         * Get Sites Collection Data
         *
         * Fetch sites collection data.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Clear the collection data to avoid duplications
                t.sitesCollectionData = []

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesCollectionData.push(document)
                    }

                })
            })
        },

        /**
         * Edit Document
         *
         * Set the form to an editable state.
         */
        editDocument() {
            const t = this

            if (t.formMode === 'View') {
                t.formMode = 'Edit'
                t.formReadOnly = false
                t.formBackground = "white"
            } else if (t.formMode === 'Edit') {
                t.cancelDocument()
            }

        },

        /**
         * Cancel Document
         *
         * Set the form to a viewable state and refresh the user data.
         *
         * @returns {Promise<void>}
         */
        async cancelDocument() {
            const t = this

            t.formMode = "View"
            t.formReadOnly = true
            t.formBackground = "grey lighten-3"
            t.tempImage = '';

            // Refresh user data
            await t.getUserCollectionData()
        },

        /**
         * Validate
         *
         * Validates the required fields for presence only.
         * If any of the fields are missing mark them in an errors object.
         * When there are no errors left, save the record.
         */
        validateForm() {
            const t = this
            const emailRegex = /.+@.+\..+/
            const postcodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/

            t.errors.userName = false
            t.errors.userNameErrorMessage = ''
            t.errors.userPosition = false
            t.errors.userPositionErrorMessage = ''
            t.errors.userEmail = false
            t.errors.userEmailErrorMessage = ''
            t.errors.userTelephone = false
            t.errors.userTelephoneErrorMessage = ''
            t.errors.userPostcode = false
            t.errors.userPostcodeErrorMessage = ''
            t.errors.NOKName = false
            t.errors.NOKNameErrorMessage = ''
            t.errors.NOKTelNo = false
            t.errors.NOKTelNoErrorMessage = ''
            t.errors.usualSite = false
            t.errors.usualSiteErrorMessage = ''

            // Name
            if (!t.form.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name required'
            }
            // Must be between 2 and 60 characters
            else if (t.form.userName.trim().length < 2 || t.form.userName.trim().length > 60) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
            }

            // Position
            if (!t.form.userPosition.trim()) {
                t.errors.userPosition = true
                t.errors.userPositionErrorMessage = 'Position required'
            }

            // Email
            if (!t.form.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email required'
            }
            // Must be a (simple) valid email address
            else if (!emailRegex.test(t.form.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email not valid'
            }

            // Telephone
            if (!t.form.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Telephone Number required'
            }
            // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.form.userTelephone.trim()[1]) && (t.form.userTelephone.trim().length < 10 || t.form.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.form.userTelephone.trim()[1]) && t.form.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Number must start 01, 02, 03 or 07
            else if (!['0'].includes(t.form.userTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Primary Work Location
            if (!t.form.usualSite) {
                t.errors.usualSite = true
                t.errors.usualSiteErrorMessage = 'Primary Work Location required'
            }

            // Postcode
            if (t.form.userAddress.userPostcode.trim() && !postcodeRegex.test(t.form.userAddress.userPostcode.trim())) {
                t.errors.userPostcode = true
                t.errors.userPostcodeErrorMessage = 'Postcode not valid'
            }

            // NOK Name
            // Must be between 2 and 60 characters
            if (t.form.NOKName && (t.form.NOKName.trim().length < 2 || t.form.NOKName.trim().length > 60)) {
                t.errors.NOKName = true
                t.errors.NOKNameErrorMessage = 'Name must be between 2 and 60 characters'
            }

            // NOK Telephone
            // Telephone
            if (t.form.NOKTelNo) {

                // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
                if (['1', '2', '3'].includes(t.form.NOKTelNo.trim()[1]) && (t.form.NOKTelNo.trim().length < 10 || t.form.NOKTelNo.trim().length > 11)) {
                    t.errors.NOKTelNo = true
                    t.errors.NOKTelNoErrorMessage = 'Landline numbers must have either 10 or 11 digits'
                }
                // Mobile number must start 07 and be 11 digits
                else if (['7'].includes(t.form.NOKTelNo.trim()[1]) && t.form.NOKTelNo.trim().length !== 11) {
                    t.errors.NOKTelNo = true
                    t.errors.NOKTelNoErrorMessage = 'Mobile numbers must have 11 digits'
                }
                // Number must start 01, 02, 03 or 07
                else if (!['0'].includes(t.form.NOKTelNo[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.form.NOKTelNo[1])) {
                    t.errors.NOKTelNo = true
                    t.errors.NOKTelNoErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
                }

            }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.setUserLevel()
                t.saveDocument()
            }
        },

        /**
         * Set User Level
         *
         * Configure the user's user level from their user type and user role.
         */
        setUserLevel() {
            const t = this

            t.form.userLevel = t.form.userType[0] + t.form.userRole[0]
        },

        /**
         * Save Document
         *
         * Update the user document.
         * Set the form to a viewable state.
         * Call to upload the profile picture if one has been selected.
         * Refresh the user data.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this

            const updateDocumentResult = await this.MIX_updateDocument('users', t.form)

            if (updateDocumentResult.code === 1) {

                t.formBackground = "grey lighten-3"
                t.formMode = "View"
                t.formReadOnly = true
                t.tempImage = ''

                // Add uploaded profile image
                await t.uploadProfileImage()

                // Refresh user data
                await t.getUserCollectionData()

                t.MIX_go('/')

            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(updateDocumentResult, 'Profile successfully updated', 'Error updating profile')
        },

        /**
         * Upload Profile Image
         *
         * Update the user's document with a profile image path  (collection | user id | image path).
         *
         * @returns {Promise<void>}
         */
        async uploadProfileImage() {
            const t = this

            if (t.photoUploadResult !== {}) {

                // Save to the document with: collection | user id | image path
                const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                    'users', t.photoUploadResult.docLink, {profilePicFileURL: t.photoUploadResult.fileURL})

                // Call for a confirmation alert
                t.renderConfirmationAlert(updatePhotosResult, 'Photo successfully updated', 'Error updating photo')

            }
        },

        /**
         * Render Confirmation Alert
         *
         * Take the result of a document db change and render a confirmation box to the user.
         */
        renderConfirmationAlert(document, successMessage, failureMessage) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, successMessage, null, null)
            } else {
                t.MIX_alert(-1, failureMessage, null, document.error)
            }

        },

        /**
         * Handle User DOB Date Picker
         *
         * Takes the time emitted from DatePicker and assigns it to the form data.
         *
         * @param date
         */
        handleUserDOBPicker(date) {
            const t = this

            // Convert to Unix timestamp
            date = t.$moment(date).format('x')
            t.form.userDOB = date
        },

        /**
         * Handle User Start Date Picker
         *
         * Takes the date emitted from DatePicker and assigns it to the form data.
         *
         * @param date
         */
        handleUserStartDatePicker(date) {
            const t = this

            // Convert to Unix timestamp
            date = t.$moment(date).format('x')
            t.form.userStartDate = date
        }

    },

    watch: {

        /**
         * Photo Upload Result
         *
         * Watch for the result of a profile picture upload and add its storage path to the photoUploadResult variable.
         */
        GET_photoUploadResult: {
            handler: async function () {
                const t = this

                t.photoUploadResult = t.GET_photoUploadResult
                t.tempImage = t.photoUploadResult.fileURL

            }, deep: true
        },

    },

    async mounted() {
        const t = this

        // Fetch required collection data
        await t.getUserCollectionData()
        await t.getSitesCollectionData()

    }
}
</script>
