<template>
    <v-form>
        <v-row no-gutters>

            <!--Company-->
            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'">
                <app-input input-type="textInput"
                           append-icon="icons8-business-building"
                           :disabled="true"
                           :error="errors.contractorName"
                           :error-messages="errors.contractorNameErrorMessage"
                           label="Company"
                           v-model.trim="form.contractor.contractorName"/>
            </v-col>

            <!--Name-->
            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                   :class="$vuetify.breakpoint.width >= 600 ? 'pl-2' : 'mt-4'">
                <app-input input-type="textInput"
                           append-icon="icons8-person"
                           :error="errors.userName"
                           :error-messages="errors.userNameErrorMessage"
                           :disabled="true"
                           label="Name"
                           v-model.trim="form.user.userName"/>
            </v-col>

            <!--Start Date-->
            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                   class="mt-4">
                <date-picker v-on:emitDate="handleEmittedStartDate"
                             :date="form.startDate"
                             :error="errors.startDate"
                             :error-messages="errors.startDateErrorMessage"
                             :is-form-read-only="isFormReadOnly"
                             label="Start Date"
                             :min-date="[0, '', 'past']"
                             :max-date="[1, 'weeks', 'future']"/>
            </v-col>

            <!--End Date-->
            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                   :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                   class="mt-4">
                <date-picker v-on:emitDate="handleEmittedEndDate"
                             :date="form.endDate"
                             :error="errors.endDate"
                             :error-messages="errors.endDateErrorMessage"
                             :is-form-read-only="isFormReadOnly"
                             label="End Date"
                             :min-date="[0, '', 'past']"
                             :max-date="[1, 'years', 'future']"/>
            </v-col>

            <!--Reason-->
            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                   :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                   class="mt-4">
                <app-input input-type="textInput"
                           :error="errors.reason"
                           :error-messages="errors.reasonErrorMessage"
                           :is-form-read-only="isFormReadOnly"
                           label="Reason"
                           v-model.trim="form.reason"/>
            </v-col>

            <!--Status-->
            <v-col :cols="$vuetify.breakpoint.width < 600 ? 12 : 6"
                   :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                   class="mt-4">
                <app-input input-type="select"
                           append-icon="icons8-circle"
                           :disabled="!['SA'].includes(GET_currentUser.userLevel)"
                           :error="errors.status"
                           :error-messages="errors.statusErrorMessage"
                           :items="sitePermitStatusOptionsData"
                           :is-form-read-only="isFormReadOnly"
                           label="Status"
                           v-model="form.status"/>
            </v-col>

            <!--Description-->
            <v-col cols="12"
                   :class="$vuetify.breakpoint.width < 600 && 'mt-4'"
                   class="mt-4">
                <app-input input-type="textArea"
                           :error="errors.description"
                           :error-messages="errors.descriptionErrorMessage"
                           :is-form-read-only="isFormReadOnly"
                           label="Description"
                           v-model.trim="form.description"/>
            </v-col>

            <!--Save button-->
            <v-col cols="12">
                <div class="d-flex my-4">
                    <v-spacer/>
                    <app-btn v-if="formMode !== 'View'"
                             @click.native="validateForm"
                             color="success"
                             icon="icons8-save"
                             label="Save"/>
                </div>
            </v-col>

        </v-row>
    </v-form>
</template>

<script>
import DatePicker from "../modules/datePicker/datePicker_component";

export default {

    name: "sitePermit",

    components: {DatePicker},

    props: ['formMode', 'user'],

    data: () => ({

        errors: {
            contractorName: false,
            contractorNameErrorMessage: '',
            description: false,
            descriptionErrorMessage: '',
            endDate: false,
            endDateErrorMessage: '',
            reason: false,
            reasonErrorMessage: '',
            startDate: false,
            startDateErrorMessage: '',
            status: false,
            statusErrorMessage: '',
            userName: false,
            userNameErrorMessage: ''
        },
        form: {
            id: '',
            contractor: {
                id: '',
                contractorName: '',
            },
            description: '',
            endDate: '',
            reason: '',
            startDate: '',
            status: 'Pending',
            user: {
                id: '',
                userName: ''
            }
        },
        isFormReadOnly: true,

    }),

    methods: {

        /**
         * Get Contractor Collection Data
         *
         * Fetch the collection data for the selected User's Contractor.
         *
         * @returns {Promise<void>}
         */
        async getContractorCollectionData() {
            const t = this

            const collection = await t.$firebase.db.collection('contractors').doc(t.form.user.contractorCompanyId)
            const doc = await collection.get()

            if (doc.exists) {
                t.form.contractor = doc.data()
            }

        },

        async getSitePermitCollectionData() {
            const t = this
            const userPermits = []

            const collection = await t.$firebase.db.collection('sitePermits').where("user.id", "==", t.form.user.id)
            collection.onSnapshot(snapshot => {

                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    userPermits.push(document)

                })

                // If the user has existing permits, get the latest one and set it
                if (userPermits.length) {
                    userPermits.sort((a, b) => {
                        return a.modifiedDateTime < b.modifiedDateTime ? 1 : -1
                    })
                    t.form = userPermits[0]
                }

            })

        },

        /**
         * Handle Edit
         *
         * Called from watcher:formMode to set the form to read only as the edit button is outside of this component.
         */
        handleEdit() {
            const t = this
            const formMode = t.$props.formMode

            if (formMode === 'View') {
                t.isFormReadOnly = true
                this.getSitePermitCollectionData()
                t.clearErrors()
            } else {
                t.isFormReadOnly = false
            }

        },

        /**
         * Clear Errors
         *
         * Clear any errors and error messages.
         */
        clearErrors() {
            const t = this

            t.errors.contractorName = false
            t.errors.contractorNameErrorMessage = ''
            t.errors.description = false
            t.errors.descriptionErrorMessage = ''
            t.errors.endDate = false
            t.errors.endDateErrorMessage = ''
            t.errors.reason = false
            t.errors.reasonErrorMessage = ''
            t.errors.startDate = false
            t.errors.startDateErrorMessage = ''
            t.errors.status = false
            t.errors.statusErrorMessage = ''
            t.errors.userName = false
            t.errors.userNameErrorMessage = ''
        },

        /**
         * Handle Emitted Start Date
         *
         * Get the date from the picker, convert and save it.
         *
         * @param date timestamp from picker
         */
        handleEmittedStartDate(date) {
            const t = this

            // Convert to Unix timestamp
            date = t.$moment(date).format('x')

            t.form.startDate = date

        },

        /**
         * Handle Emitted Start Date
         *
         * Get the date from the picker, convert and save it.
         *
         * @param date timestamp from picker
         */
        handleEmittedEndDate(date) {
            const t = this

            // Convert to Unix timestamp
            date = t.$moment(date).format('x')

            t.form.endDate = date

        },

        /**
         * Validate Form
         *
         * Validate all form fields for presence.
         */
        validateForm() {
            const t = this

            t.clearErrors()

            // Contractor Name
            if (!t.form.contractor.contractorName.trim()) {
                t.errors.contractorName = true
                t.errors.contractorNameErrorMessage = 'Contractor required'
            }

            // User Name
            if (!t.form.user.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'User Name required'
            }

            // Start Date
            if (!t.form.startDate) {
                t.errors.startDate = true
                t.errors.startDateErrorMessage = 'Start Date required'
            }

            // End Date
            if (!t.form.endDate) {
                t.errors.endDate = true
                t.errors.endDateErrorMessage = 'End Date required'
            }

            // Reason
            if (!t.form.reason) {
                t.errors.reason = true
                t.errors.reasonErrorMessage = 'Reason required'
            }

            // Status
            if (!t.form.status) {
                t.errors.status = true
                t.errors.statusErrorMessage = 'Status required'
            }

            // Description
            if (!t.form.description.trim()) {
                t.errors.description = true
                t.errors.descriptionErrorMessage = 'Description required'
            }

            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Save Document
         *
         * Create a new Site Permit.
         * On successful creation, emit 'true' back to the parent to set its formMode which will update this form.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let documentResult

            if (!t.form.id) {
                documentResult = await t.MIX_createDocument('sitePermits', t.form)
            } else {
                documentResult = await t.MIX_updateDocument('sitePermits', t.form)
            }

            if (documentResult) {
                t.$emit('emitCreatedSitePermit', true)
                await t.MIX_updateDocumentFieldsById('users', t.form.user.id, {sitePermitStatus: t.form.status})
                t.renderConfirmationAlert(documentResult)
            }
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `Site Permit saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving Site Permit`, null, null)
            }
        },

    },

    watch: {

        /**
         * Form Mode
         *
         * The edit button is outside of this component so watch for its value change and handle it.
         */
        formMode: {
            handler() {
                const t = this

                t.handleEdit()
            }
        }

    },

    async mounted() {
        const t = this

        t.form.user = t.$props.user
        t.isFormReadOnly = t.$props.formMode === 'View'

        // Get collection data
        await t.getContractorCollectionData()
        await t.getSitePermitCollectionData()
    }

}
</script>
