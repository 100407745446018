<template>
    <div>

        <!--Counters-->
        <div class="frcr-counter-container">

            <!--Time taken-->
            <!--Not for small screens-->
            <div v-if="$vuetify.breakpoint.width > 424" class="ffrcr-counter">
                <app-text category="text-small">Time taken</app-text>
                <app-text category="text-default-bold">
                    {{ computedTimeTaken }}
                </app-text>
            </div>

            <v-divider v-if="$vuetify.breakpoint.width > 424" vertical/>

            <!--In-->
            <div class="ffrcr-counter">
                <app-text category="text-small">IN</app-text>
                <app-text category="text-default-bold">
                    {{ computedSelectedUsersSwappedIn }} / {{ computedUsersSwappedIn }}
                </app-text>
            </div>

            <v-divider vertical/>

            <!--Out-->
            <div class="ffrcr-counter">
                <app-text category="text-small">OUT</app-text>
                <app-text category="text-default-bold">
                    {{ computedSelectedUsersSwappedOut }} / {{ computedUsersSwappedOut }}
                </app-text>
            </div>

            <v-divider vertical/>

            <!--Total-->
            <div class="ffrcr-counter">
                <app-text category="text-small">Total</app-text>
                <app-text category="text-default-bold">
                    {{ computedSelectedUsersTotal }} / {{ computedUsersTotal }}
                </app-text>
            </div>

        </div>

        <!--Time taken-->
        <!--Small screens only-->
        <div v-if="$vuetify.breakpoint.width <= 424" class="frcr-counter-container">
            <div class="ffrcr-counter">
                <app-text category="text-small">Time taken</app-text>
                <app-text category="text-default-bold">
                    {{ computedTimeTaken }}
                </app-text>
            </div>
        </div>

        <!--Search-->
        <v-row no-gutters>
            <v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12">
                <app-input input-type="textInput"
                           :append-icon="'icons8-search'"
                           class="rounded-lg mt-4"
                           :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                           clearable
                           label="Search"
                           solo
                           v-model="filterBySearch"/>
            </v-col>
        </v-row>

        <!--User cards-->
        <div class="frcr-grid">
            <div v-for="user in computedUsers" :key="user.id">

                <fire-roll-call-user-card v-on:fireRollCallSelection="handleUserSelection" :user="user"/>

            </div>
        </div>

        <v-divider class="my-4"/>

        <!--Form-->
        <v-form>

            <!--Title | Type | Notes-->
            <v-row no-gutters>

                <!--Title-->
                <v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12">
                    <app-input input-type="textInput"
                               class="rounded-lg mb-4"
                               :class="$vuetify.breakpoint.width >= 600 && 'pr-2'"
                               :error="errors.title"
                               :error-messages="errors.titleErrorMessage"
                               label="Title"
                               v-model="form.title"/>
                </v-col>

                <!--Type-->
                <v-col :cols="$vuetify.breakpoint.width >= 600 ? 6 : 12" md="6">
                    <app-input input-type="select"
                               class="rounded-lg mb-4"
                               :class="$vuetify.breakpoint.width >= 600 && 'pl-2'"
                               :error="errors.type"
                               :error-messages="errors.typeErrorMessage"
                               :items="['Drill', 'Emergency']"
                               label="Type"
                               v-model="form.type"/>
                </v-col>

                <!--Notes-->
                <v-col cols="12">
                    <app-input input-type="textArea"
                               class="rounded-lg"
                               :error="errors.notes"
                               :error-messages="errors.notesErrorMessage"
                               label="Notes"
                               :rows="4"
                               v-model="form.notes"/>
                </v-col>

            </v-row>

            <!--Save button-->
            <div class="d-flex">
                <v-spacer/>
                <app-btn @click.native="validateForm"
                         :block="$vuetify.breakpoint.width < 600"
                         class="mt-4"
                         color="success"
                         icon="icons8-save"
                         label="Save Fire Roll Call"/>
            </div>

        </v-form>

    </div>
</template>

<script>
import FireRollCallUserCard from "./fireRollCallUserCard/fireRollCallUserCard";

export default {

    name: "fireRollCallRegister",

    props: ['users'],

    components: {FireRollCallUserCard},

    data: () => ({

        errors: {
            notes: false,
            notesErrorMessage: '',
            title: false,
            titleErrorMessage: '',
            type: false,
            typeErrorMessage: '',
        },
        filterBySearch: '',
        form: {
            checkedUsers: [],
            notes: '',
            timeTaken: 0,
            title: '',
            type: '',
        },
        timer: null,
        usersData: [],
    }),

    computed: {

        /**
         * Computed Users
         *
         * Sort the users array by the user's swapp status ('in' first), then alphabetically.
         *
         * @returns {Array} sorted list of users
         */
        computedUsers() {
            const t = this
            let users = t.usersData

            // Filter by search
            if (t.filterBySearch) {
                users = t.usersData.filter(user => user.userName.toUpperCase().match(t.filterBySearch.toUpperCase()))
            }

            // Sort by SWAPP status, then alphabetically
            users.sort((a, b) => {

                // Swapp - in first
                if (a.swappStatus < b.swappStatus) return 1
                if (a.swappStatus > b.swappStatus) return -1
                // UserName - a-z
                if (a.userName > b.userName) return 1
                if (a.userName < b.userName) return -1

            })

            return users
        },

        /**
         * Computed Selected Users Swapped In
         *
         * Returns the number of selected users that are SWAPPed in.
         *
         * @returns {number}
         */
        computedSelectedUsersSwappedIn() {
            const t = this

            return t.form.checkedUsers.filter(user => user.swappStatus === 1).length
        },

        /**
         * Computed Users Swapped In
         *
         * Returns the total number of SWAPPed in users.
         *
         * @returns {number}
         */
        computedUsersSwappedIn() {
            const t = this

            return t.$props.users.filter(user => user.swappStatus === 1).length
        },

        /**
         * Computed Selected Users Swapped Out
         *
         * Returns the number of selected users that are SWAPPed out.
         *
         * @returns {number}
         */
        computedSelectedUsersSwappedOut() {
            const t = this

            return t.form.checkedUsers.filter(user => user.swappStatus === 0).length
        },

        /**
         * Computed Users Swapped Out
         *
         * Returns the total number of SWAPPed out users.
         *
         * @returns {number}
         */
        computedUsersSwappedOut() {
            const t = this

            return t.$props.users.filter(user => user.swappStatus === 0).length
        },

        /**
         * Computed Selected Users Total
         *
         * Returns the number of selected users that are SWAPPed in or out.
         *
         * @returns {number}
         */
        computedSelectedUsersTotal() {
            const t = this

            return t.form.checkedUsers.length
        },

        /**
         * Computed Users Total
         *
         * Returns the total number of users.
         *
         * @returns {number}
         */
        computedUsersTotal() {
            const t = this

            return t.$props.users.length
        },

        /**
         * Computed Time Taken
         *
         * Returns the time taken to complete the Fire Roll Call, formatted to H:MM:SS.
         *
         * @returns {string}
         */
        computedTimeTaken() {
            const t = this

            const timeInMillis = new Date(1000 * Math.round(t.form.timeTaken / 1000))
            return `
            ${timeInMillis.getUTCHours()} :
            ${('0' + timeInMillis.getUTCMinutes()).slice(-2)} :
            ${('0' + timeInMillis.getUTCSeconds()).slice(-2)}`
        },

    },

    methods: {

        /**
         * Handle User Selection
         *
         * Add or remove the selected User to/from the checkedUsers array.
         */
        handleUserSelection(userPayload) {
            const t = this

            // If the user has been checked, uncheck them
            if (t.form.checkedUsers.some(user => user.id === userPayload.id && user.checked)) {
                delete userPayload.checked
                t.form.checkedUsers = t.form.checkedUsers.filter(user => user.id !== userPayload.id)
            }

            // If the user has not been checked, check them
            else {
                userPayload.checked = true
                t.form.checkedUsers.push(userPayload)
            }

        },

        /**
         * Validate Form
         *
         * Validate Title, Type and Notes for presence.
         * Selected Users are not validated.
         * If there are no errors, call to save the document.
         */
        validateForm() {
            const t = this

            t.stopTimer()

            t.errors.title = false
            t.errors.titleErrorMessage = ''
            t.errors.type = false
            t.errors.typeErrorMessage = ''
            t.errors.notes = false
            t.errors.notesErrorMessage = ''

            // Title
            if (!t.form.title.trim()) {
                t.errors.title = true
                t.errors.titleErrorMessage = 'Title required'
            }

            // Type
            if (!t.form.type) {
                t.errors.type = true
                t.errors.typeErrorMessage = 'Type required'
            }

            // Notes
            if (!t.form.notes.trim()) {
                t.errors.notes = true
                t.errors.notesErrorMessage = 'Notes required'
            }

            if (!Object.values(t.errors).includes(true)) {
                t.saveDocument()
            }
        },

        /**
         * Start Timer
         *
         * Starts the Fire Roll Call timer and updates timeTaken every second.
         */
        startTimer() {
            const t = this

            t.timer = setInterval(() => {
                t.form.timeTaken += 1000
            }, 1000)
        },

        /**
         * Stop Timer
         *
         * Stop and clear the timer.
         */
        stopTimer() {
            const t = this

            clearInterval(t.timer)
        },

        /**
         * Save Document
         *
         * Save a new Fire Rol Call with the form data.
         * If the document has saved successfully, call to close the register and refresh the page.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this

            const createDocument = await t.MIX_createDocument('fireRollCall', t.form)

            if (createDocument.code === 1) {
                t.$emit('emitCloseRegister')
                t.MIX_go('fireRollCall')
            }
        },

    },

    mounted() {
        const t = this

        t.usersData = t.$props.users

        t.startTimer()
    },

    /**
     * Before Destroy
     *
     * Kill any running processes.
     */
    beforeDestroy() {
        const t = this

        t.stopTimer()
    }

}
</script>

<style>
.frcr-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(calc(360px - 32px - 16px), 1fr)); /* width - page padding - scrollbar */
    grid-gap: 16px;

    margin-top: 16px;
}

.frcr-counter-container {
    display: flex;
    align-self: center;
    justify-content: center;
    grid-gap: 32px;

    background: white;
    border-radius: 8px;
    margin-top: 16px;
    padding: 8px 16px;
    width: calc(100%);
    z-index: 999;
}

.ffrcr-counter {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
