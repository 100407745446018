import {mapActions} from 'vuex';

const location_mixins = {

	data: () => ({}),

	methods: {
		...mapActions('location_store', ['ACT_location']),

		MIX_location(show, collectionName, id, locations, siteDetails) {
			const payload = {};
			payload.show = show;
			payload.collectionName = collectionName;
			payload.id = id;
			payload.locations = locations;
			payload.siteDetails = siteDetails;
			this.ACT_location(payload);
		},

	}
}

export default {
	install(Vue, options) {
		Vue.mixin(location_mixins)
	}
};
