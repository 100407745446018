<!-- The comment component -->
<template>
    <v-dialog v-model="GET_puDialog.show" :max-width="GET_puDialog.width" :max-height="GET_puDialog.height">

        <v-card ref="popup" class="appbackground" :style="dynamicStyle">

            <div class="grey darken-2 text-right">
                <appbutton icon="icons8-multiply" type="grey darken-2" iconclass="white--text"
                           @click.native="closepuDialog"/>
            </div>

            <div>
                <component :is="GET_puDialog.dynamicComponent" :key="dynamicComponentKey"></component>
            </div>

        </v-card>

    </v-dialog>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {

    data: () => ({
        dynamicComponent: '',
        dynamicComponentKey: 0,
    }),

    computed: {
        ...mapGetters({
            GET_puDialog: 'puDialog_store/GET_puDialog',
        }),
        dynamicStyle() {
            return `width: ${this.GET_puDialog.width}px !important; min-width: ${this.GET_puDialog.width}px !important; max-width: ${this.GET_puDialog.width}px !important; height: ${this.GET_puDialog.height}px !important; min-height: ${this.GET_puDialog.height}px !important; max-height: ${this.GET_puDialog.height}px !important; `
        }
    },

    watch: {
        GET_puDialog: {
            handler() {
                this.dynamicComponentKey++
//                    this.$refs.popup.scrollIntoView({behavior: 'smooth'})

            }, deep: true
        }
    },

    methods: {
        closepuDialog() {
            this.MIX_puDialog(false, '', '', ''); // Hide Comments Dialog
        },
    },

}
</script>

