<!--
Covid Questions

This is the form for the Covid Questions.
The 'isSwappEnabled' boolean
-->
<template>
    <v-expansion-panels flat>
        <v-expansion-panel class="rounded-lg">

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex align-center">

                    <!--Icon-->
                    <v-icon class="icons8-coronavirus mr-4" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text">Covid-19 Screening</app-text>

                </div>

                <!--Message-->
                <app-text v-if="!hasFormBeenFilledToday"
                          class="mt-4"
                          style="line-height: 24px">
                    You are required to answer the following Covid-19 Screening Questions on the day of your visit
                </app-text>

                <!--Responses-->
                <div>

                    <!--Bad result-->
                    <app-text v-if="hasBadAnswer" class="mt-4" style="line-height: 24px">
                        <v-icon class="icons8-no-entry flash pb-1 mr-4" color="error"/>
                        <span class="text-default-bold">Do not enter this site</span>
                        <br><br>
                        Due to the answers you have provided, you must contact a member of management for
                        further
                        instructions
                    </app-text>

                    <!--Good result-->
                    <app-text v-if="isSwappEnabled" class="mt-4" style="line-height: 24px">
                        <v-icon class="icons8-checkmark-yes pb-1" color="success"/>
                        You have completed the Covid Screening for today
                    </app-text>

                </div>

            </v-expansion-panel-header>

            <!--Body-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Button-->
                <app-btn @click.native="isFormVisible = !isFormVisible"
                         block
                         :disabled="hasFormBeenFilledToday"
                         icon="icons8-ask-question"
                         label="Covid-19 Screening"/>

                <!--Questions and Answers-->
                <v-form v-if="isFormVisible" class="home-form">
                    <v-row v-if="isPageLoading" class="justify-center align-center fill-height mt-4" no-gutters>
                        <v-progress-circular v-if="isPageLoading"
                                             color="primary"
                                             indeterminate
                                             size="80"/>
                    </v-row>

                    <div v-for="question in computedQuestionsCollectionData">

                        <!--Title-->
                        <app-text category="text-default-bold" class="primary--text mt-4">
                            {{ question.questionTitle }}
                        </app-text>

                        <!--Question-->
                        <app-text class="mt-4 mb-4">
                            {{ question.questionName }}
                        </app-text>

                        <!--Description-->
                        <app-text category="text-small" class="mt-4">
                            {{ question.questionDescription }}
                        </app-text>

                        <!--Buttons-->
                        <div class="d-flex">

                            <v-spacer/>

                            <v-btn-toggle
                                borderless
                                v-model="question.answer"
                                :color="question.answer ? 'error' : 'success'">

                                <!--No-->
                                <app-btn @click.native="saveSelectedAnswer(question, question.questionAnswers[1])"
                                         color="white"
                                         :label="question.questionAnswers[1]"
                                         :value="question.questionAnswers[1]"/>

                                <!--Yes-->
                                <app-btn @click.native="saveSelectedAnswer(question, question.questionAnswers[0])"
                                         class="ml-4"
                                         color="white"
                                         :label="question.questionAnswers[0]"
                                         :value="question.questionAnswers[0]"/>

                            </v-btn-toggle>

                        </div>

                        <v-divider class="mt-4"/>

                    </div>

                    <!--Submit Button-->
                    <div v-if="!isPageLoading" class="d-flex align-end pt-4">

                        <!--Submit button-->
                        <app-btn v-if="!isErrorMessageVisible" @click.native="submitForm"
                                 block
                                 color="success"
                                 icon="icons8-checkmark-yes"
                                 label="Submit"/>

                        <!--Error message-->
                        <app-text v-if="isErrorMessageVisible"
                                  class="error white--text pa-2 errorMessage justify-center">
                            Answer all questions
                        </app-text>

                    </div>

                </v-form>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import appBtn from "./appBtn";
import {mapGetters} from "vuex";

export default {

    name: "covidQuestions",

    components: {
        appBtn,
    },

    data: () => ({

        answer: [], // holds all the answers as we don't know how many questions there will be
        answeredQuestions: [], // hold all the questions and answers to save to Firebase
        answersCollectionData: [], // questions and answers data from DB
        hasBadAnswer: false,
        hasFormBeenFilledToday: false,
        isErrorMessageVisible: false,
        isFormVisible: false,
        isPageLoading: true,
        isSwappEnabled: false,
        questionsCollectionData: [],

    }),

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),

        /**
         * Computed Questions Collection Data
         *
         * Present the appropriate questions to the user based on the current userType.
         * Warriors and Warriors Women are two separate teams but both are categorised as Players.
         * When a Player registers, they select a team and have a warriorsTeam: Mens/Womens property added to their user
         * account.
         * As the Warriors Womens have a different set of questions to the men, they have been categorised as
         * 'Warriors' and 'Warriors Women' in the questionUserCategories, hence the separate filtering.
         *
         * @returns {Array} the processed questions to render
         */
        computedQuestionsCollectionData() {
            const t = this
            let questions = t.questionsCollectionData

            // Filter by Warriors Women
            if (t.GET_currentUser.userType === 'Player' && t.GET_currentUser.warriorsTeam === 'Womens') {
                questions = questions.filter(question => question.questionUserCategories.includes('Warriors Women'))
            }

            // Filter by Warriors Men
            if (t.GET_currentUser.userType === 'Player' && t.GET_currentUser.warriorsTeam === 'Mens') {
                questions = questions.filter(question => question.questionUserCategories.includes('Warriors'))
            }

            // Only ask the questions the user's userType is tagged in
            if (t.GET_currentUser.userType !== 'Player') {
                questions = questions.filter(question => question.questionUserCategories.includes(t.GET_currentUser.userType))
            }

            return questions

        }

    },

    methods: {

        /**
         * Alert Covid Manager
         *
         * Trigger FB function (sendCovidFailedEmail) to send email to Covid Manager on covid screening failure.
         */
        alertCovidManager() {

            // Only send the email when in production
            if (process.env.NODE_ENV === 'production') {
                const t = this
                const userInfo = t.GET_currentUser
                const covidEmail = t.$firebase.functions.httpsCallable('sendCovidFailedEmail')

                // Filter all the current user's answers
                const usersAnswers = t.answersCollectionData.filter(answer => answer.createdUserData.userId === t.GET_currentUser.id)
                const dateTimeThisMorning = t.$moment().startOf('day').add(3, 'hours').format('x')

                // Filter the current user's answers for today
                const todaysAnswers = usersAnswers.filter(answer =>
                    answer.createdDateTime > dateTimeThisMorning
                )

                // Format date to display in email. e.g. Monday, June 9 2014 9:32 PM
                const answersDateTime = t.$moment(todaysAnswers[0].createdDateTime, 'x').format('LLLL')

                let emailFields = {
                    userName: userInfo.userName,
                    userEmail: userInfo.userEmail,
                    userPosition: userInfo.userPosition,
                    userTelephone: userInfo.userTelephone,
                    questionsAndAnswers: todaysAnswers[0].questions,
                    temperature: todaysAnswers[0].temperature,
                    answersDateTime: answersDateTime
                }

                covidEmail(emailFields)
                    .then(result => {
                        console.log("FB function send covid email successful", result)
                    })
                    .catch((error) => {
                        console.log("FB function send covid email error", error)
                    });
            }

        },

        /**
         * Get Answers Collection
         *
         * Fetch all the data from the Answers collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getAnswersCollectionData() {
            const t = this

            await t.$firebase.db.collection('answers')
                .where('createdUserData.userId', '==', t.GET_currentUser.id)
                .onSnapshot(snapshot => {

                    t.answersCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            t.answersCollectionData.push(document)
                        }
                    })

                    t.checkIfFormHasBeenFilledInToday()
                })
        },

        /**
         * Check If Form Has Been Filled In Today
         *
         * The form only needs submitting once a day.
         * Check the answersData to see if the current user has completed the form today.
         * If they have, check to ensure the result was good or bad, to know whether to enable Swapp button.
         * If they have NOT, the form should be filled in.
         */
        checkIfFormHasBeenFilledInToday() {
            const t = this

            // Filter all the current user's answers
            const usersAnswers = t.answersCollectionData.filter(answer => answer.createdUserData.userId === t.GET_currentUser.id)
            const dateTimeThisMorning = t.$moment().startOf('day').add(3, 'hours').format('x')

            // Filter the current user's answers for today
            const todaysAnswers = usersAnswers.filter(answer =>
                answer.createdDateTime > dateTimeThisMorning
            )

            // If the questions have been answered today
            if (todaysAnswers.length) {

                // If user's temperature is above 37.8, send email notification to Covid Manager
                if (todaysAnswers[0].temperature && todaysAnswers[0].temperature > 37.8) {
                    t.alertCovidManager()
                }

                // Emit the data back to Home
                t.$emit('emitExistingCovidTemperature', todaysAnswers[0].temperature)

                // Filter any bad answers
                const badAnswers = todaysAnswers[0].questions.filter(
                    answer => !answer.question.questionCorrectAnswers.includes(answer.answer))

                // Handle good or bad answers
                if (badAnswers.length) {
                    t.handleFormAndButtonsVisibility('bad')
                    // Send email notifying Covid Manager of the question failure
                    t.alertCovidManager()
                } else {
                    t.handleFormAndButtonsVisibility('good')
                }
            }

            // If the questions have not been answered today, answer them
            else {
                t.hasFormBeenFilledToday = false
                t.shouldEnableSwappIn(false)
            }
        },

        /**
         * Handle Form and Buttons Visibility
         *
         * Based on the results from either 'checkIfFormHasBeenFilledInToday()', or 'checkForBadAnswers()'.
         * If any of the answers are not acceptable, disable the form and Swapp button.
         * If all the answers are acceptable, disable the form but enable the Swapp button.
         */
        handleFormAndButtonsVisibility(status) {
            const t = this

            if (status === 'bad') {
                t.isFormVisible = false
                t.hasBadAnswer = true
                t.hasFormBeenFilledToday = true
                t.shouldEnableSwappIn(false)
            } else if (status === 'good') {
                t.isFormVisible = false
                t.hasFormBeenFilledToday = true
                t.shouldEnableSwappIn(true)
            }
        },

        /**
         * Enable/Disable Swapp button based on the status
         *
         * @param status - the collective result of the answers.
         */
        shouldEnableSwappIn(status) {
            const t = this

            t.isSwappEnabled = status

            t.$emit('isSwappEnabled', status)

        },

        /**
         * Get Questions Collection
         *
         * Fetch all the data from the Questions collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getQuestionsCollectionData() {
            const t = this

            await t.$firebase.db.collection('questions')
                .onSnapshot(snapshot => {

                    t.questionsCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            t.questionsCollectionData.push(document)
                        }

                    })
                })
            // Cancel loading spinner
            t.isPageLoading = false
        },

        /**
         * Save Selected Answer
         *
         * Saves the current question and answer in the answeredQuestions array.
         * The question is saved as an object and the answer is saved as a string.
         *
         * @param currentQuestion - Object - the current question object
         * @param answer - String - the selected answer to the current question
         */
        saveSelectedAnswer(currentQuestion, answer) {
            const t = this

            // Create a question object with the current question (object) and answer (string)
            let questionAnswerObj = {
                question: currentQuestion,
                answer: answer
            }

            // Find the index of the current question
            const i = t.answeredQuestions.findIndex(question => question.question.id === currentQuestion.id)

            // If the index exists in the
            if (i === -1) {
                t.answeredQuestions.push(questionAnswerObj)
            } else {
                t.answeredQuestions[i] = questionAnswerObj
            }

        },

        /**
         * Submit Form
         *
         * Save the document with the collection name, question details and related answers.
         * If saving is successful SWAPPING IN will be enabled.
         *
         * @returns {Promise<void>}
         */
        async submitForm() {
            const t = this
            const numberOfQuestionsProvided = t.computedQuestionsCollectionData.length
            const numberOfQuestionsAnswered = t.answeredQuestions.length

            // To avoid having anonymous nested objects,
            // wrap the questions in an object which will hold the rest of the data
            let saveableObject = {
                questions: []
            }

            // If all the questions have been answered
            if (numberOfQuestionsAnswered === numberOfQuestionsProvided) {

                saveableObject.questions = t.answeredQuestions

                // Create a new document
                const createDocumentResult = await t.MIX_createDocument('answers', saveableObject)

                // Enable the Swapp button and close the form if the submission was successful
                if (createDocumentResult.code === 1) {

                    // Check to ensure all answers given are accepted
                    t.checkForBadAnswers()
                }

                // Call for the confirmation box
                t.renderConfirmationAlert(createDocumentResult)
            }

            // If all the questions have NOT been answered show an error message
            else {
                t.toggleErrorMessageVisibility()
            }
        },

        /**
         * Check For Bad Answers
         *
         * Check the given answers against the related question's correct answers to ensure they are acceptable.
         * If any of the answers are not acceptable, render a 'do not enter' message.
         */
        checkForBadAnswers() {
            const t = this
            const answerResults = [];

            // Iterate over the answered questions
            t.answeredQuestions.forEach(answeredQuestion => {

                // Iterate over the stock questions
                t.questionsCollectionData.forEach(question => {

                    // If it's the correct question
                    if (answeredQuestion.question.id === question.id) {

                        // Check if the answer is included in the correct answers
                        const isIncluded = question.questionCorrectAnswers.includes(answeredQuestion.answer)

                        // Good
                        if (isIncluded) {
                            answerResults.push('good')
                        }
                        // Bad
                        else {
                            answerResults.push('bad')
                        }
                    }
                })
            })

            // Handle good or bad results
            if (answerResults.includes('bad')) {
                t.handleFormAndButtonsVisibility('bad')
            } else {
                this.handleFormAndButtonsVisibility('good')
            }
        },

        /**
         * Toggle Error Message Visibility
         */
        toggleErrorMessageVisibility() {
            const t = this

            t.isErrorMessageVisible = true

            setTimeout(() => {
                t.isErrorMessageVisible = false
            }, 2000)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `Answer submitted`, null, null)
            } else {
                t.MIX_alert(-1, `Error submitting answer`, null, null)
            }
        },

    },

    async mounted() {
        const t = this

        // Start the loading spinner, clears when getQuestionsCollectionData() completes
        t.isPageLoading = true

        // Get collection data
        await t.getQuestionsCollectionData()
        await t.getAnswersCollectionData()

    },

}
</script>

<style>
.errorMessage {
    border-radius: 4px;
    height: 48px;
    width: 100%;

    animation: wobblee 2s;
}

/* Wobble the error message */
@keyframes wobblee {
    0% {
        transform: translateX(8px);
    }
    2% {
        transform: translateX(-8px);
    }
    4% {
        transform: translateX(4px);
    }
    6% {
        transform: translateX(-4px);
    }
    8% {
        transform: translateX(0);
    }
    90% {
        opacity: 1
    }
    100% {
        opacity: 0
    }
}

.home-form {
    animation: softDrop 1s;
}

/* Fade in the form */
@keyframes softDrop {
    0% {
        transform: translateY(32px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.flash {
    animation: flash 1s infinite;
}
</style>
