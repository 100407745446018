<!--
Sites Manager Selection Modal

Renders the modal for selecting a new site manager.
Upon clicking save, an event is emitted back to the parent sites.vue where it is processed and the site updated.
-->
<template>
	<v-row no-gutters class="pa-1">
		<v-dialog
			v-model="dialog"
			transition="scale-transition"
			max-width="400">

			<!--Activator-->
			<template v-slot:activator="{ on, attrs }">

				<!--Name card-->
				<v-card v-on="on" flat class="d-flex align-center">
					<app-text category="text-small">{{ site.siteContact.contactName }}</app-text>
					<v-icon class="icons8-expand-arrow ml-4" color="lightgrey" size="16"/>
				</v-card>

			</template>

			<!--Modal Content-->
			<v-card>

				<!--Toolbar-->
				<v-toolbar color="primary">

					<app-text category="text-medium" class="white--text ml-2">New Site Manager</app-text>

					<v-spacer/>

					<v-btn @click="dialog = false" icon>
						<v-icon class="icons8-multiply" color="white"/>
					</v-btn>

				</v-toolbar>

				<!--Content-->
				<div class="pa-4">

					<!--Info-->
					<app-text>
						You are about to replace
						<span class="font-weight-bold">{{ site.siteContact.contactName }}</span>
						as Site Manager for the following site.
					</app-text>

					<v-divider class="my-4"/>

					<!--Icon | Site details-->
					<div class="d-flex align-start">

						<v-icon class="icons8-home mr-4" color="accent" size="24"/>

						<div>
							<app-text category="text-default-bold">{{ site.siteName }}</app-text>
							<app-text class="mt-2">{{ site.siteAddress.addressLine1 }}
							</app-text>
							<app-text class="mt-2">{{ site.siteAddress.town }}</app-text>
						</div>
					</div>

					<v-divider class="my-4"/>

					<!--Info-->
					<app-text class="mt-4">
						Select a replacement from the list below
					</app-text>

					<!--Manager selection-->
					<v-select class="mt-4"
							  background-color="white"
							  hide-details
							  :items="users"
							  :item-text="renderText"
							  label="Users"
							  outlined
							  return-object
							  v-model="selectedSiteManager"/>

					<!--Buttons-->
					<div class="d-flex mt-4">

						<!--Cancel button-->
						<v-btn @click="dialog = false" depressed right>
							<v-icon class="icons8-cancel mr-2" color="gey"/>
							Cancel
						</v-btn>

						<v-spacer/>

						<!--Save button-->
						<v-btn @click="updateManager" color="success" right>
							<v-icon class="icons8-save mr-2" color="white"/>
							Save
						</v-btn>

					</div>

				</div>

			</v-card>

		</v-dialog>
	</v-row>
</template>

<script>
export default {
	props: ['site', 'users'],

	data() {
		return {
			dialog: false,
			selectedSiteManager: {},
			userData: this.$props.users,
		}
	},

	methods: {

		/**
		 * Update Manager
		 *
		 * Take the newly selected manager and return it back to the parent component (Sites.vue).
		 */
		updateManager() {
			const t = this

			// Emit the data back to Sites
			t.$emit('updateManager', {manager: t.selectedSiteManager, site: t.$props.site})

			// Close the dialog box
			t.dialog = false
		},

		renderText(item) {
			return `${item.userName} (${item.userPosition})`
		}

	},

}
</script>
