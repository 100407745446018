<!--
Covid Answers Dialog

Renders the dialog pop up with answers to the Covid screening questions (including the user's temperature).
-->
<template>
    <div class="text-center">
        <v-dialog max-width="512"
                  ref="dialog"
                  transition="dialog-bottom-transition"
                  v-model="dialog">

            <!--Activator-->
            <template v-slot:activator="{ on, attrs }">
                <v-btn @click="copyQuestionsProp()"
                       :color="computedCovidButtonColor"
                       class="d-flex justify-space-between"
                       depressed
                       height="32"
                       width="100%"
                       v-on="on">

                    <v-icon class="icons8-expand-arrow" color="white" size="8"/>
                    <app-text class="white--text">Covid</app-text>
                    <v-icon class="icons8-expand-arrow" color="white" size="8"/>

                </v-btn>
            </template>

            <!--Dialog content-->
            <v-card v-click-outside="onClickOutside">

                <!--Toolbar-->
                <v-toolbar color="primary">

                    <app-text category="text-medium" class="white--text ml-2">Covid Answers</app-text>

                    <v-spacer/>

                    <v-btn @click="dialog = false" icon dark>
                        <v-icon class="icons8-multiply" color="white"/>
                    </v-btn>

                </v-toolbar>

                <!--Body-->
                <div class="white pa-4">

                    <!--Image | Name | Position-->
                    <div class="d-flex">

                        <!--Image-->
                        <div>
                            <v-img v-if="user.profilePicFileURL"
                                   :src="user.profilePicFileURL"
                                   alt="player"
                                   class="cpc-image"
                                   cover
                                   height="128"
                                   width="128"/>

                            <v-img v-else
                                   :src="require('@/assets/user.png')"
                                   alt="player"
                                   class="cpc-image"
                                   cover
                                   height="128"
                                   width="128"/>
                        </div>

                        <!--Name | Position-->
                        <div class="cad-userNamePosition-container d-flex">
                            <app-text category="text-large-bold" class="primary--text">{{ user.userName }}</app-text>
                            <app-text category="text-default-bold" class="accent--text">{{ user.playerPosition }}
                            </app-text>
                        </div>

                        <v-spacer/>

                        <!--Show Edit button for users with 'Edit Covid Answers (Requires Covid)'access. Only allow edits to users that have answered covid questions-->
                        <div v-if="GET_currentUser.userAccessAndAbilities && GET_currentUser.userAccessAndAbilities.includes('Edit Covid Answers (Requires Covid)')
                        && !isErrorMessageVisible && user.answers.length > 0"
                             class="cad-userNamePosition-container d-flex">
                            <v-icon @click.native="editItem"
                                    class="icons8-edit"
                                    :class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
                                    :color="formReadOnly ? 'warning' : 'white'"
                                    size="32"/>
                        </div>
                    </div>

                    <v-divider/>

                    <!--Temperature-->
                    <div v-if="user.userType.toUpperCase() === 'PLAYER'">
                        <app-text category="text-default-bold" class="mt-4">Temperature Today</app-text>
                        <div class="d-flex align-center">
                            <v-icon v-if="formReadOnly && computedTemperature.icon"
                                    class="mr-2"
                                    :class="computedTemperature.icon"
                                    :color="computedTemperature.iconColor"/>
                            <app-text v-if="formReadOnly" category="text-default">{{ computedTemperature.temperature }}
                            </app-text>

                            <!--Edit Temperature-->
                            <app-input v-else
                                       input-type="textInput"
                                       v-model="updatedTemperature"
                                       style="width: 80px"/>

                        </div>
                    </div>

                    <!--Questions-->
                    <div v-if="typeof computedCovidAnswers !== 'string'">
                        <div v-for="question in answeredQuestions" class="mt-4">

                            <!--Title-->
                            <app-text category="text-default-bold">{{ question.question.questionTitle }}</app-text>

                            <!--Question-->
                            <app-text>{{ question.question.questionName }}</app-text>

                            <!--Answer-->
                            <app-text v-if="formMode !== 'Edit'" category="text-default-bold"
                                      :class="renderQuestionAnswer(question.answer).textColor">
                                {{ renderQuestionAnswer(question.answer).answer }}
                            </app-text>

                            <!--Edit Covid Answer-->
                            <div v-else>

                                <v-btn-toggle>

                                    <!--No -->
                                    <app-btn
                                        @click.native="saveSelectedAnswer(question, question.question.questionAnswers[1])"
                                        class="mt-2"
                                        color="white"
                                        :class="question.answer === 'No' ? 'v-item--active v-btn--active' : ''"
                                        :label="question.question.questionAnswers[1]"
                                        style="color:green"/>

                                    <!-- Yes -->
                                    <app-btn
                                        @click.native="saveSelectedAnswer(question, question.question.questionAnswers[0])"
                                        class="ml-4 mt-2"
                                        color="white"
                                        :class="question.answer === 'Yes' ? 'v-item--active v-btn--active' : ''"
                                        :label="question.question.questionAnswers[0]"
                                        style="color:red"/>

                                </v-btn-toggle>

                            </div>

                        </div>
                    </div>
                    <div v-else class="mt-4">
                        <app-text category="text-default-bold">Questions</app-text>
                        <app-text category="text-default">{{ computedCovidAnswers }}</app-text>
                    </div>

                    <!--Show Save button for 'Edit Covid Answers'-->
                    <div v-if="formMode === 'Edit' && !isErrorMessageVisible" class="d-flex mt-4">

                        <v-spacer/>

                        <!--Save Edited Covid answers-->
                        <app-btn v-if="formMode === 'Edit' && !isErrorMessageVisible"
                                 @click.native="saveItem"
                                 color="success"
                                 icon="icons8-save"
                                 label="Save"/>

                    </div>

                    <!--Error message-->
                    <app-text v-if="isErrorMessageVisible" category="text-default"
                              class="error white--text pa-2 errorMessage justify-center mt-4">
                        Answer all questions
                    </app-text>

                </div>

            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "covidAnswersDialog",

    props: ['user', 'questions', 'temperature'],

    data: () => ({
        answeredQuestions: [], // Hold all the updated answers to save to Firebase
        dialog: false,
        formMode: 'View', // Show Form in View Mode
        formReadOnly: true, // Make Form Editable
        isCovidPassed: false,
        isErrorMessageVisible: false,
        updatedTemperature: '', // Hold updated temperature
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),

        /**
         * Computed Temperature
         *
         * Process the User's Covid Temperature reading and return and icon, icon color and temperature to render.
         *
         * @returns {{temperature: any, icon: string, iconColor: string}}
         */
        computedTemperature() {
            const t = this
            let icon;
            let iconColor;
            let temperature = t.$props.temperature
            t.updatedTemperature = temperature

            if (temperature === '-') {
                icon = ''
                iconColor = ''
                temperature = 'Not taken today'
            } else if (temperature <= 37.8) {
                icon = 'icons8-checkmark-yes'
                iconColor = 'success'
                temperature = temperature + '°C'
            } else if (temperature > 37.8) {
                icon = 'icons8-multiply'
                iconColor = 'error'
                temperature = temperature + '°C'
            }

            return {temperature: temperature, icon: icon, iconColor: iconColor}
        },

        /**
         * Computed Covid Answers
         *
         * If the current User has not answered the Covid questions, render a single message instead of one for each
         * missing question.
         *
         * @returns {string} the message to render
         */
        computedCovidAnswers() {
            const t = this
            const questions = t.$props.questions

            if (questions.some(question => question.answer === '-')) {
                return 'Covid questions not answered'
            }
        },

        /**
         * Computed Covid Button Color
         *
         * Render the dialog activator button ('Covid') color based on the Covid answers and Covid temperature.
         *
         * @returns {string} the color of the Covid button
         */
        computedCovidButtonColor() {
            const t = this
            const questions = t.$props.questions
            const temperature = t.$props.temperature
            let covidColor;

            if (questions.some(question => question.answer === 'Yes') || temperature > 37.8) {
                covidColor = 'error'
            } else if (questions.some(question => question.answer === '-')) {
                covidColor = 'grey'
            } else {
                covidColor = 'success'
            }

            return covidColor
        }

    },

    methods: {

        copyQuestionsProp() {
            const t = this
            // Make a non reactive copy of questions prop to be used to update answers
            t.answeredQuestions = JSON.parse(JSON.stringify(t.questions));
        },

        editItem() {
            const t = this

            if (t.formReadOnly) {
                t.formMode = 'Edit'
                t.formReadOnly = false
            } else {
                t.formMode = 'View'
                t.formReadOnly = true
            }
        },

        // Set form to view mode on click outside of dialog
        onClickOutside() {
            const t = this

            t.formMode = 'View'
            t.formReadOnly = true
        },

        /**
         * Render Question Answer
         *
         * Return the answer and associated text color of the given Covid Answer.
         *
         * @param covidAnswer - the current answer to process
         * @returns {{answer, textColor: string}} - an object containing the answer and text color
         */
        renderQuestionAnswer(covidAnswer) {
            let answer;
            let color;

            if (covidAnswer === 'Yes') {
                answer = covidAnswer
                color = 'error'
            } else {
                answer = covidAnswer
                color = 'success'
            }

            return {answer: answer, textColor: color + '--text'}
        },

        /**
         * Save Selected Answer
         *
         * Saves the current question and answer in the answeredQuestions array.
         * The question is saved as an object and the answer is saved as a string.
         *
         * @param currentQuestion - Object - the current question object
         * @param answer - String - the selected answer to the current question
         */
        saveSelectedAnswer(currentQuestion, answer) {
            const t = this

            // Get question IDs
            const questionIDs = t.answeredQuestions.map(a => a.question.id);

            // If the current question answer is changed, update the array
            if (questionIDs.includes(currentQuestion.question.id)) {
                for (let i = 0; i < t.answeredQuestions.length; i++) {
                    if (t.answeredQuestions[i].question.id === currentQuestion.question.id) {
                        t.answeredQuestions[i].question = currentQuestion.question
                        t.answeredQuestions[i].answer = answer
                    }
                }
            }

        },

        async saveItem() {
            const t = this
            const fields = {}
            // Get the user's answer ID
            const answerID = t.$props.user.answers[0].id

            // Only Players need to record their temperature
            if (t.$props.user.userType.toUpperCase() !== 'PLAYER') t.updatedTemperature = 'N/A'
            fields.temperature = t.updatedTemperature
            fields.questions = t.answeredQuestions

            // Save answers If temperature is present
            if (t.updatedTemperature) {
                const updateDocumentResult = await this.MIX_updateDocumentFieldsById('answers', answerID, fields)

                if (updateDocumentResult.code === 1) {

                    // Reset the form and close the dialog
                    t.formMode = 'View'
                    t.formReadOnly = true
                    t.dialog = false
                    // refresh the covid user card data
                    t.$emit('updateCovidAnswers')

                }
            } else {
                // If all the questions have NOT been answered show an error message
                t.toggleErrorMessageVisibility()
            }

        },

        /**
         * Toggle Error Message Visibility
         */
        toggleErrorMessageVisibility() {
            const t = this

            t.isErrorMessageVisible = true

            setTimeout(() => {
                t.isErrorMessageVisible = false
            }, 2000)
        }

    }

}
</script>

<style>
.cad-userNamePosition-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 16px;
}

</style>
