<!--
Covid Temperature

Renders a component to take the user's temperature as part of the Covid-19 screening process.
The temperature is stored against today's Covid-19 answers.
-->
<template>
    <v-expansion-panels flat>
        <v-expansion-panel class="rounded-lg">

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex align-center">

                    <!--Icon-->
                    <v-icon class="icons8-fever ma-n1" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">Temperature</app-text>

                </div>

                <!--Text-->
                <app-text v-if="!todaysCovidTemperature" class="mt-4">
                    Please record your temperature before SWAPPing in
                    <br>
                    <br>
                    <span class="grey--text">You must answer the Covid-19 Screening Questions first</span>
                </app-text>

                <!--Responses-->
                <div>

                    <!--Bad result-->
                    <app-text v-if="todaysCovidTemperature > 37.8"
                              class="mt-4"
                              style="line-height: 24px">
                        <v-icon class="icons8-no-entry flash pb-1 mr-4" color="error"/>
                        <span class="text-default-bold">Do not enter this site</span>

                        <br><br>

                        Your Covid Screening Temperature for today was
                        <span class="font-weight-bold">{{ todaysCovidTemperature }}</span>°C

                        <br>
                        <br>

                        Due to your temperature, you must contact a member of management for further instructions
                    </app-text>

                    <!--Good result-->
                    <app-text v-if="todaysCovidTemperature && todaysCovidTemperature <= 37.8"
                              class="mt-4" style="line-height: 24px">
                        <v-icon class="icons8-checkmark-yes pb-1" color="success"/>
                        Your Covid Screening Temperature for today was
                        <span class="font-weight-bold">{{ todaysCovidTemperature }}</span>°C
                    </app-text>

                </div>

            </v-expansion-panel-header>

            <!--Temperature input | Submit button-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Temperature field-->
                <v-text-field class="rounded-lg"
                              :disabled="!isSwappEnabled || !!todaysCovidTemperature"
                              :error="errors.temperature"
                              filled
                              flat
                              hide-details
                              label="Your Temperature"
                              outlined
                              solo
                              v-model="form.temperature"/>

                <!--Submit button-->
                <app-btn @click.native="validateForm"
                         block
                         class="mt-4"
                         :disabled="!isSwappEnabled || !!todaysCovidTemperature"
                         label="Submit"/>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: "covidTemperature",

    props: ['isSwappEnabled', 'todaysCovidTemperature'],

    data: () => ({

        answersCollectionData: [],
        errors: {
            temperature: false,
        },
        form: {
            temperature: '',
        },

    }),

    methods: {

        /**
         * Get Answers Collection Data
         *
         * Fetch the collection data from the database and assign to answersCollectionData
         *
         * @returns {Promise<void>}
         */
        async getAnswersCollectionData() {
            const t = this

            await t.$firebase.db.collection('answers')
                .where('createdUserData.userId', '==', t.GET_currentUser.id)
                .onSnapshot(snapshot => {

                    // Clear the array to avoid duplications
                    t.answersCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        t.answersCollectionData.push(document)

                    })
                })
        },

        /**
         * Validate Form
         *
         * Validate for the presence of the temperature.
         * If the temperature is present, update the document
         * If the temperature is NOT present, throw an error
         */
        validateForm() {
            const t = this

            !t.form.temperature.trim() ? t.errors.temperature = true : t.errors.temperature = false

            if (!Object.values(t.errors).includes(true)) {
                t.addToTodaysAnswers()
            }
        },

        /**
         * Add To Today's Answers
         *
         * Process the Answers collection and add the temperature to today's answers array.
         */
        addToTodaysAnswers() {
            const t = this

            const dateTimeThisMorning = t.$moment().startOf('day').add(3, 'hours').format('x')

            t.answersCollectionData = t.answersCollectionData.filter(answer => answer.createdUserData.userId === t.GET_currentUser.id)

            // If the user has ever answered questions
            if (t.answersCollectionData.length) {

                t.answersCollectionData.forEach(answer => {

                    // If the user has answered the questions today
                    if (answer.createdDateTime > dateTimeThisMorning) {

                        answer.temperature = t.form.temperature

                        t.saveDocument(answer)
                    }
                })
            }
        },

        /**
         * Save Document
         *
         * Update today's answers with the new temperature reading.
         *
         * @param answer - today's answer updated with temperature
         * @returns {Promise<void>}
         */
        async saveDocument(answer) {
            const t = this

            const savedDocument = await t.MIX_updateDocument('answers', answer)

            if (savedDocument.code === 1) {

                t.$emit('emitNewCovidTemperature', t.form.temperature)
            }
        },

    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getAnswersCollectionData()
    }
}
</script>
