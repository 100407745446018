<template>
    <v-row class="fill-height" no-gutters>

        <!--Show loader whilst loading data-->
        <v-row v-if="isPageLoading" class="justify-center align-center fill-height" no-gutters>
            <v-progress-circular
                color="primary"
                indeterminate
                size="96"/>
        </v-row>

        <v-row v-if="!isPageLoading" class="fill-height" no-gutters>

            <!--Left panel-->
            <v-col class="pa-4">

                <!--Page title-->
                <page-title icon="icons8-fires" page-title="Fire Roll Call"/>

                <!--Action buttons-->
                <v-row class="mt-4" no-gutters>
                    <v-col class="d-flex">

                        <v-spacer v-if="$vuetify.breakpoint.width >= 600"/>

                        <!--Start a Roll Call-->
                        <fire-roll-call-start-dialog
                            v-if="!isFireRegisterVisible"
                            v-on:emitHandleStartRegister="isFireRegisterVisible = !isFireRegisterVisible"/>

                        <!--Cancel the Roll Call-->
                        <fire-roll-call-cancel-dialog
                            v-if="isFireRegisterVisible"
                            v-on:emitHandleCancelRegister="isFireRegisterVisible = !isFireRegisterVisible"/>

                    </v-col>
                </v-row>

                <!--Table-->
                <v-row v-if="$vuetify.breakpoint.width >= 600 && !isFireRegisterVisible"
                       class="mt-4" no-gutters>
                    <v-col>

                        <v-data-table class="rounded-lg"
                                      :headers="tableHeaders"
                                      :items="fireRollCallCollectionData"
                                      sort-by="createdDateTime"
                                      sort-desc>

                            <!--Title-->
                            <template v-slot:item.title="{ item }">
                                <app-text category="text-small">{{ item.title }}</app-text>
                            </template>

                            <!--Type-->
                            <template v-slot:item.type="{ item }">
                                <app-text category="text-small">{{ item.type }}</app-text>
                            </template>

                            <!--Date-->
                            <template v-slot:item.date="{ item }">
                                <app-text category="text-small">
                                    {{ $moment(Number(item.createdDateTime)).format('dddd Do MMMM YYYY @ HH:mm') }}
                                </app-text>
                            </template>

                            <!--Open-->
                            <template v-slot:item.open="{ item }">
                                <v-btn @click="openDocument(item.id, 'View', false)"
                                       depressed class="primary--text white pa-4">
                                    Open
                                    <v-icon class="icons8-forward" color="primary"/>
                                </v-btn>
                            </template>

                        </v-data-table>

                    </v-col>
                </v-row>

                <!--Mobile Cards-->
                <div v-if="$vuetify.breakpoint.width < 600 && !isFireRegisterVisible">

                    <app-text v-if="!fireRollCallCollectionData.length"
                              class="justify-center mt-4">You have no Fire Roll Calls
                    </app-text>

                    <div v-for="rollCall in fireRollCallCollectionData">

                        <v-card @click="openDocument(rollCall.id, 'View', false)"
                                class="rounded-lg mt-4 pa-4" flat>

                            <!--Title-->
                            <app-text category="text-default-bold">{{ rollCall.title }}</app-text>

                            <!--Type | Date-->
                            <div class="d-flex">

                                <!--Type-->
                                <app-text>
                                    {{ rollCall.type }}
                                </app-text>

                                <v-spacer/>

                                <!--Date-->
                                <app-text>
                                    {{ $moment(Number(rollCall.createdDateTime)).format('DD MMMM YYYY @ HH:mm') }}
                                </app-text>

                            </div>

                        </v-card>

                    </div>

                </div>

                <!--Register-->
                <fire-roll-call-register v-if="isFireRegisterVisible" :users="usersCollectionData"
                                         v-on:emitCloseRegister="isFireRegisterVisible = false"/>

            </v-col>

            <!--Right panel-->
            <transition name="custom-classes-transition"
                        enter-active-class="animate__animated animate__slideIn animated__faster"
                        leave-active-class="animate__animated animate__fadeOut animated__faster"
                        mode="out-in">
                <rightpanel></rightpanel>
                <!--<component :is="dynamicComponent" :key="dynamicComponentKey"></component>-->
            </transition>

        </v-row>
    </v-row>
</template>

<script>
import FireRollCallRegister from "./fireRollCallRegister/fireRollCallRegister";
import FireRollCallCancelDialog from "./fireRollCallCancelDialog/fireRollCallCancelDialog";
import FireRollCallStartDialog from "./fireRollCallStartDialog/fireRollCallStartDialog";
import {mapActions, mapGetters} from "vuex";

export default {

    name: "fireRollCall",

    components: {
        FireRollCallStartDialog,
        FireRollCallCancelDialog,
        FireRollCallRegister
    },

    data: () => ({
        fireRollCallCollectionData: [],
        isFireRegisterVisible: false,
        isPageLoading: false,
        swappLogsCollectionData: [],
        tableHeaders: [
            {text: 'Title', value: 'title', align: 'left', hidden: false},
            {text: 'Type', value: 'type', align: 'left', hidden: false},
            {text: 'Date', value: 'date', align: 'left', hidden: false, sortable: false},
            {text: '', value: 'open', align: 'right', hidden: false, sortable: false, width: '136px'},
        ],
        usersCollectionData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: "GET_panelLayout",
        }),
    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openFireRollCall",
            ACT_openFireRollCall: "ACT_openFireRollCall",
        }),

        /**
         * Get Fire Roll Call Collection Data
         *
         * Fetch the collection data and add the records to the data array.
         *
         * @returns {Promise<void>}
         */
        async getFireRollCallCollectionData() {
            const t = this

            await t.$firebase.db.collection('fireRollCall')
                .onSnapshot(snapshot => {

                    // Clear the array to avoid data duplication
                    t.fireRollCallCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        t.fireRollCallCollectionData.push(document)

                    })
                })
        },

        /**
         * Get Users Collection Data
         *
         * Fetch the collection data and add those records which are not marked as 'deleted'.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            await t.$firebase.db.collection('users')
                .onSnapshot(snapshot => {

                    // Clear the array to avoid data duplication
                    t.usersCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only push documents not marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            t.usersCollectionData.push(document)
                        }

                    })
                })
        },

        /**
         * Get SWAPP Logs Collection Data
         *
         * Fetch the collection data and add the records to the data array.
         * Call to matchUsersAndSwappLogs().
         *
         * @returns {Promise<void>}
         */
        async getSwappLogsCollectionData() {
            const t = this

            // Get the dateTime in milliseconds for 3am today.
            // This time is an hour after everyone is swapped out with a Firebase Function so it ensures only activity from today
            const dateTimeThisMorning = t.$moment().startOf('day').add(3, 'hours').format('x')

            await t.$firebase.db.collection('swappLogs')
                .where('createdDateTime', '>', dateTimeThisMorning)
                .onSnapshot(snapshot => {

                    // Clear the array to avoid data duplication
                    t.swappLogsCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        t.swappLogsCollectionData.push(document)
                    })

                    t.usersCollectionData = t.matchUsersAndSwappLogs()
                    t.isPageLoading = false
                })
        },

        /**
         * Match Users And Swapp Logs
         *
         * Returns an array of Users that are:
         *  - Currently swapped in
         *  - Have swapped out in the last two hours
         *
         * @returns {Array} list of Users
         */
        matchUsersAndSwappLogs() {
            const t = this
            let users = []
            let twoHoursAgo = t.$moment().subtract(2, 'hours').format('x')

            // Iterate over the users
            t.usersCollectionData.forEach(user => {

                // Filter all the logs for the current user
                let userSwappLog = t.swappLogsCollectionData.filter(log => log.swappedUser === user.id)

                // Sort the logs by modification data (soonest first)
                userSwappLog = userSwappLog.sort((a, b) => {
                    return a.modifiedDateTime < b.modifiedDateTime ? 1 : -1
                })

                // Using the last log from today (if present), add the user to the array if:
                //  - They are currently swapped in
                //  - They have swapped out in the last two hours
                if (userSwappLog.length && userSwappLog[0].swappStatus === 1
                    || (userSwappLog.length && userSwappLog[0].swappStatus === 0 && userSwappLog[0].createdDateTime > twoHoursAgo)) {
                    user.lastSwappDateTime = userSwappLog[0].modifiedDateTime
                    users.push(user)
                }

            })

            t.usersCollectionData = users

            return t.usersCollectionData
        },

        /**
         * Open Document
         *
         * When a table row is opened, populate and show the right panel with the row details.
         *
         * @param id
         * @param process
         * @param popup
         * @returns {Promise<void>}
         */
        async openDocument(id, process, popup) {
            const t = this

            const item = {}
            item.process = process
            item.popup = popup

            const itemResult = await t.MIX_readDocumentById('fireRollCall', id)
            item.data = itemResult.data

            t.ACT_openItem(item)

            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, 'Fire Roll Call', 'fireRollCall')
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, 'Fire Roll Call', 'fireRollCall')
            }
        },

    },

    async mounted() {
        const t = this

        // Start spinner, clears when getSwappLogsCollectionData() has complete
        t.isPageLoading = true

        // Fetch collections data - in order
        await t.getFireRollCallCollectionData()
        await t.getUsersCollectionData()
        await t.getSwappLogsCollectionData()

    },

}
</script>

<style>

</style>
