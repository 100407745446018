<!--
Dashboard - Office Staff

Renders the dashboard displaying office staff who are IN and DUE in.
There are three schedule types to group users by:
 - Head Office
 - Working from Home
 - Holiday
-->
<template>
    <v-row class="fill-height pa-0 ma-0" style="min-width: 100%" no-gutters>
        <v-col>

            <!--
            Header
             - Left side - Site details
             - Right side - DateTime | Weather forecast
            -->
            <v-row class="pa-4" no-gutters>

                <!--Left side-->
                <div class="d-flex align-center">

                    <!--Logo-->
                    <v-img v-if="$vuetify.breakpoint.width >= 600" :src="require('@/assets/company_logo.svg')"
                           class="ml-n4" contain min-height="48" max-height="112"/>

                    <!--Site details-->
                    <div class="d-flex flex-column justify-space-around">

                        <!--Site name-->
                        <app-text category="text-xxlarge-bold" class="primary--text">
                            Office Staff
                        </app-text>

                        <!--Site address-->
                        <div class="d-flex">

                            <!--Address line 1-->
                            <app-text category="text-medium" class="darkgrey--text mr-2">
                                The Grange, Margam, SA13 2SP
                            </app-text>

                        </div>

                    </div>

                </div>

                <v-spacer/>

                <!--Right side (DateTime | Weather forecast)-->
                <div :class="$vuetify.breakpoint.width >= 1366
                                ? 'd-flex justify-end align-center'
                                : 'd-flex justify-center mt-4'"
                     :style="$vuetify.breakpoint.width < 1366 && 'width: 100%'">

                    <!--
                    Current date/time
                     - Render the current date and time as a card
                    -->
                    <div v-if="$vuetify.breakpoint.width > 832">
                        <v-card flat width="174" height="112" class="pa-2 d-flex flex-column align-center">

                            <!--Time-->
                            <app-text category="text-xxlarge" class="primary--text pb-3">
                                {{ timeNow }}
                            </app-text>

                            <!--Date-->
                            <app-text class="grey--text pb-0">
                                {{ $moment().format("ddd D-MMM-YYYY") }}
                            </app-text>

                        </v-card>
                    </div>

                    <!--
                    Weather cards
                     - Render a weather tile for the next 5 day forecast
                    -->
                    <div v-if="$vuetify.breakpoint.width > 632" v-for="forecast in fiveDayForecast">
                        <dashboard-weather-card :forecast="forecast"/>
                    </div>

                </div>

            </v-row>

            <!--Page break-->
            <v-row class="primary ma-0" style="height: 8px"/>

            <!--
            Main body
            -->

            <!--
            Personnel in/due - Full size (> 600 width)
            -->
            <div v-if="$vuetify.breakpoint.width > 600">

                <!--Head Office-->
                <div v-if="scheduleTypeOffice.length" class="dshbrd-cardGrid">
                    <div v-for="user in scheduleTypeOffice" style="width: 100%">
                        <dashboard-user-modal :user="user"/>
                    </div>
                </div>

				<!--Out on Site-->
				<div v-if="scheduleTypeSite.length" class="dshbrd-cardGrid">
					<div v-for="user in scheduleTypeSite" style="width: 100%">
						<dashboard-user-modal :user="user"/>
					</div>
				</div>

                <!--Working from Home-->
                <div v-if="scheduleTypeWorkingFromHome.length" class="dshbrd-cardGrid">
                    <div v-for="user in scheduleTypeWorkingFromHome" style="width: 100%">
                        <dashboard-user-modal :user="user"/>
                    </div>
                </div>

                <!--Holiday-->
                <div v-if="scheduleTypeHoliday.length" class="dshbrd-cardGrid">
                    <div v-for="user in scheduleTypeHoliday" style="width: 100%">
                        <dashboard-user-modal :user="user"/>
                    </div>
                </div>

            </div>

            <!--
            Personnel in/due - Tabbed layout (<= 600 width) ---- MOBILE
            -->
            <div v-if="$vuetify.breakpoint.width < 601">

                <!--Tabs-->
                <v-tabs fixed-tabs v-model="tab">
                    <v-tab>Today</v-tab>
                </v-tabs>

                <!--Tab Content-->
                <v-tabs-items v-model="tab" class="lightgrey">

                    <!--Users in today-->
                    <v-tab-item>
                        <div class="flex-column">
                            <div class="ma-4">

                                <!--Head Office-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeOffice" style="width: 100%">
                                        <dashboard-user-modal :user="user"/>
                                    </div>
                                </div>

                                <!--Working from Home-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeWorkingFromHome" style="width: 100%">
                                        <dashboard-user-modal :user="user"/>
                                    </div>
                                </div>

                                <!--Holiday-->
                                <div class="dshbrd-cardGrid">
                                    <div v-for="user in scheduleTypeHoliday" style="width: 100%">
                                        <dashboard-user-modal :user="user"/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </v-tab-item>

                </v-tabs-items>

            </div>

        </v-col>
    </v-row>
</template>

<script>
import dashboardWeatherCard from "./dashboardWeatherCard/dashboardWeatherCard";
import dashboardUserModal from "./dashboardUserModal/dashboardUserModal";
import {mapGetters} from "vuex";

export default {
    name: "DashboardNew",

    data: () => ({
        title: "DashboardNew",
        fiveDayForecast: [],
        scheduleData: [],
        sitesData: [],
        tab: null,
        timeNow: '',
        userData: [],
        userScheduleData: [],
        weatherData: {},

        scheduleTypeOffice: [],
		scheduleTypeSite: [],
		scheduleTypeHoliday: [],
        scheduleTypeWorkingFromHome: [],
    }),

    components: {
        dashboardUserModal,
        dashboardWeatherCard,
    },

    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
        }),

        /**
         * Get SWAPPed In Users
         *
         * @return swappedInUsers - an array containing the currently swapped in users
         */
        getSwappedInUsers() {
            const t = this

            const swappedInUsers = t.userData.filter(user => user.swappStatus)
            t.userData = swappedInUsers

            return swappedInUsers
        }
    },

    methods: {

        /**
         * Get Time
         *
         * Get and set the current time for the clock.
         * This is started in mount() and stopped in beforeDestroy().
         */
        getTime() {
            const t = this

            t.timeNow = t.$moment().format("HH:mm")
        },

        /**
         * Get Users Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        getUsersCollectionData: async function () {
            const t = this

            const collection = t.$firebase.db.collection('users')
            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.userData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.userData.push(document)
                    }
                })

                // Filter out visitors
                // t.userData = t.userData.filter(user => user.userLevel !== 'VU')
                t.filterOfficeStaff(t.userData)
            })
        },

        /**
         * Filter Office Staff
         *
         * Filter all users by their user category to only get those based at Head Office.
         */
        filterOfficeStaff() {
            const t = this

            t.userData = t.userData.filter(user => user.userCategory === 'Office Staff')
        },

        /**
         * Get Schedule Collection
         *
         * Clear the data to avoid duplications.
         * Fetch all the data from the Schedule collection.
         * Iterate over the collection and push documents to the scheduleData array.
         *
         * @returns {Promise<void>}
         */
        getUserScheduleCollectionData: async function () {
            const t = this

            const collection = t.$firebase.db.collection('schedule')
            collection.onSnapshot(snapshot => {

                // Clear the data to avoid duplications
                t.scheduleData = []

                // Iterate over the collection and add the documents to the data array
                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    t.scheduleData.push(document)
                })

                // Call to link user and schedule data
                t.linkUserAndScheduleData()
            })
        },

        /**
         * Link User and Schedule Data
         *
         * Iterate over the User and Schedule data and match them by ID.
         * If they match, merge them into a new temporary object and push it to the userScheduleData array for rendering.
         */
        linkUserAndScheduleData() {
            const t = this
            let tempObj = {}

            // Rest the data to avoid duplications
            t.userScheduleData = []

            t.userData.forEach(user => {
                t.scheduleData.forEach(schedule => {

                    // Match the user to their schedule
                    if (user.id === schedule.id) {

                        // Join the user and schedule objects to make a new tempObj
                        tempObj = {...user, ...schedule}

                        // Push to the array used for rendering
                        t.userScheduleData.push(tempObj)

                    }
                })
            })

            t.filterScheduleTypes(t.userScheduleData)
        },

        /**
         * Filter Schedule Types
         *
         * Take the merged user/schedule data and filter it into the following arrays for rendering:
         *  - Head Office
         *  - Working from Home
         *  - Holiday
         *
         * @param userData - user and schedule data (merged) to filter
         */
        filterScheduleTypes(userData) {
            const t = this

            t.scheduleTypeOffice = []
            t.scheduleTypeSite = []
            t.scheduleTypeWorkingFromHome = []
            t.scheduleTypeHoliday = []

            const dayToday = t.$moment().format('dddd')

            // Iterate over the users
            userData.forEach(user => {

                // Iterate over the user object to get the schedule keys and values
                for (const [key, value] of Object.entries(user)) {

                    // If the user object day matches the current day (is today)
                    // Push the user's to their respective schedule arrays for rendering.
                    // Add the todaysSchedule field for convenience.
                    if (key.toUpperCase() === dayToday.toUpperCase()) {

                        if (value.toUpperCase() === 'HEAD OFFICE') {
                            user = {...user, todaysSchedule: value}
                            t.scheduleTypeOffice.push(user)
                        }

                        else if (value.toUpperCase() === 'WORKING FROM HOME') {
                            user = {...user, todaysSchedule: value}
                            t.scheduleTypeWorkingFromHome.push(user)
                        }

                        else if (value.toUpperCase() === 'HOLIDAY') {
                            user = {...user, todaysSchedule: value}
                            t.scheduleTypeHoliday.push(user)
                        }

                        else {
                            user = {...user, todaysSchedule: value}
                            t.scheduleTypeSite.push(user)
                        }

                    }

                    t.sortFilteredScheduleData(t.scheduleTypeOffice)
                    t.sortFilteredScheduleData(t.scheduleTypeWorkingFromHome)
                    t.sortFilteredScheduleData(t.scheduleTypeHoliday)
                }
            })
        },

        /**
         * Sort Filtered Schedule Data
         *
         * Take the filtered user schedule data and sort if by the following criteria, in order:
         *  - Swapp status ('in' first)
         *  - User name (a-z)
         *
         * @param scheduleType - the filtered array of a given schedule value to sort through
         */
        sortFilteredScheduleData(scheduleType) {
            scheduleType = scheduleType.sort((a, b) => {
                if (scheduleType[0].todaysSchedule === 'Head Office') {
                    if (a.swappStatus < b.swappStatus) return 1
                    if (a.swappStatus > b.swappStatus) return -1
                }
                if (a.userName > b.userName) return 1
                if (a.userName < b.userName) return -1
            })
        },

        /**
         * Get Sites Collection
         *
         * Clear the table data to avoid duplications.
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted to the tableData array.
         *
         * @returns {Promise<void>}
         */
        getSitesCollectionData: async function () {
            const t = this

            const collection = t.$firebase.db.collection('sites')
            collection.onSnapshot(snapshot => {

                // Clear the users data to avoid duplications
                t.sitesData = []

                snapshot.forEach(doc => {
                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.sitesData.push(document)
                    }
                })
            })
        },

        /**
         * Get Weather Collection
         *
         * Fetch all the data from the Weather collection and call to set a five-day forecast.
         *
         * @returns {Promise<void>}
         */
        getWeatherCollectionData: async function () {
            const t = this

            // Get weather collection
            const collection = t.$firebase.db.collection('weather')
            collection.onSnapshot(snapshot => {

                // Clear the users data to avoid duplications
                t.weatherData = []

                // Iterate over the weather and set it to the weatherData
                snapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    t.weatherData = document
                })

                // Call for a five day forecast
                t.setFiveDayWeatherForecast(t.weatherData)
            })
        },

        /**
         * Set Five Day Weather Forecast
         *
         * Using the raw weather data from the collection, grab the first 5 days
         * and iterate over it to add an icon name from the icon id.
         *
         * @param weatherData - the raw weather data from the collection
         */
        setFiveDayWeatherForecast(weatherData) {
            const t = this
            let fiveDayForecast = []

            // Capture the next five days
            weatherData = weatherData.daily.slice(0, 5)

            // Iterate over the forecast and add the icon names to each day
            weatherData.forEach(day => {
                fiveDayForecast.push({
                    ...day,
                    image_icon: t.getWeatherIconById(day.weather[0].id)
                })
            })

            // Set the forecast for rendering
            t.fiveDayForecast = fiveDayForecast
        },

        /**
         * Get Weather Icon By ID
         *
         * Take the weather icon id, and return an icon name to display.
         *
         * @param id - the weather icon id
         * @return String - the icon name to display
         */
        getWeatherIconById(id) {
            let icon;

            switch (true) {
                case id >= 200 && id < 300:
                    icon = 'icons8-storm';
                    break;
                case id >= 300 && id < 400:
                    icon = 'icons8-light-rain';
                    break;
                case id >= 500 && id < 504:
                    icon = 'icons8-rain';
                    break;
                case id === 511:
                    icon = 'icons8-sleet';
                    break;
                case id >= 511 && id < 531:
                    icon = 'icons8-rain-cloud';
                    break;
                case id >= 600 && id < 700:
                    icon = 'icons8-snow';
                    break;
                case id >= 701 && id < 800:
                    icon = 'icons8-haze';
                    break;
                case id === 800:
                    icon = 'icons8-sun';
                    break;
                case id === 801:
                    icon = 'icons8-partly-cloudy-day';
                    break;
                case id === 802:
                    icon = 'icons8-partly-cloudy-day';
                    break;
                case id === 803:
                    icon = 'icons8-cloud';
                    break;
                case id === 804:
                    icon = 'icons8-cloud';
                    break;

                default:
                    console.log('weather icon id  not recognised: ', id)
            }

            return icon
        },

        /**
         * Get Amount of Users In
         *
         * Filter the userData by who's currently swapped in to a new array,
         * and return the length of it.
         *
         * @returns {Number} Number of users currently swapped in
         */
        getAmountOfUsersIn() {
            const t = this

            const swappedInUsers = t.userData.filter(user => user.swappStatus)
            return swappedInUsers.length
        },

    },

    /**
     * Mounted
     *
     * Pre-load the required data before components render to the screen.
     *
     * @returns {Promise<void>}
     */
    async mounted() {
        const t = this

        // Get collection data
        await t.getUsersCollectionData()
        await t.getSitesCollectionData()
        await t.getWeatherCollectionData()
        await t.getUserScheduleCollectionData()

        // Start clock timer
        t.interval = setInterval(t.getTime, 1000)

    },

    /**
     * Before Destroy
     *
     * Kill any running processes.
     */
    beforeDestroy() {
        const t = this

        clearInterval(t.interval)
    }
}
</script>

<style>
/*.dshbrd-cardGrid {*/
/*    display: grid;*/
/*    grid-template-columns: repeat(auto-fill, minmax(356px, 1fr));*/
/*    grid-gap: 16px;*/

/*    padding: 16px;*/
/*}*/
</style>
