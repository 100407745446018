<!--
Concerns

Renders the Concerns page displaying a list of concerns (table on tablets and desktops, cards on mobile).
-->
<template>
	<v-row no-gutters class="fill-height" style="height: 100% !important">

		<!--
		Left Panel
		-->
		<v-col :cols="GET_panelLayout.leftPanel" class="pa-4 ma-0">

			<!--
			Header
			-->
			<v-row class="ma-0" dense>

				<!--Page title-->
				<v-col class="pa-0 mb-4" cols="12">
					<app-text category="text-xlarge" class="primary--text">Concerns</app-text>
				</v-col>

				<!--
				Filters
				-->
				<v-row dense class="d-flex ma-0 mb-4 pa-0">

					<!--Search-->
					<v-col :cols="$vuetify.breakpoint.width < 792 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 792 ? 'pa-0' : 'pa-0 pr-1'">
						<v-text-field background-color="white" v-model="search" prepend-inner-icon="icons8-search"
									  filled dense :label="$t(`filters.search`)" clearable hide-details></v-text-field>
					</v-col>

					<!--Type-->
					<v-col :cols="$vuetify.breakpoint.width < 792 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 792 ? 'pa-0 mb-2' : 'pa-0 px-1'"
						   v-if="$vuetify.breakpoint.lgAndUp && ['SA'].includes(GET_currentUser.userLevel)">
						<v-select background-color="white" v-model="filter.type" :items="concernTypes" item-text="text"
								  item-value="value" filled dense clearable :label="$t(`filters.type`)"
								  hide-details></v-select>
					</v-col>

					<!--Created-->
					<v-col :cols="$vuetify.breakpoint.width < 792 ? 12 : 4"
						   :class="$vuetify.breakpoint.width < 792 ? 'd-flex align-center pa-0' : 'd-flex align-center pa-0 pl-1'"
						   v-if="$vuetify.breakpoint.lgAndUp && ['SA'].includes(GET_currentUser.userLevel)">
						<v-select background-color="white" v-model="sortBy" :items="computedHeaders" item-text="text"
								  item-value="value" filled clearable dense :label="$t(`filters.sortBy`)" hide-details>
							<template v-slot:item="data">{{ $t(`fields.${data.item.text}`) }}</template>
							<template v-slot:selection="data">{{ $t(`fields.${data.item.text}`) }}</template>
						</v-select>
						<!--Filer up/down button-->
						<v-btn icon class="ml-2" @click="filter.sortAsc = !filter.sortAsc">
							<v-icon
								:class="{ 'icons8-collapse-arrow': filter.sortAsc, 'icons8-expand-arrow': !filter.sortAsc }"
								class="text-h5"/>
						</v-btn>
					</v-col>
				</v-row>

				<!-- Status filters | Action buttons -->
				<v-col cols="12" class="d-flex mb-4 pa-0 align-center">

					<!--Status filters-->
					<div v-if="['SA'].includes(GET_currentUser.userLevel)">
						<v-btn-toggle v-model="filter.status" multiple color="white" active-class="white"
									  background-color="lightgrey" dense>

							<!--Resolved-->
							<v-btn value="RESOLVED">
								<v-icon color="success" class="icons8-checkmark-yes"/>
								<app-text v-if="$vuetify.breakpoint.mdAndUp" category="text-small"
										  class="success--text">
									{{ $t(`status.resolved`) }}
								</app-text>
							</v-btn>

							<!--On Hold-->
							<v-btn value="ONHOLD">
								<v-icon color="error" class="icons8-private"/>
								<app-text v-if="$vuetify.breakpoint.mdAndUp" category="text-small" class="error--text">
									{{ $t(`status.onhold`) }}
								</app-text>
							</v-btn>

							<!--Pending-->
							<v-btn value="PENDING">
								<v-icon color="accent" class="icons8-circle"/>
								<app-text v-if="$vuetify.breakpoint.mdAndUp" category="text-small"
										  class="primary--text">
									{{ $t(`status.pending`) }}
								</app-text>
							</v-btn>

							<!--Draft-->
							<v-btn value="DRAFT">
								<v-icon class="grey--text text--lighten-1 icons8-inactive-state"/>
								<app-text v-if="$vuetify.breakpoint.mdAndUp" category="text-small" class="grey--text">
									{{ $t(`status.draft`) }}
								</app-text>
							</v-btn>

						</v-btn-toggle>
					</div>

					<!--Show deleted switch-->
					<div v-if="['DA'].includes(GET_currentUser.userLevel)">
						<div class="d-flex ml-8" v-if="$vuetify.breakpoint.mdAndUp">
							<v-icon class="icons8-trash display-1" color="primary"/>
							<v-switch @change="getTableData()" v-model="showDeleted" class="my-n4 pt-5 ml-4" inset
									  color="purple"></v-switch>
						</div>
					</div>

					<v-spacer/>

					<!--
					Action Buttons
					 - Add Concern | Columns | Export
					-->
					<v-btn v-if="$vuetify.breakpoint.width > 792"
						   @click.native="openItem('', 'New', false)" color="primary mr-2" depressed>
						<v-icon class="icons8-siren"/>
						<app-text v-if="$vuetify.breakpoint.width > 928" class="ml-2">Add New Concern</app-text>
					</v-btn>

					<!--Columns | Export-->
					<div v-if="['SA'].includes(GET_currentUser.userLevel)" class="d-flex align-center">

						<!--Columns-->
						<v-btn v-if="$vuetify.breakpoint.width > 664" @click.native="columnsDialog = true"
							   color="primary mr-2" depressed>
							<v-icon class="icons8-select-column"/>
							<app-text v-if="$vuetify.breakpoint.width > 1096" class="ml-2">Columns</app-text>
						</v-btn>

						<!--Export-->
						<v-btn v-if="$vuetify.breakpoint.width > 664" @click.native="mix_exportDocuments"
							   color="primary" depressed>
							<v-icon class="icons8-export-csv"/>
							<app-text v-if="$vuetify.breakpoint.width > 1096" class="ml-2">Export</app-text>
						</v-btn>

					</div>

				</v-col>

				<!--
				Add Concern
				 - Create a new Concern. Only render on devices <= 384px in width
				-->
				<v-btn v-if="$vuetify.breakpoint.width <= 792" @click.native="openItem('', 'New', false)"
					   block class="primary mb-4">
					<v-icon block class="icons8-siren mr-4"/>
					<app-text>Add New Concern</app-text>
				</v-btn>

			</v-row>

			<!--
			Table
			-->
			<v-row class="ma-0">
				<v-col v-if="$vuetify.breakpoint.lgAndUp"
					   class="tabledata pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<v-data-table
						:headers="computedHeaders"
						:items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
						:items="computedTableData"
						item-key="id"
						class="elevation-0"
						hide-default-header
						:search="search">

						<!--Headers-->
						<template v-slot:header="{ props }">
							<th v-for="(head, index) in props.headers" :key="index" :align="head.align" class="pa-4">
								<app-text category="text-small" class="grey--text">{{ head.text }}
								</app-text>
							</th>
						</template>

						<!--Status-->
						<template v-slot:[`item.concernStatus`]="{ item }">
							<v-icon v-if="item.concernStatus === 'RESOLVED'"
									color="success"
									class="icons8-checkmark-yes display-1 d-flex justify-start"/>
							<v-icon v-if="item.concernStatus === 'ONHOLD'"
									color="warning"
									class="icons8-high-importance display-1 d-flex justify-start"/>
							<v-icon v-if="item.concernStatus === 'PENDING'"
									color="accent"
									class="icons8-circle display-1 d-flex justify-start"/>
							<v-icon v-if="item.concernStatus === 'DRAFT'"
									color="grey"
									class="icons8-inactive-state display-1 d-flex justify-start"/>
						</template>

						<!--ID-->
						<template v-slot:[`item.id`]="{ item }">
							<app-text category="text-small"
									  v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
									  class="error--text d-inline-block text-truncate" style="max-width: 200px;">
								<v-icon color="error" class="icons8-trash"/>
								<app-text category="text-small">{{ item.id }}</app-text>
							</app-text>
							<app-text category="text-small" v-else class="d-inline-block text-truncate"
									  style="max-width: 200px;">{{ item.id }}
							</app-text>
						</template>

						<!--Name-->
						<template v-slot:[`item.concernName`]="{ item }">
							<app-text category="text-small"
									  v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
									  class="error--text d-inline-block text-truncate ">
								<v-icon color="error" class="icons8-trash"/>
								{{ item.concernName }}
							</app-text>
							<app-text v-else category="text-small" class="">{{ item.concernName }}</app-text>
						</template>

						<!--Concern Type-->
						<template v-slot:[`item.concernType`]="{ item }">
							<app-text category="text-small">{{ lookupField('concernTypes', 'text', item.concernType)
								}}
							</app-text>
						</template>

						<!--Created Date-->
						<template v-slot:[`item.createdDateTime`]="{ item }">
							<div @click="MIX_popupUser(item.createdDateTime)" class="pointer noselect">
								<!--<v-icon>icons8-identification-documents</v-icon>-->
								<app-text category="text-small">{{ item.createdDateTime }}</app-text>
							</div>
						</template>

						<!--Created User Data-->
						<template v-slot:[`item.createdUserData`]="{ item }">
							<div @click="MIX_popupUser(item.createdUserData.userId)" class="pointer noselect">
								<!--<v-icon>icons8-identification-documents</v-icon>-->
								<app-text category="text-small">{{ item.createdUserData.userName }}</app-text>
							</div>
						</template>

						<!--Modified Date-->
						<template v-slot:[`item.modifiedDateTime`]="{ item }">
							<div @click="MIX_popupUser(item.modifiedDateTime)" class="pointer noselect">
								<!--<v-icon>icons8-identification-documents</v-icon>-->
								<app-text category="text-small">{{ item.modifiedDateTime }}</app-text>
							</div>
						</template>

						<!--Modified User Data-->
						<template v-slot:[`item.modifiedUserData`]="{ item }">
							<div @click="MIX_popupUser(item.modifiedUserData.userId)" class="pointer noselect">
								<!--<v-icon>icons8-identification-documents</v-icon>-->
								<app-text category="text-small">{{ item.modifiedUserData.userName }}</app-text>
							</div>
						</template>

						<!--Delete Date-->
						<template v-slot:[`item.deleteUserData`]="{ item }">
							<div v-if="item.deleteUserData !== undefined"
								 @click="MIX_popupUser(item.deleteUserData.userId)" class="pointer noselect">
								<!--<v-icon>icons8-identification-documents</v-icon>-->
								<app-text category="text-small">{{ item.deleteUserData.userName }}</app-text>
							</div>
						</template>

						<!--Delete User Data-->
						<template v-slot:[`item.delete`]="{ item }">
							<div v-if="item.deleteUserData !== undefined" @click="MIX_popupUser(item.delete)"
								 class="pointer noselect">
								<!--<v-icon>icons8-identification-documents</v-icon>-->
								<app-text category="text-small">{{ item.delete }}</app-text>
							</div>
						</template>

						<!--Action-->
						<template v-slot:[`item.actions`]="{ item }">
							<v-btn @click="openItem(item.id, 'View', false)"
								   depressed class="primary--text white">Open
								<v-icon color="primary" class="icons8-forward pa-0"/>
							</v-btn>
						</template>

					</v-data-table>
				</v-col>
			</v-row>

			<!--
			Mobile Cards
			 - Instead of table for small-screened devices
			-->
			<v-row class="ma-0" no-gutters>
				<v-col v-if="$vuetify.breakpoint.mdAndDown"
					   class="tabledata pa-0" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
					<v-row no-gutters>
						<v-col v-for="item in computedTableData" @click="openItem(item.id, 'View', false)"
							   class="white tablecard mb-4" cols="12" xs="12" :key="item.id">
							<v-row class="d-flex align-center ma-4" no-gutters>

								<!--Status icons-->
								<v-col class="flex-grow-0 mr-4">
									<v-icon v-if="item.concernStatus === 'RESOLVED'"
											:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
											color="success"
											class="icons8-checkmark-yes iconbackground display-1"/>
									<v-icon v-if="item.concernStatus === 'ONHOLD'"
											:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
											color="warning"
											class="icons8-high-importance iconbackground display-1"/>
									<v-icon v-if="item.concernStatus === 'PENDING'"
											:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
											color="accent"
											class="icons8-circle iconbackground display-1"></v-icon>
									<v-icon v-if="item.concernStatus === 'DRAFT'"
											:style="{'background-image': 'url(' + require('@/assets/circle.svg') + ')'}"
											color="grey"
											class="icons8-inactive-state iconbackground display-1"/>
								</v-col>

								<!--
								Concern Details
								  - Name | Type | Date
								-->
								<v-col class="flex-grow-1">

									<!--Concern name-->
									<app-text category="text-default-bold">{{ item.concernName }}</app-text>

									<!--Concern type-->
									<app-text category="text-small" class="grey--text noselect">{{ item.concernType }}
									</app-text>

									<!--Concern date-->
									<app-text category="text-small-bold" class="grey--text noselect">
										{{ MIX_fromNow(item.createdDateTime, 'DD-MMM-YY HH:mm', false) }}
									</app-text>

								</v-col>

								<!--Action button-->
								<v-col class="flex-grow-0">
									<v-icon @click="openItem(item.id, 'View', false)"
											class="icons8-forward" color="primary"/>
								</v-col>

							</v-row>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

		</v-col>

		<!--
		Right Panel
		-->
		<transition name="custom-classes-transition"
					enter-active-class="animate__animated animate__fadeIn animate__faster"
					leave-active-class="animate__animated animate__fadeOut animate__faster"
					mode="out-in">
			<rightpanel></rightpanel>
		</transition>

		<!--
		Columns Dialog Box
		-->
		<v-dialog v-model="columnsDialog" scrollable max-width="300px">
			<v-card>

				<!--Title-->
				<app-text category="text-large" class="primary--text pa-4">{{ $t('miscHeadings.showHideColumns') }}
				</app-text>

				<v-divider/>

				<!--List items-->
				<v-card-text>
					<v-list>
						<v-list-item v-for="(header, index) in headers" :key="index">
							<app-text category="text-default">
								<v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
											:false-value="true" :true-value="false"
											:label="$t(`fields.${header.text}`)"></v-checkbox>
							</app-text>
						</v-list-item>
					</v-list>
				</v-card-text>

				<v-divider/>

				<!--Close button-->
				<v-card-actions class="text-right">
					<v-spacer/>
					<v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
				</v-card-actions>

			</v-card>
		</v-dialog>

	</v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'; // Map Actions / Getters
export default {
	name: "Concerns",

	data: () => ({
		title: "Concerns",
		search: '', // Table Search
		collection: "concerns",
		collectionItem: "concern",
		collectionTitle: "Concerns",
		collectionItemTitle: "Concern",
		langPath: "concerns",
		columnsDialog: false,
		sortBy: 'createdDateTime', // Default Table Sort By
		customWhere: [],
		// * CUSTOM DATA
		filter: {
			// Default Filters
			sortAsc: false,
			status: ['RESOLVED', 'ONHOLD', 'PENDING', 'DRAFT'],
			type: '',
		},
		tableData: [], // Table Data
		headers: [ // Table Headers
			// hidden shows the initial value of the column - hidden or visible
			// hides determines if you want to hide the column on screen resize
			{
				text: 'status',
				value: 'concernStatus',
				sortable: false,
				align: 'center',
				hidden: false,
				hide: false,
				width: '10px'
			},
			{text: 'id', value: 'id', sortable: false, align: 'left', hidden: true, hide: false, width: '20px'},
			{
				text: 'name',
				value: 'concernName',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: false,
				width: '20px'
			},
			{
				text: 'type',
				value: 'concernType',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: false,
				width: '20px'
			},
			{
				text: 'created',
				value: 'createdDateTime',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: false,
				width: '20px'
			},
			{
				text: 'createdUserData',
				value: 'createdUserData',
				align: 'left',
				sortable: false,
				hidden: false,
				hide: true,
				width: '20px'
			},
			{
				text: 'modified',
				value: 'modifiedDateTime',
				align: 'left',
				sortable: false,
				hidden: true,
				hide: false,
				width: '20px'
			},
			{
				text: 'modifiedUserData',
				value: 'modifiedUserData',
				align: 'left',
				sortable: false,
				hidden: true,
				hide: false,
				width: '20px'
			},
			{text: 'delete', value: 'delete', align: 'left', sortable: false, hidden: true, hide: false, width: '20px'},
			{
				text: 'deleteUserData',
				value: 'deleteUserData',
				align: 'left',
				sortable: false,
				hidden: true,
				hide: false,
				width: '20px'
			},
			{text: '', value: 'actions', align: 'right', sortable: false, hidden: false, width: '20px'},
		],
		// LOOKUP VALUES
		concernTypes: [],
		concernSeverities: [],
		// DELETED
		showDeleted: false
	}),

	computed: {
		// Map Getters
		...mapGetters({
			GET_panelLayout: 'GET_panelLayout',
			GET_currentUser: 'GET_currentUser',
			GET_currentUserData: 'GET_currentUserData',
			GET_lookupValues: 'GET_lookupValues',
			GET_help: 'GET_help'
		}),

		// Computed Table Headers
		computedHeaders() {
			var t = this;
			var headers = t.headers;
			return (t.$filter(headers, {
				hidden: false, // filter headers based on initial hidden value
			}));
		},

		// Computed Cols
		computedCols() {
			var t = this;
			var cols
			var leftPanel = t.GET_panelLayout.leftPanel
			switch (true) {
				case (leftPanel === 12):
					cols = 6;
					break
				case (leftPanel === 6):
					cols = 12;
					break;
				default:
					cols = 12;
			}
			if (t.$vuetify.breakpoint.smAndDown) {
				cols = 12
			}
			return cols
		},

		// Computed Table Data
		computedTableData() {
			const t = this;
			let tableData = t.tableData;
			// Filter by status
			tableData = tableData.filter(function (item) {
				return t.filter.status.includes(item[t.collectionItem + 'Status']);
			});
			// Filter by type
			if ((t.filter.type !== '') && (t.filter.type !== undefined) && (t.filter.type !== null)) {
				tableData = tableData.filter(function (item) {
					return t.filter.type === item[t.collectionItem + 'Type'];
				});
			}
			// Filter by Search Mobile
			if (t.search !== '' && t.$vuetify.breakpoint.smAndDown) {
				tableData = tableData.filter(function (item) {
					const itemName = item[t.collectionItem + 'Name'].toUpperCase();
					const search = t.search.toUpperCase();
					return itemName.includes(search)
				});
			}
			const tableDataList = tableData.map(e => {
				return {
					id: e.id,
					concernStatus: e.concernStatus,
					concernName: e.concernName,
					concernDescription: e.concernDescription,
					concernType: e.concernType,
					createdDateTime: t.MIX_formatDateTime(e.createdDateTime, 'x', 'DD-MMM-YY HH:mm'),
					createdUserData: e.createdUserData,
					modifiedDateTime: t.MIX_formatDateTime(e.modifiedDateTime, 'x', 'DD-MMM-YY HH:mm'),
					modifiedUserData: e.modifiedUserData,
					delete: t.MIX_formatDateTime(e.delete, 'x', 'DD-MMM-YY HH:mm'),
					deleteUserData: e.deleteUserData,
				};
			});
			// Sort the Table by the Field in the Sort By Filter
			const sortResults = t.$sortBy(tableDataList, t.sortBy)
			if (!t.filter.sortAsc) {
				sortResults.reverse()
			}
			return (sortResults);
		}
	},

	watch: {
		GET_currentUser: {
			handler() {
				this.getTableData();
			}, deep: true
		},
		GET_panelLayout: {
			handler() {
				let t = this;
				if (t.GET_panelLayout.show === false) {
					for (var i = 0; i < t.headers.length; i++) {
						if (t.headers[i].hide === true) {
							t.headers[i].hidden = false;
						}
					}
				} else {
					for (var i = 0; i < t.headers.length; i++) {
						if (t.headers[i].hide === true) {
							t.headers[i].hidden = true;
						}
					}
				}
			}, deep: true
		}
	},

	methods: {
		...mapActions({
			ACT_openItem: "ACT_openConcern",
			ACT_openConcern: "ACT_openConcern",
			ACT_areas: "ACT_areas",
		}),

		// UPDATE BREADCRUMBS
		updateBreadcrumbs() {
			var t = this;
			var breadcrumbs = [];
			breadcrumbs.push({name: `${t.collectionTitle}`, path: `/${t.collection}`}) // Set the Main Path
			t.MIX_breadcrumbs(breadcrumbs);
		},

		// OPEN ITEM
		openItem: async function (id, process, popup) {
			var t = this;
			var item = {}
			t.dynamicComponentKey++;
			item.process = process;
			item.popup = popup;
			if (item.process !== 'New') {
				var itemResult = await t.MIX_readDocumentById(t.collection, id)
				item.data = itemResult.data;
			} else {
				item.data = null;
			}
			t.ACT_openItem(item); // Open Item
			if (t.$vuetify.breakpoint.lgAndDown) {
				t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem);
				t.MIX_setPanelLayout(12, 0, false, '');
			} else {
				t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem);
				// * HIDE HEADERS
				for (var i = 0; i < t.headers.length; i++) {
					if (t.headers[i].hide === true) {
						t.headers[i].hidden = true;
					}
				}
			}
			return;
		},

		/**
		 * Get Table Data
		 *
		 * Get all Concerns data from the database and add it to the tableData array for rendering.
		 * A 'Staff User' can only view their own Concerns, but a 'Staff Admin' can view all the concerns.
		 *
		 * @returns {Promise<void>}
		 */
		getTableData: async function () {
			const t = this

			// Get Concerns collection data
			let collection = t.$firebase.db.collection(t.collection)

			// If the user is a Staff User (SU), and
			if (['SU'].includes(t.GET_currentUser.userLevel) &&
				JSON.stringify(t.customWhere) !== '[]') {

				// Apply the custom where clause
				const customWhereLength = t.customWhere.length
				for (let i = 0; i < customWhereLength; i++) {
					const query = {
						field: t.customWhere[i].key,
						operator: t.customWhere[i].operator,
						value: t.customWhere[i].value
					}
					collection = collection.where(query.field, query.operator, query.value)
				}
			}

			collection.onSnapshot(querySnapshot => {

				// Clear the tableData to avoid duplication
				t.tableData = [];

				// Iterate over the collection
				querySnapshot.forEach(doc => {

					const document = doc.data()
					document.id = doc.id;

					// If the document is not marked as deleted, add it to the tableData array
					if (!document.hasOwnProperty('delete')) {
						t.tableData.push(document)
					} else if (t.showDeleted) {
						t.tableData.push(document)
					} else {
						// Do not Show Concern
					}
				})
			})
		},

		// LOOKUP FIELD (GENERIC)
		lookupField: function (lookup, field, value) {
			var t = this;
			var lookupResult = t.$find(t[lookup], {value: value})
			if (lookupResult !== undefined) {
				return (lookupResult)[field];
			} else {
				return ('NOTFOUND')
			}
		}
	},

	async mounted() {
		// * DEFAULT MOUNTED
		var t = this;
		t.MIX_setPanelLayout(12, 0, false, ''); // Hide Right Panel
		t.updateBreadcrumbs(); // Update Breadcrumbs in Title
		// Additional WHERE Clauses for Snapshot
		// TODO customer where should be only for user when not admin
		t.customWhere.push({key: 'createdUserData.userId', operator: '==', value: t.GET_currentUserData.userId});
		t.getTableData(); // Get Table Data
		await t.MIX_sites(); // UPDATE SITES LIST IN STORE
		t.sites = t.GET_sites; // STORE SITES IN LOCAL AREA
		// * LOOKUP RELATED MOUNTED

		// LOOKUPS
		await t.MIX_lookupValues(); // LOAD LOOKUP VALUES
		t.concernTypes = await t.GET_lookupValues('CONCERNTYPES');
		t.concernStatus = await t.GET_lookupValues('CONCERNSTATUSES');
		t.concernSeverities = await t.GET_lookupValues('CONCERNSEVERITIES')

		// * AUTO OPEN SITE IF PATH CONTAINS SITEID
		if ((window.location.pathname !== `/${t.collection}` && window.location.pathname !== `/${t.collection}/`)) {
			var urlPath = window.location.pathname.split('/');
			var id = urlPath[2];
			if (id === 'new') {
				t.openItem('', 'New', false)
			} else {
				t.openItem(id, 'View', false)
			}
		}
	},
}
</script>
