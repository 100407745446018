<!--
Contractor

Renders the form to add/edit/delete Contractors.
-->
<template>
    <div>

        <!--Header-->
        <v-row class="ma-0 pa-4" no-gutters>

            <!--Title-->
            <app-text>{{ form.userName ? form.userName : 'New' }}</app-text>

            <v-spacer/>

            <!--Action buttons-->
            <div>

                <!--Edit | Delete-->
                <div v-if="tabs === 'sitePermit'">

                    <!--Edit-->
                    <v-icon v-if="formMode !== 'New'"
                            @click.native="editDocument"
                            class="icons8-edit"
                            :class="formReadOnly ? 'frc-icon' : 'frc-icon-edit-active'"
                            :color="formReadOnly ? 'warning' : 'white'"
                            size="32"/>

                    <!--Delete-->
                    <v-icon v-if="formMode !== 'New'"
                            @click.native="deleteDocument(form.id)"
                            class="icons8-trash frc-icon"
                            color="error"
                            size="32"/>

                </div>

            </div>

        </v-row>

        <!--Page Content-->
        <v-row class="ma-0" no-gutters>

            <!--Tabs-->
            <v-tabs v-model="tabs" :height="buttonSizeDefault">

                <v-tabs-slider color="accent"/>

                <!--Site Permit-->
                <v-tab href="#sitePermit">
                    <app-text category="text-small">Site Permit</app-text>
                </v-tab>

                <!--Documents-->
                <v-tab href="#documents">
                    <app-text category="text-small">Documents</app-text>
                </v-tab>

            </v-tabs>

            <!--Tab Content-->
            <v-tabs-items v-model="tabs" class="appbackground" style="width: 100%">

                <!--Site Permit Tab Content-->
                <v-tab-item value="sitePermit">

                    <!--Instructions-->
                    <app-text class="ma-4">
                        Complete the following form to create a new Site Permit.
                        The permit will need to be approved by Sixways before the user can SWAPP in.
                    </app-text>

                    <v-divider class="ma-4"/>

                    <!--Form-->
                    <v-form class="mx-4">

                        <site-permit v-on:emitCreatedSitePermit="emittedCreatedSitePermit"
                                     :formMode="formMode"
                                     :user="form"/>

                        <v-divider/>

                        <!--Meta data-->
                        <div class="ma-4">
                            <app-text category="text-small" class="grey--text">Meta data</app-text>
                        </div>

                    </v-form>

                </v-tab-item>

            </v-tabs-items>

        </v-row>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ContractorsInviteDialog from "../views/contractors/contractorsInviteDialog/contractorsInviteDialog";

export default {

    name: "ContractorArea",

    components: {ContractorsInviteDialog},

    data: () => ({
        collection: 'users',
        collectionTitle: 'Users',
        collectionItem: 'user',
        collectionItemTitle: 'User',

        form: {
            id: '',
            userEmail: '',
            userName: '',
            userTelephone: '',
        },
        formMode: 'New',
        formReadOnly: false,
        tabs: 'tabs',
    }),

    computed: {
        ...mapGetters({
            GET_openItem: 'GET_openContractorArea',
            GET_confirmBoxResult: 'confirmBox_store/GET_confirmBoxResult',
        }),
    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openContractorArea",
            ACT_openContractorArea: "ACT_openContractorArea"
        }),

        /**
         * Save Document
         *
         * Save or update the document with the collection name and form details.
         *
         * @returns {Promise<void>}
         */
        async saveDocument() {
            const t = this
            let createDocumentResult

            // If the formMode is New, create a new document
            if (t.formMode === 'New') {
                createDocumentResult = await t.MIX_createDocument(t.collection, t.form)
            }

            // If the formMode is Edit, update the current document
            else if (t.formMode === 'Edit') {
                createDocumentResult = await t.MIX_updateDocument(t.collection, t.form)
            }

            // If the document saved/updated successfully, set the form back to viewable state
            if (createDocumentResult.code === 1) {
                t.formMode = 'View'
                t.formReadOnly = true
                await t.refreshDocumentView();
            }

            // Call for a confirmation alert
            t.renderConfirmationAlert(createDocumentResult)
        },

        /**
         * Edit Document
         *
         * Toggle the form between an editable, and a viewable state.
         */
        editDocument() {
            const t = this

            if (t.formReadOnly) {
                t.formMode = 'Edit'
                t.formReadOnly = false
            } else {
                t.formMode = 'View'
                t.formReadOnly = true
                t.refreshDocumentView()
            }

        },

        /**
         * Delete Document
         *
         * Call for the confirmation box to delete a document.
         * The deletion is handled in this file's watch -> GET_confirmBoxResult handler function.
         */
        deleteDocument: function (id) {
            const t = this
            const width = t.$vuetify.breakpoint.width <= 425 ? '' : '400'

            t.MIX_confirmBox(
                {
                    show: true,
                    collection: 'sitePermit',
                    id: id,
                    title: `Delete Site Permit`,
                    description: `Are you sure you want to delete this Site Permit: <br /><div class="mt-4"><strong>${t.form[t.collectionItem + 'Name']}</strong></div>`,
                    width,
                    trueButtonText: 'Yes',
                    trueButtonValue: 'YES',
                    falseButtonText: 'No',
                    falseButtonValue: 'NO',
                }
            )
        },

        /**
         * Refresh Document View
         *
         * Load the new document and sets the payload onto ACT_openItem
         */
        async refreshDocumentView() {
            const t = this;

            const itemResult = await t.MIX_readDocumentById(t.collection, t.form.id)
            t.form = itemResult.data
            let payload = {}
            payload.process = 'View';
            payload.popup = false;
            payload.data = itemResult.data;

            await t.ACT_openItem(payload);
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} saved`, null, null)
            } else {
                t.MIX_alert(-1, `Error saving ${t.collectionItemTitle}`, null, null)
            }
        },

        /**
         * Emitted Created Site Permit
         *
         * If the site permit has been successfully created, call editDocument() to reset the form.
         *
         * @param createdPermit boolean - if the site permit was created
         */
        emittedCreatedSitePermit(createdPermit) {
            const t = this

            if (createdPermit) {
                t.editDocument()
            }
        },

    },

    watch: {
        GET_confirmBoxResult: {
            handler: async function () {
                const t = this

                if (t.GET_confirmBoxResult.data.collection === 'questions') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const deleteDocumentResult = await t.MIX_deleteDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (deleteDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Deleted`, deleteDocumentResult.data, null);
                            await t.refreshDocumentView();
                        } else {
                            t.MIX_alert(-1, `Error Deleting Question`, null, null);
                        }
                    }
                } else if (t.GET_confirmBoxResult.data.collection === 'questions/destroy') {
                    if (t.GET_confirmBoxResult.data.result === 'YES') {
                        const destroyDocumentResult = await t.MIX_destroyDocument('questions', t.GET_confirmBoxResult.data.id);

                        if (destroyDocumentResult.code === 1) {
                            t.MIX_alert(1, `Question Destroyed`, destroyDocumentResult.data, null);
                            t.MIX_setPanelLayout(12, 0, false, '', '');
                        } else {
                            t.MIX_alert(-1, `Error Destroying Question`, null, null);
                        }
                    }
                }

            }, deep: true
        }
    },

    async mounted() {
        const t = this

        // Get and populate form data
        const openItem = await t.GET_openItem
        t.formMode = openItem.process;
        if (openItem.process === 'New') {
            t.formReadOnly = false
        } else {
            t.form = openItem.data
            t.formReadOnly = true
        }

    }

}
</script>
