<!--
Contractor's Area

Renders the Contractor's Area page which displays a table containing the individual Contractor data.

The page also contains a Contractor form where the user can add/edit/delete Contractors.
-->
<template>
    <v-row no-gutters style="height: 100%">

        <!--Left panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4 ma-0">

            <!--Header-->
            <v-row class="ma-0" no-gutters>

                <!--Title-->
                <v-col cols="12" class="mb-4 pa-0">
                    <page-title icon="icons8-business-building" pageTitle="Contractor's Area"/>
                </v-col>

                <!--Search-->
                <v-col :cols="$vuetify.breakpoint.width <= 600 ? 12 : 6"
                       class="pa-0 mb-4">

                    <app-input input-type="textInput"
                               clearable
                               :label="$t('filters.search')"
                               :append-icon="'icons8-search'"
                               v-model="search"/>

                </v-col>

                <!--New Contractor Button-->
                <v-col class="d-flex justify-end align-end pa-0 mb-4">

                    <!--Invite Contractor-->
                    <contractors-invite-dialog :contractorCompanyId="GET_currentUser.contractorCompanyId"/>

                </v-col>

            </v-row>

            <!--Table-->
            <v-row v-if="$vuetify.breakpoint.width >= 600" class="ma-0" no-gutters>
                <v-col class="pa-0">

                    <v-data-table class="rounded-lg"
                                  :headers="computedHeaders"
                                  :items="computedTableData"
                                  :search="search"
                                  sort-by="userName">

                        <!--Id-->
                        <template v-slot:item.id="{ item }">
                            <app-text category="text-small">{{ item.id }}</app-text>
                        </template>

                        <!--Status-->
                        <template v-slot:item.userStatus="{ item }">
                            <v-icon v-if="item.userStatus === 'PENDING'" class="icons8-circle" color="warning" size="32"/>
                            <v-icon v-if="item.userStatus === 'APPROVED'" class="icons8-checkmark-yes" color="success" size="32"/>
                            <v-icon v-if="item.userStatus === 'SUSPENDED'" class="icons8-private" color="error" size="32"/>
                            <v-icon v-if="item.userStatus === 'ARCHIVED'" class="icons8-xbox-a" color="grey" size="32"/>
                        </template>

                        <!--Name-->
                        <template v-slot:item.userName="{ item }">
                            <app-text category="text-small">{{ item.userName }}</app-text>
                        </template>

                        <!--Telephone-->
                        <template v-slot:item.userTelephone="{ item }">
                            <app-text category="text-small">{{ item.userTelephone }}</app-text>
                        </template>

                        <!--Email-->
                        <template v-slot:item.userEmail="{ item }">
                            <app-text category="text-small">{{ item.userEmail }}</app-text>
                        </template>

                        <!--Permit-->
                        <template v-slot:item.sitePermitStatus="{ item }">
                            <v-icon v-if="item.sitePermitStatus === 'Pending'" class="icons8-circle" color="warning" size="32"/>
                            <v-icon v-if="item.sitePermitStatus === 'Approved'" class="icons8-checkmark-yes" color="success" size="32"/>
                            <v-icon v-if="item.sitePermitStatus === 'Rejected'" class="icons8-cancel" color="error" size="32"/>
                            <v-icon v-if="item.sitePermitStatus === 'Suspended'" class="icons8-private" color="error" size="32"/>
                        </template>

                        <!--Action-->
                        <template v-slot:item.actions="{ item }">
                            <div class="d-flex align-center justify-end" style="height: 100%">
                                <v-btn @click="openItem(item.id, 'View', false)"
                                       depressed class="white text--red">Open
                                    <v-icon color="primary" class="icons8-forward"/>
                                </v-btn>
                            </div>
                        </template>

                    </v-data-table>

                </v-col>
            </v-row>

            <!--Mobile Cards-->
            <v-row class="ma-0" no-gutters>
                <v-col v-if="$vuetify.breakpoint.width < 600">
                    <div v-for="contractor in filteredTableData" :key="contractor.id">

                        <v-card @click="openItem(contractor.id, 'View', false)"
                                class="rounded-lg mb-4 pa-4"
                                flat>

                            <!--Name-->
                            <app-text category="text-default-bold" class="mb-2">
                                {{ contractor.userName }}
                            </app-text>

                            <!--Contact number-->
                            <div class="d-flex align-center">
                                <v-icon class="icons8-person mr-2" color="primary" size="16"/>
                                <app-text class="grey--text mr-4">
                                    {{ contractor.userTelephone }}
                                </app-text>
                            </div>

                            <!--Contact email-->
                            <div class="d-flex align-center">
                                <v-icon class="icons8-phone mr-2" color="primary" size="16"/>
                                <app-text class="grey--text">
                                    {{ contractor.userEmail }}
                                </app-text>
                            </div>

                        </v-card>

                    </div>
                </v-col>
            </v-row>

        </v-col>

        <!--Right panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animate__faster"
                    leave-active-class="animate__animated animate__fadeOut animate__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
        </transition>

    </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import ContractorsInviteDialog from "../contractorsInviteDialog/contractorsInviteDialog";

export default {

    name: "ContractorsArea",

    components: {ContractorsInviteDialog},

    data: () => ({
        title: 'ContractorsArea',
        collection: 'users',
        collectionItem: 'contractorArea',
        collectionTitle: 'ContractorsArea',
        collectionItemTitle: 'Contractor Area',

        headers: [
            {text: 'Id', value: 'id', align: 'left', sortable: false, hidden: true},
            {text: 'Status', value: 'userStatus', align: 'left', sortable: false, width: '69px'},
            {text: 'Name', value: 'userName', align: 'left', sortable: false},
            {text: 'Telephone', value: 'userTelephone', align: 'left', sortable: false},
            {text: 'Email', value: 'userEmail', align: 'left', sortable: false},
            {text: 'Site Permit', value: 'sitePermitStatus', align: 'left', sortable: false},
            {text: '', value: 'actions', align: 'right', sortable: false, width: '134px'},
        ],
        search: '',
        tableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
        }),

        /**
         * Computed Headers
         *
         * Returns a filtered headers array which is only those not marked as hidden.
         */
        computedHeaders() {
            const t = this

            return t.headers.filter(header => !header.hidden)
        },

        computedTableData() {
            const t = this

            return t.tableData.filter(user => user.contractorCompanyId === t.GET_currentUser.contractorCompanyId)
        },

        /**
         * Filtered Table Data
         *
         * Take the tableData and return an array of question filtered by the search string.
         *
         * @returns array - users filtered by search string
         */
        filteredTableData() {
            const t = this

            return t.tableData.filter(user => user.userName.toLowerCase().match(t.search.toLowerCase()))
        },

    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openContractorArea",
            ACT_openContractorArea: "ACT_openContractorArea",
        }),

        /**
         * Open Item
         *
         * Call for data of the selected document.
         * Open the Right Panel (form).
         * If the viewport is <= 'large', make the form full-width, if not, split it 50/50.
         *
         * @returns {Promise<void>}
         */
        async openItem(id, process, popup) {
            const t = this
            const item = {}

            t.dynamicComponentKey++
            item.process = process
            item.popup = popup

            if (item.process !== 'New') {
                const itemResult = await t.MIX_readDocumentById(t.collection, id)
                item.data = itemResult.data
            } else {
                item.data = null
            }

            t.ACT_openItem(item)

            // Open form based on device size
            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem);
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)
            }
        },

        async getUsersCollectionData() {
            const t = this

            const collection = t.$firebase.db.collection('users').where("userType", "==", "Contractor")
            collection.onSnapshot(snapShot => {

                // Clear the table data to avoid duplications
                t.tableData = []

                snapShot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    }
                })
            })
        },

    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getUsersCollectionData()
    }

}
</script>
