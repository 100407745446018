<template>
    <v-expansion-panels flat>

        <v-expansion-panel class="rounded-lg">

            <!--Header-->
            <v-expansion-panel-header>

                <!--Title-->
                <app-text category="text-medium-bold" class="primary--text">Getting Started</app-text>

            </v-expansion-panel-header>

            <!--Content-->
            <v-expansion-panel-content>

                <!--Greeting-->
                <app-text>
                    Welcome to the {{ $t('global.companyName') }} Safe Workplace App (SWAPP).
                    We are just getting started, so you can expect more features soon.
                </app-text>

                <!--Instructions-->
                <app-text class="mt-4">
                    To begin, SWAPP In at your place of work using the button below,
                    and don't forget to SWAPP Out when you leave.
                </app-text>

                <!--Instructions-->
                <app-text category="text-default-bold" class="mt-4">
                    Please note: You must log in via mobile to be able to SWAPP in and out
                </app-text>

            </v-expansion-panel-content>

        </v-expansion-panel>

    </v-expansion-panels>
</template>

<script>
export default {

    name: "gettingStartedHomePageCard",

    props: ['isGettingStartedMessageVisible'],

    methods: {

        /**
         * Handle Hide Welcome Text
         *
         * If the welcome message has been closed, add to local storage to not render it again.
         */
        handleHideWelcomeText() {
            const t = this

            localStorage.isGettingStartedMessageVisible = 'hide'
            t.isGettingStartedMessageVisible = false
        },

    },

}
</script>

<style scoped>

</style>
