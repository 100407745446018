<!--
LOGIN

Renders the login form, containing the following:
	- Email (input)
	- Password (input)
	- Register (button)
	- Next (button)
	- Register (link)
	- Rest Password (link)
-->
<template>
    <v-row class="d-flex justify-center ma-4" no-gutters>
        <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="8">

            <!--Header-->
            <!--
			 - Welcome text | Logo
			-->
			<v-row class="justify-center" no-gutters>
				<v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">

					<!--Logo-->
					<div class="d-flex justify-center">
						<v-img :src="require('@/assets/company_logo_full.svg')" max-width="256"/>
					</div>

					<!--Welcome text-->
					<!--<app-text category="text-large" class="justify-center mt-4">-->
					<!--	Welcome to-->
					<!--	<span class="primary&#45;&#45;text font-weight-bold">Sixways Stadium </span>-->
					<!--	SWAPP-->
					<!--</app-text>-->

					<v-divider class="my-4"/>

				</v-col>
			</v-row>

			<!--Title-->
            <!--
			 - Screen title and login instructions
			-->
			<v-row class="justify-center" no-gutters>
				<v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6">

					<!--Title-->
					<app-text category="text-large" class="primary--text">Login</app-text>

					<!--Instruction-->
					<app-text class="mt-4">
						Please enter your email address and password below to login.
					</app-text>

				</v-col>
			</v-row>

			<!--LogIn Form-->
            <!--
			 - Login form with fields for Email and Password, and buttons for Register and Next
			-->
			<v-row class="d-flex justify-center" no-gutters>
				<v-col cols="12" xs="12" sm="8" md="6">

					<!--Form-->
					<firebase-auth-login/>

				</v-col>
			</v-row>

			<!--Links-->
            <!--
			 - Links to Register and Reset Password
			-->
            <v-row class="d-flex justify-center text-center" no-gutters>
                <v-col cols="12" xs="12" sm="8">

                    <!--Register-->
                    <app-text category="text-default" class="justify-center mb-4">Don't have an account?
                        <router-link :to="{ path: 'register' }" replace class="btn btn-link">
                            Register
                        </router-link>
                    </app-text>

                    <!--Reset-->
                    <app-text category="text-default" class="justify-center">Forgot Password?
                        <router-link :to="{ path: 'reset-password' }" replace class="btn btn-link">
                            Reset password
                        </router-link>
                    </app-text>

                </v-col>
            </v-row>

		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'Login',
}
</script>
