<!--
Concerns

Renders the Concerns page displaying a list of concerns (table on tablets and desktops, cards on mobile).
-->
<template>
    <v-row no-gutters class="fill-height" style="height: 100% !important">

        <!--Left Panel-->
        <v-col :cols="GET_panelLayout.leftPanel" class="pa-4">

            <!--Header-->
            <div>

                <!--Page title-->
                <page-title icon="icons8-eye" pageTitle="Observations"/>

                <!--Filters-->
                <v-row no-gutters>

                    <!--Search-->
                    <v-col :cols="$vuetify.breakpoint.width < 820 ? 12 : 4"
                           :class="$vuetify.breakpoint.width < 820 ? '' : 'pr-2'">

                        <app-input inputType="textInput"
                                   clearable
                                   label="Search"
                                   v-model="search"/>

                    </v-col>

                    <!--Type-->
                    <v-col v-if="$vuetify.breakpoint.width > 600 && ['SA'].includes(GET_currentUser.userLevel)"
                           :cols="$vuetify.breakpoint.width < 820 ? 12 : 4"
                           :class="$vuetify.breakpoint.width < 820 ? 'mt-4' : 'px-2'">

                        <app-input inputType="select"
                                   clearable
                                   :items="observationTypesOptionsData"
                                   label="Type"
                                   v-model="filter.type"/>

                    </v-col>

                    <!--Sort by-->
                    <v-col v-if="$vuetify.breakpoint.width > 600 && ['SA'].includes(GET_currentUser.userLevel)"
                           :cols="$vuetify.breakpoint.width < 820 ? 12 : 4"
                           :class="$vuetify.breakpoint.width < 820 ? 'mt-4' : 'pl-2'">

                        <app-input inputType="select"
                                   clearable
                                   :items="computedHeaders"
                                   item-text="text"
                                   label="Sort By"
                                   v-model="sortBy"/>

                    </v-col>

                </v-row>

                <!-- Status filters | Action buttons -->
                <div class="d-flex align-center mt-4" style="width: 100%">

                    <!--Status filters-->
                    <v-btn-toggle v-if="['SA', 'SM'].includes(GET_currentUser.userLevel)"
                                  active-class="white"
                                  background-color="grey"
                                  class="rounded-lg"
                                  color="white"
                                  multiple
                                  style="height: 48px"
                                  v-model="filter.status">

                        <!--Pending-->
                        <v-btn :height="buttonSizeDefault" value="Pending">
                            <v-icon color="error" class="icons8-inactive-state"/>
                            <app-text v-if="$vuetify.breakpoint.width >= 416"
                                      category="text-small" class="grey--text">
                                {{ $t(`status.pending`) }}
                            </app-text>
                        </v-btn>

                        <!--In Progress-->
                        <v-btn :height="buttonSizeDefault" value="In Progress">
                            <v-icon color="orange" class="icons8-circle"/>
                            <app-text v-if="$vuetify.breakpoint.width >= 416"
                                      category="text-small" class="grey--text">
                                In Progress
                            </app-text>
                        </v-btn>

                        <!--Resolved-->
                        <v-btn :height="buttonSizeDefault" value="Resolved">
                            <v-icon color="success" class="icons8-checkmark-yes"/>
                            <app-text v-if="$vuetify.breakpoint.width >= 416"
                                      category="text-small" class="grey--text">
                                {{ $t(`status.resolved`) }}
                            </app-text>
                        </v-btn>

                    </v-btn-toggle>

                    <v-spacer/>

                    <!--Add Button-->
                    <app-btn v-if="$vuetify.breakpoint.width > 864"
                             @click.native="openItem('', 'New', false)"
                             icon="icons8-eye"
                             label="New"/>

                    <!--Columns | Export-->
                    <div v-if="['SA'].includes(GET_currentUser.userLevel) && $vuetify.breakpoint.width > 600"
                         class="d-flex align-center ml-4">

                        <!--Columns-->
                        <app-btn @click.native="columnsDialog = true"
                                 class="mr-4"
                                 color="primary"
                                 hideLabelBelow="1032"
                                 icon="icons8-select-column"
                                 label="Columns"/>

                        <!--Export-->
                        <app-btn @click.native="MIX_exportDocuments(headersCSV,'Observations',formatExport)"
                                 color="primary"
                                 hideLabelBelow="1032"
                                 icon="icons8-export-csv"
                                 label="Export"/>

                    </div>

                </div>

                <!--Add Observation-->
                <!--
                    Create a new Observation. Only render on devices <= 384px in width
                -->
                <app-btn v-if="$vuetify.breakpoint.width <= 864"
                         @click.native="openItem('', 'New', false)"
                         block
                         class="primary mt-4"
                         icon="icons8-eye"
                         label="New Observation"/>

            </div>

            <!--Table-->
            <v-data-table v-if="$vuetify.breakpoint.width > 600"
                          class="rounded-lg mt-4"
                          :headers="computedHeaders"
                          :items-per-page="MIX_itemsPerPage(MIX_breakpoint())"
                          :items="filteredComputedTableData"
                          item-key="id"
                          :search="search"
                          no-data-text="You have no Observations to view">

                <!--ID-->
                <template v-slot:[`item.id`]="{ item }">
                    <app-text category="text-small"
                              v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
                              class="error--text d-inline-block text-truncate" style="max-width: 200px;">
                        <v-icon color="error" class="icons8-trash"/>
                        <app-text category="text-small">{{ item.id }}</app-text>
                    </app-text>
                    <app-text category="text-small" v-else class="d-inline-block text-truncate"
                              style="max-width: 200px;">{{ item.id }}
                    </app-text>
                </template>

                <!--Status-->
                <template v-slot:[`item.observationStatus`]="{ item }">
                    <v-icon v-if="item.observationStatus === 'Pending'"
                            color="error"
                            class="icons8-inactive-state display-1 d-flex justify-start"/>
                    <v-icon v-if="item.observationStatus === 'In Progress'"
                            color="orange"
                            class="icons8-circle display-1 d-flex justify-start"/>
                    <v-icon v-if="item.observationStatus === 'Resolved'"
                            color="success"
                            class="icons8-checkmark-yes display-1 d-flex justify-start"/>
                </template>

                <!--Short Description (issue)-->
                <template v-slot:[`item.observationReportingShortDescription`]="{ item }">
                    <app-text category="text-small"
                              v-if="item.delete !== null && item.delete !== '' && item.delete !== undefined"
                              class="error--text d-inline-block text-truncate ">
                        <v-icon color="error" class="icons8-trash"/>
                        {{ item.observationReportingShortDescription }}
                    </app-text>
                    <app-text v-else category="text-small" class="">
                        {{ item.observationReportingShortDescription }}
                    </app-text>
                </template>

                <!--Type-->
                <template v-slot:[`item.observationType`]="{ item }">
                    <app-text category="text-small">{{ item.observationType }}</app-text>
                </template>

                <!--Site-->
                <template v-slot:[`item.observationSite`]="{ item }">
                    <app-text category="text-small">{{ item.observationSite.siteName }}</app-text>
                </template>

                <!--Created User Data-->
                <template v-slot:[`item.createdUserData`]="{ item }">
                    <app-text category="text-small">{{ item.createdUserData.userName }}</app-text>
                </template>

                <!--Created Date-->
                <template v-slot:[`item.createdDateTime`]="{ item }">
                    <app-text category="text-small">
                        {{ item.createdDateTime }}
                    </app-text>
                </template>

                <!--Modified Date-->
                <template v-slot:[`item.modifiedDateTime`]="{ item }">
                    <app-text category="text-small">
                        {{ item.modifiedDateTime }}
                    </app-text>
                </template>

                <!--Modified User Data-->
                <template v-slot:[`item.modifiedUserData`]="{ item }">
                    <app-text category="text-small">{{ item.modifiedUserData.userName }}</app-text>
                </template>

                <!--Action-->
                <template v-slot:[`item.actions`]="{ item }">
                    <v-btn @click="openItem(item.id, 'View', false)"
                           depressed class="primary--text white">Open
                        <v-icon color="primary" class="icons8-forward pa-0"/>
                    </v-btn>
                </template>

            </v-data-table>

            <!--Mobile Cards - Instead of table for small-screened devices-->
            <div v-if="$vuetify.breakpoint.width <= 600">
                <div v-for="item in filteredComputedTableData"
                       @click="openItem(item.id, 'View', false)" :key="item.id"
                       class="white rounded-lg">

                    <div class="d-flex align-center mt-4 pa-4">

                        <!--Status icons-->
                        <div class="flex-grow-0 mr-4">

                            <v-icon v-if="item.observationStatus === 'Pending'"
                                    color="error"
                                    class="icons8-inactive-state"
                                    size="32"/>

                            <v-icon v-if="item.observationStatus === 'In Progress'"
                                    color="orange"
                                    class="icons8-circle"
                                    size="32"/>

                            <v-icon v-if="item.observationStatus === 'Resolved'"
                                    color="success"
                                    class="icons8-checkmark-yes"
                                    size="32"/>

                        </div>

                        <!--Observation Details - (Name | Type | Date)-->
                        <div class="flex-grow-1">

                            <!--Observation name-->
                            <app-text category="text-default-bold">
                                {{ item.observationReportingShortDescription }}
                            </app-text>

                            <!--Observation type-->
                            <app-text category="text-small" class="grey--text">
                                {{ item.observationType }}
                            </app-text>

                            <!--Observation date-->
                            <app-text category="text-small-bold" class="grey--text noselect">
                                {{ item.createdDateTime }}
                            </app-text>

                        </div>

                        <!--Action button-->
                        <div class="flex-grow-0">
                            <v-icon @click="openItem(item.id, 'View', false)"
                                    class="icons8-forward" color="primary"/>
                        </div>

                    </div>

                </div>
            </div>

        </v-col>

        <!--Right Panel-->
        <transition name="custom-classes-transition"
                    enter-active-class="animate__animated animate__fadeIn animate__faster"
                    leave-active-class="animate__animated animate__fadeOut animate__faster"
                    mode="out-in">
            <rightpanel></rightpanel>
        </transition>

        <!--Columns Dialog Box-->
        <v-dialog v-model="columnsDialog" scrollable max-width="300px">
            <v-card>

                <!--Title-->
                <app-text category="text-large" class="primary--text pa-4">{{ $t('miscHeadings.showHideColumns') }}
                </app-text>

                <v-divider/>

                <!--List items-->
                <v-card-text>
                    <v-list>
                        <v-list-item v-for="(header, index) in headers.slice(0, -1)" :key="index">
                            <app-text>
                                <v-checkbox color="grey darken-1" hide-details v-model="headers[index].hidden"
                                            :false-value="true" :true-value="false"
                                            :label="header.text"></v-checkbox>
                            </app-text>
                        </v-list-item>
                    </v-list>
                </v-card-text>

                <v-divider/>

                <!--Close button-->
                <v-card-actions class="text-right">
                    <v-spacer/>
                    <v-btn color="primary" text @click="columnsDialog = false">Close</v-btn>
                </v-card-actions>

            </v-card>
        </v-dialog>

    </v-row>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'

export default {

    name: "Observations",

    data: () => ({
        title: "Observation",
        collection: "observations",
        collectionItem: "observation",
        collectionTitle: "Observations",
        collectionItemTitle: "Observation",
        columnsDialog: false,
        customWhere: [],
        exportObservations: [],
        filter: {
            // Default Filters
            sortAsc: false,
            status: ['Pending', 'In Progress', 'Resolved'],
            type: '',
        },
        headers: [
            {text: 'ID', value: 'id', sortable: false, align: 'left', hidden: true, hide: false, width: '20px'},
            {
                text: 'Status',
                value: 'observationStatus',
                sortable: false,
                align: 'left',
                hidden: false,
                hide: false,
                width: '69px'
            },
            {
                text: 'Issue',
                value: 'observationReportingShortDescription',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Type',
                value: 'observationType',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Site',
                value: 'observationSite',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Reported By',
                value: 'createdUserData',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: true,
            },
            {
                text: 'Reported On',
                value: 'createdDateTime',
                align: 'left',
                sortable: false,
                hidden: false,
                hide: false,
            },
            {
                text: 'Modified',
                value: 'modifiedDateTime',
                align: 'left',
                sortable: false,
                hidden: true,
                hide: false,
            },
            {
                text: 'Modified By',
                value: 'modifiedUserData',
                align: 'left',
                sortable: false,
                hidden: true,
                hide: false,
            },
            {text: '', value: 'actions', align: 'right', sortable: false, hidden: false, width: '134px'},
        ],
        headersCSV: {
            observationStatus: 'Observation Status',
            observationReportingShortDescription: 'Observation Name',
            observationType: 'Observation Type',
            observationSite: 'Observation Site',
            observationReportedBy: 'Reported By',
            observationReportedOn: 'Reported On',
            observationModified: 'Modified',
            observationModifiedBy: 'Modified By',
        },
        langPath: "observations",
        observationSeverities: [],
        observationTypes: [],
        search: '', // Table Search
        showDeleted: false,
        sortBy: 'createdDateTime', // Default Table Sort By
        tableData: [],
    }),

    computed: {
        ...mapGetters({
            GET_panelLayout: 'GET_panelLayout',
            GET_currentUser: 'GET_currentUser',
            GET_currentUserData: 'GET_currentUserData',
            GET_lookupValues: 'GET_lookupValues',
            GET_help: 'GET_help'
        }),

        //Format of the Export File
        formatExport() {
            const t = this
            const observations = JSON.parse(JSON.stringify(t.computedTableData));
            t.exportObservations = [];

            for (let i = 0; i < observations.length; i++) {
                const observationStatus = "";
                if (observations[i].observationStatus) {
                    observationStatus = observations[i].observationStatus;
                } else {
                    observationStatus = "UNKNOWN"
                }
                const observationName = "";
                if (observations[i].observationReportingShortDescription) {
                    observationName = observations[i].observationReportingShortDescription;
                } else {
                    observationName = "UNKNOWN"
                }
                const observationType = "";
                if (observations[i].observationType) {
                    observationType = observations[i].observationType;
                } else {
                    observationType = "UNKNOWN"
                }
                const observationSite = "";
                if (observations[i].observationSite.siteName) {
                    observationSite = observations[i].observationSite.siteName;
                } else {
                    observationSite = "UNKNOWN"
                }
                const observationReportedBy = "";
                if (observations[i].createdUserData.userName) {
                    observationReportedBy = observations[i].createdUserData.userName;
                } else {
                    observationReportedBy = "UNKNOWN"
                }
                const observationReportedOn = "";
                if (observations[i].createdDateTime) {
                    observationReportedOn = observations[i].createdDateTime;
                } else {
                    observationReportedOn = "UNKNOWN"
                }
                const observationModified = "";
                if (observations[i].modifiedDateTime) {
                    observationModified = observations[i].modifiedDateTime;
                } else {
                    observationModified = "UNKNOWN"
                }
                const observationModifiedBy = "";
                if (observations[i].modifiedUserData.userName) {
                    observationModifiedBy = observations[i].modifiedUserData.userName;
                } else {
                    observationModifiedBy = "UNKNOWN"
                }

                t.exportObservations.push({
                    observationStatus: observationStatus,
                    observationName: observationName,
                    observationType: observationType,
                    observationSite: observationSite,
                    observationReportedBy: observationReportedBy,
                    observationReportedOn: observationReportedOn,
                    observationModified: observationModified,
                    observationModifiedBy: observationModifiedBy,
                })
            }
            return t.exportObservations;
        },

        // Computed Table Headers
        computedHeaders() {
            const t = this;
            const headers = t.headers;
            return (t.$filter(headers, {
                hidden: false, // filter headers based on initial hidden value
            }));
        },

        // Computed Table Data
        computedTableData() {
            const t = this;

            let tableData = t.tableData;

            // Filter by status
            tableData = tableData.filter(observation => {
                return t.filter.status.includes(observation.observationStatus)
            })

            // Filter by type
            if (t.filter.type) {
                tableData = tableData.filter(observation => {
                    return observation.observationType === t.filter.type
                })
            }

            const tableDataList = tableData.map(e => {
                return {
                    id: e.id,
                    observationReportingShortDescription: e.observationReportingShortDescription,
                    observationType: e.observationType,
                    observationSite: e.observationSite,
                    observationStatus: e.observationStatus,
                    createdDateTime: t.MIX_formatDateTime(e.createdDateTime, 'x', 'Do MMM YY @ HH:mm'),
                    createdUserData: e.createdUserData,
                    modifiedDateTime: t.MIX_formatDateTime(e.modifiedDateTime, 'x', 'Do MMM YY @ HH:mm'),
                    modifiedUserData: e.modifiedUserData,
                };
            });

            // Sort the Table by the Field in the Sort By Filter
            const sortResults = t.$sortBy(tableDataList, t.sortBy)
            if (!t.filter.sortAsc) {
                sortResults.reverse()
            }
            return (sortResults);
        },

        /**
         * Filtered Computed Table Data
         *
         * If the user is a manager, they can see the observations they're responsible for, and their own.
         * Take the computedTableData and return an array of observations filtered by the search string.
         *
         * @returns array - observations filtered by search string
         */
        filteredComputedTableData() {
            const t = this
            let observationsData = t.computedTableData

            // Filter by search
            if (t.search) {
                observationsData = t.computedTableData.filter(observation => observation.observationReportingShortDescription.toUpperCase().match(t.search.toUpperCase()))
            }

            // Managers can see the observations they're responsible for, and their own
            if (t.GET_currentUser.userRole === 'Manager') {
                observationsData = observationsData.filter(observation =>
                    t.GET_currentUser.userObservationResponsibilities.includes(observation.observationType)
                    || observation.createdUserData.userId === t.GET_currentUser.id)
            }

            return observationsData
        }
    },

    methods: {
        ...mapActions({
            ACT_openItem: "ACT_openObservation",
            ACT_openObservation: "ACT_openObservation",
            ACT_areas: "ACT_areas",
        }),

        // UPDATE BREADCRUMBS
        updateBreadcrumbs() {
            const t = this
            const breadcrumbs = []

            breadcrumbs.push({name: `${t.collectionTitle}`, path: `/${t.collection}`}) // Set the Main Path
            t.MIX_breadcrumbs(breadcrumbs)
        },

        // OPEN ITEM
        async openItem(id, process, popup) {
            const t = this
            const item = {}

            t.dynamicComponentKey++
            item.process = process
            item.popup = popup

            if (item.process !== 'New') {
                const itemResult = await t.MIX_readDocumentById('observations', id)
                item.data = itemResult.data
            } else {
                item.data = null
            }

            t.ACT_openItem(item)

            if (t.$vuetify.breakpoint.lgAndDown) {
                t.MIX_fsDialog(true, t.collectionItemTitle, t.collectionItem)
                t.MIX_setPanelLayout(12, 0, false, '')
            } else {
                t.MIX_setPanelLayout(6, 6, true, t.collectionItemTitle, t.collectionItem)

                // * HIDE HEADERS
                for (let i = 0; i < t.headers.length; i++) {
                    if (t.headers[i].hide === true) {
                        t.headers[i].hidden = true
                    }
                }
            }

        },

        /**
         * Get Table Data
         *
         * Get all Concerns data from the database and add it to the tableData array for rendering.
         * A 'Staff User' can only view their own Concerns, but a 'Staff Admin' can view all the concerns.
         *
         * @returns {Promise<void>}
         */
        async getTableData() {
            const t = this

            // Get Concerns collection data
            let collection = await t.$firebase.db.collection('observations')

            // If the user is a Staff User (SU), and
            if (['CU', 'SU'].includes(t.GET_currentUser.userLevel) &&
                JSON.stringify(t.customWhere) !== '[]') {

                // Apply the custom where clause
                const customWhereLength = t.customWhere.length
                for (let i = 0; i < customWhereLength; i++) {
                    const query = {
                        field: t.customWhere[i].key,
                        operator: t.customWhere[i].operator,
                        value: t.customWhere[i].value
                    }
                    collection = collection.where(query.field, query.operator, query.value)
                }
            }

            collection.onSnapshot(querySnapshot => {

                // Clear the tableData to avoid duplication
                t.tableData = []

                // Iterate over the collection
                querySnapshot.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    // If the document is not marked as deleted, add it to the tableData array
                    if (!document.hasOwnProperty('delete')) {
                        t.tableData.push(document)
                    } else if (t.showDeleted) {
                        t.tableData.push(document)
                    } else {
                        // Do not Show Concern
                    }
                })

            })
        },

    },

    watch: {

        GET_currentUser: {
            handler() {
                this.getTableData()
            }, deep: true
        },

        GET_panelLayout: {
            handler() {
                const t = this

                if (t.GET_panelLayout.show === false) {
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = false
                        }
                    }
                } else {
                    for (let i = 0; i < t.headers.length; i++) {
                        if (t.headers[i].hide === true) {
                            t.headers[i].hidden = true
                        }
                    }
                }
            }, deep: true
        },

    },

    async mounted() {
        const t = this

        t.MIX_setPanelLayout(12, 0, false, ''); // Hide Right Panel
        t.updateBreadcrumbs(); // Update Breadcrumbs in Title

        // Additional WHERE Clauses for Snapshot
        // TODO customer where should be only for user when not admin
        t.customWhere.push({key: 'createdUserData.userId', operator: '==', value: t.GET_currentUserData.userId});

        await t.getTableData(); // Get Table Data
        await t.MIX_sites(); // UPDATE SITES LIST IN STORE

        // * AUTO OPEN SITE IF PATH CONTAINS SITE ID
        if ((window.location.pathname !== `/${t.collection}` && window.location.pathname !== `/${t.collection}/`)) {
            const urlPath = window.location.pathname.split('/');
            const id = urlPath[2]
            if (id === 'new') {
                t.openItem('', 'New', false)
            } else {
                t.openItem(id, 'View', false)
            }
        }

    },

}
</script>
