<!--
Complete Profile/Schedule Home Page Card

Renders a Home page card to prompt the user to complete their profile and/or schedule.
-->
<template>
    <v-expansion-panels flat>
        <v-expansion-panel class="rounded-lg">

            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex">

                    <!--Icon-->
                    <v-icon class="icons8-private ma-n1" color="error" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">
                        Complete Your Profile
                    </app-text>

                </div>

                <!--Message-->
                <app-text class="mt-4">
                    Please update your {{ computedUpdateMessage }} before you continue
                </app-text>

            </v-expansion-panel-header>

            <v-expansion-panel-content class="expansion-panel-content-container">

                <div :class="$vuetify.breakpoint.width > 400 && 'd-flex'">

                    <v-spacer/>

                    <!--Button-->
                    <div v-if="!GET_currentUser.usualSite && !['CU', 'VU'].includes(GET_currentUser.userLevel)"
                         class="text-right mt-4">
                        <app-btn @click.native="MIX_go('/userProfile')"
                                 label="Update Profile"/>
                    </div>

                    <!--Button-->
                    <div v-if="scheduleError && !['CU', 'VU'].includes(GET_currentUser.userLevel)"
                         class="text-right mt-4 ml-4">
                        <app-btn @click.native="MIX_go('/userSchedule')"
                                 label="Schedule"/>
                    </div>

                </div>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import {mapGetters} from "vuex";

export default {

    name: "completeProfileHomePageCard",

    props: ['scheduleError'],

    computed: {
        ...mapGetters({
            GET_currentUser: 'GET_currentUser',
        }),

        computedUpdateMessage() {
            const t = this
            let message
            const scheduleError = t.$props.scheduleError
            const usualSite = t.GET_currentUser.usualSite

            if (!usualSite && scheduleError) {
                message = 'profile and schedule'
            } else if (!usualSite) {
                message = 'profile'
            } else if (scheduleError) {
                message = 'schedule'
            }

            return message

        }

    }

}
</script>

<style scoped>

</style>
