<!--
HOME

Renders the home screen which contains cards for Covid related flows and quick link around the app.
-->
<template>
    <v-row class="pa-4" no-gutters>

        <!--Welcome User-->
        <welcome-user-home-page-card/>

        <!--Left side-->
        <v-col v-if="isContractorApprovedAndPermitted"
               :class="$vuetify.breakpoint.width < 840 && 'pr-2'"
               :cols="splitWindowWidth">

            <!--Getting started-->
            <getting-started-home-page-card class="mt-4"/>

            <!--Complete Profile/Schedule-->
            <complete-profile-home-page-card v-if="computedIsProfileIncomplete"
                                             class="mt-4"
                                             :scheduleError="scheduleError"/>

            <!--Covid Screening Questions-->
            <covid-questions2 v-if="computedIsAbleToAnswerCovidQuestions"
                              class="mt-4"
                              v-on:isSwappEnabled="emittedIsSwappEnabled"
                              v-on:emitExistingCovidTemperature="emittedExistingCovidTemperature"/>

            <!--Covid Temperature-->
            <covid-temperature v-if="computedIsAbleToRecordTemperature"
                               class="mt-4"
                               :isSwappEnabled="isSwappEnabled"
                               :todaysCovidTemperature="todaysCovidTemperature"
                               v-on:emitNewCovidTemperature="emittedNewCovidTemperature"/>

            <!--SWAPP In-->
            <div
                v-if="
                ((GET_currentUser.usualSite && !scheduleError) || ['CU', 'VU'].includes(GET_currentUser.userLevel))
                &&
                GET_currentUser.swappStatus === 0
                &&
                (
                    (!GET_currentUser.userAccessAndAbilities.includes('Sign in from Desktop') && $vuetify.breakpoint.width < 840)
                    ||
                    (GET_currentUser.userAccessAndAbilities.includes('Sign in from Desktop'))
                )"
                class="mt-4">
                <v-card class="d-flex flex-column rounded-lg pa-4" flat>

                    <!--Icon | Title-->
                    <div class="d-flex align-center">

                        <!--QR code-->
                        <v-icon class="icons8-qr-code ma-n1" color="accent" size="48"/>

                        <!--Title-->
                        <app-text category="text-medium-bold" class="primary--text ml-4">
                            SWAPP In
                        </app-text>

                    </div>

                    <!--Message-->
                    <app-text class="mt-4">
                        When you arrive at Site you must SWAPP In at a location
                    </app-text>
                    <app-text class="grey--text mt-4">
                        You must answer the Covid questions {{ GET_currentUser.userType.toUpperCase() === 'PLAYER' ? 'and record your temperature first' : ' first' }}
                    </app-text>

                    <!--Button-->
                    <app-btn @click.native="MIX_go('/swapp/in')"
                             block
                             class="mt-4"
                             :disabled="!computedIsAbleToSwappIn"
                             label="SWAPP In"/>

                </v-card>
            </div>

            <!--SWAPP Out-->
            <div
                v-if="GET_currentUser.swappStatus === 1 && ((!GET_currentUser.userAccessAndAbilities.includes('Sign in from Desktop') && $vuetify.breakpoint.width < 840) || (GET_currentUser.userAccessAndAbilities.includes('Sign in from Desktop')))"
                class="mt-4">
                <v-card class="d-flex flex-column rounded-lg pa-4" flat>

                    <!--Icon | Title-->
                    <div class="d-flex align-center">

                        <!--QR code-->
                        <v-icon class="icons8-qr-code ma-n1" color="accent" size="48"/>

                        <!--Title-->
                        <app-text category="text-medium-bold" class="primary--text ml-4">
                            SWAPP Out
                        </app-text>

                    </div>

                    <!--Message-->
                    <app-text class="mt-4">
                        When you leave a Site you must SWAPP out of the Location
                    </app-text>

                    <!--Button-->
                    <app-btn
                        @click.native="MIX_go('/swapp/out')"
                        block
                        class="mt-4"
                        label="SWAPP Out"/>

                </v-card>
            </div>

            <!--Schedule-->
            <user-scedule-home-page-card
                v-if="(GET_currentUser.usualSite && !scheduleError) && !['CU'].includes(GET_currentUser.userLevel)"
                class="mt-4"
                :scheduleData="scheduleData"
                :scheduleError="scheduleError"
                :tomorrowsScheduleData="tomorrowsScheduleData"/>

            <!--Fire Roll Call-->
            <fire-roll-call-home-page-card class="mt-4"/>

        </v-col>
        <v-col v-if="!isContractorApprovedAndPermitted" class="mt-8">
            <div class="d-flex">
                <v-icon class="icons8-private mr-4" color="error" size="48"/>
                <app-text>
                    You need a valid Site Permit before you can SWAPP in
                </app-text>
            </div>
        </v-col>

        <!--Right side (Awaiting approval)-->
        <v-col v-if="GET_currentUser.userLevel === 'SA'"
               :class="$vuetify.breakpoint.width < 840 ? 'mt-4' : 'mt-4 pl-2'" :cols="splitWindowWidth">

            <!--Users awaiting approval-->
            <awaiting-approval-home-page-card
                :usersAwaitingApproval="usersAwaitingApproval"
                :usersAwaitingApprovalMessage="usersAwaitingApprovalMessage"/>

            <!--Contractors awaiting Site Permit approval-->
            <!--<site-permits-home-page-card class="mt-4"/>-->

        </v-col>

    </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import covidQuestions from "./commonComponents/covidQuestions";
import covidQuestions2 from "./commonComponents/covidQuestions2";
import covidTemperature from "../views/home/covidTemperature/covidTemperature";
import FireRollCallHomePageCard from "../views/fireRollCall/fireRollCallHomePageCard/fireRollCallHomePageCard";
import ItSupportHomePageCard from "../views/itSupport/itSupportHomePageCard/itSupportHomePageCard";
import UserSceduleHomePageCard from "../views/userSchedule/userScheduleHomePageCard/userScheduleHomePageCard";
import AwaitingApprovalHomePageCard from "../views/home/awaitingApprovalHomePageCard/awaitingApprovalHomePageCard";
import GettingStartedHomePageCard from "../views/home/gettingStartedHomePageCard/gettingStartedHomePageCard";
import CompleteProfileHomePageCard from "../views/home/completeProfileHomePageCard/completeProfileHomePageCard";
import SitePermitsHomePageCard from "../views/home/sitePermitsHomePageCard/sitePermitsHomePageCard";
import WelcomeUserHomePageCard from "../views/home/welcomeUserHomePageCard/welcomeUserHomePageCard";

export default {

    name: "Home",

    components: {
        WelcomeUserHomePageCard,
        SitePermitsHomePageCard,
        CompleteProfileHomePageCard,
        GettingStartedHomePageCard,
        AwaitingApprovalHomePageCard,
        UserSceduleHomePageCard,
        ItSupportHomePageCard,
        FireRollCallHomePageCard,
        covidQuestions,
        covidQuestions2,
        covidTemperature,
    },

    data: () => ({
        collection: 'answers',
        collectionItemTitle: 'Answer',
        isErrorMessageVisible: false,
        isPhotoUploadDisabled: false,
        isSwappEnabled: false,
        scheduleData: [],
        scheduleError: '',
        sitesData: [],
        tomorrowsScheduleData: {},
        usersCollectionData: [],
        usersUsualSite: '',

        todaysCovidTemperature: '',
    }),

    computed: {
        ...mapGetters({
            GET_currentUser: "GET_currentUser",
            GET_photoUploadResult: 'photoUpload_store/GET_photoUploadResult',
        }),

        /**
         * Split Window Width
         *
         * Split the layout either 50/50 or 100 based on the screen width.
         *
         * @returns width - number of columns each side (left and right) should span
         */
        splitWindowWidth() {
            const t = this
            let width

            if (t.$vuetify.breakpoint.width <= 840) {
                width = 12
            } else {
                width = 6
            }

            return width
        },

        /**
         * Users Awaiting Approval Message
         *
         * Take the number of users awaiting approval and return a grammatically correct string to render.
         *
         * @returns message - message to render to screen
         */
        usersAwaitingApprovalMessage() {
            const t = this
            let message

            if (t.usersAwaitingApproval.length === 1) {
                message = `You have ${t.usersAwaitingApproval.length} account awaiting approval`
            } else {
                message = `You have ${t.usersAwaitingApproval.length} accounts awaiting approval`
            }

            return message
        },

        /**
         * Users Awaiting Approval
         *
         * Filter all users and return an array containing just those with a 'PENDING' status, awaiting approval.
         *
         * @returns array - array containing users with a 'PENDING' status
         */
        usersAwaitingApproval() {
            const t = this

            return t.usersCollectionData.filter(user => user.userStatus.toUpperCase() === 'PENDING')
        },

        /**
         * Is Contractor Approved and Permitted
         *
         * If the current User is a Contractor, their user status and site permit status must be approved.
         *
         * @returns {boolean}
         */
        isContractorApprovedAndPermitted() {
            const t = this
            let isValid = true
            const user = t.GET_currentUser

            if (user.userType === 'Contractor' && user.sitePermitStatus !== 'Approved') {
                isValid = false
            }

            return isValid
        },

        /**
         * Computed Is Profile Incomplete
         *
         * To have a complete profile, the User must:
         *  - Set a usual site
         *  - Set a schedule
         *
         *  These restrictions do not apply to Contractor Users (CU) or Visitor Users (VU).
         *
         * @returns {boolean} if the profile is complete
         */
        computedIsProfileIncomplete() {
            const t = this
            let isComplete

            isComplete = !!((!t.GET_currentUser.usualSite || t.scheduleError) && !['CU', 'VU'].includes(t.GET_currentUser.userLevel));

            return isComplete
        },

        /**
         * Computed Is Able To Answer Covid Questions
         *
         * To be able to answer the Covid questions, the User must:
         *  - Have a complete profile
         *  - Be SWAPP'd out
         *  - Be on a mobile device
         *
         * @returns {boolean} if the User is allowed to answer the Covid questions
         */
        computedIsAbleToAnswerCovidQuestions() {
            const t = this
            let isAbleToAnswer

            isAbleToAnswer = !t.computedIsProfileIncomplete && t.GET_currentUser.swappStatus === 0 && t.$vuetify.breakpoint.width < 840;

            return isAbleToAnswer
        },

        /**
         * Computed Is Able To Record Temperature
         *
         * To be able to record their temperature, the User must:
         *  - Be Player
         *  - Have a complete profile
         *  - Be SWAPP'd out
         *  - Be on a mobile device
         *
         * @returns {boolean} if the User is allowed to record their temperature
         */
        computedIsAbleToRecordTemperature() {
            const t = this
            const currentUser = t.GET_currentUser
            let isAbleToRecord

            isAbleToRecord = currentUser.userType.toUpperCase() === 'PLAYER' && !t.computedIsProfileIncomplete && t.GET_currentUser.swappStatus === 0 && t.$vuetify.breakpoint.width < 840;

            return isAbleToRecord
        },

        /**
         * Computed Is Able To SWAPP In
         *
         * To be able
         *
         * @returns {boolean} if the User is allowed to SWAPP in
         */
        computedIsAbleToSwappIn() {
            const t = this
            const currentUser = t.GET_currentUser.userType
            let isAbleToSwapp

            if (currentUser.toUpperCase() === 'PLAYER') {
                isAbleToSwapp = !!(t.isSwappEnabled && t.todaysCovidTemperature && t.todaysCovidTemperature <= 37.8);
            } else {
                isAbleToSwapp = !!t.isSwappEnabled;
            }

            return isAbleToSwapp
        },

    },

    methods: {

        /**
         * Get Schedule Collection
         *
         * Fetch the current user's data from the Schedule collection.
         * If there is a schedule present, call for tomorrow's data to render.
         * If there is no schedule present, set a message.
         *
         * @returns {Promise<void>}
         */
        async getScheduleCollectionData() {
            const t = this

            // Get collection data for the current user
            const collection = await t.$firebase.db.collection('schedule').doc(t.GET_currentUser.id)
            const doc = await collection.get()

            // If the data exists, set it and call for tomorrow's schedule
            if (doc.exists) {
                t.scheduleData = doc.data()
                t.getTomorrowsScheduleData(doc.data())
            }

            // If the data does not exist, set an error
            else {
                t.scheduleError = 'Sorry, couldn\'t find your schedule'
            }
        },

        /**
         * Get Sites Collection
         *
         * Fetch all the data from the Sites collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getSitesCollectionData() {
            const t = this

            await t.$firebase.db.collection('sites')
                .onSnapshot(snapshot => {

                    // Reset sites to avoid duplications
                    t.sitesData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add documents that aren't marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            t.sitesData.push(document)
                        }
                    })
                })
        },

        /**
         * Get Users Collection Data
         *
         * Fetch all the data from the Users collection.
         * Iterate over the collection and only push documents that aren't marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            let collection = await t.$firebase.db.collection('users')

            // If user is not admin ('SA'), only get the current user data
            if (!['SA'].includes(t.GET_currentUser.userLevel)) {
                collection = collection.where("id", "==", t.GET_currentUser.id)
            }

            collection.get().then(user => {

                // Reset data to avoid duplications
                t.usersCollectionData = []

                user.forEach(doc => {

                    const document = doc.data()
                    document.id = doc.id

                    if (t.GET_currentUser.id === document.id) {

                        // Set usual site so the Complete Profile card doesn't hang
                        if (document.usualSite) {
                            t.GET_currentUser.usualSite = document.usualSite
                        }

                        // Update the user
                        t.$store.commit('SET_currentUser', document)
                    }

                    // Only add documents that aren't marked as deleted
                    if (!document.hasOwnProperty('delete')) {
                        t.usersCollectionData.push(document)
                    }

                })
            })
        },

        /**
         * Get Tomorrow's Schedule Data
         *
         * The schedule card's text needs to show where the user is the next day (tomorrow).
         * Iterate over the schedule data and show the next working day (mon-fri)
         *
         * @param scheduleData - the current users work schedule
         */
        getTomorrowsScheduleData(scheduleData) {
            const t = this

            // Get the day tomorrow
            const tomorrowsDate = t.$moment().add(1, 'day').format('dddd')

            // If tomorrow doesn't fall on the weekend
            if (['Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(tomorrowsDate)) {

                // Iterate over the schedule
                for (const [key, value] of Object.entries(scheduleData)) {

                    // If tomorrow is in the schedule data, set the value
                    if (key.toUpperCase() === tomorrowsDate.toUpperCase()) {
                        t.tomorrowsScheduleData = value
                    }
                }
            }

            // If tomorrow falls on the weekend, set it to Monday
            else {
                t.tomorrowsScheduleData = scheduleData.monday
            }

        },

        /**
         * Toggle Error Message Visibility
         */
        toggleErrorMessageVisibility() {
            const t = this

            t.isErrorMessageVisible = true

            setTimeout(() => {
                t.isErrorMessageVisible = false
            }, 3000)
        },

        /**
         * Render Confirmation Alert
         *
         * Take the response of creating a document and render:
         *  -> 'Success' alert if all is ok
         *  -> 'Error' alert if something when wrong
         */
        renderConfirmationAlert(document) {
            const t = this

            if (document.code === 1) {
                t.MIX_alert(1, `${t.collectionItemTitle} submitted`, null, null)
            } else {
                t.MIX_alert(-1, `Error submitting ${t.collectionItemTitle}`, null, null)
            }
        },

        /**
         * Emitted Is Swapp Enabled
         *
         * Payload is emitted from covidQuestions.vue and is a boolean based on the answers to the questions.
         * In this component, it is used to determine whether or not the SWAPP IN button is clickable.
         */
        emittedIsSwappEnabled(payload) {
            const t = this

            t.isSwappEnabled = payload
        },

        /**
         * Emitted Today's Covid Temperature
         *
         *
         *
         * @param temperature
         */
        emittedExistingCovidTemperature(temperature) {
            const t = this

            if (t.GET_currentUser.userType.toUpperCase() !== 'PLAYER') {
                t.todaysCovidTemperature = 'Not Required'
            } else {
                t.todaysCovidTemperature = temperature
            }

        },

        emittedNewCovidTemperature(temperature) {
            const t = this

            t.todaysCovidTemperature = temperature
        },

    },

    watch: {
        GET_photoUploadResult: {
            handler: async function () {
                const t = this
                const photoUploadResult = this.GET_photoUploadResult

                // If there is data, update the current user's profile picture
                if (photoUploadResult !== {}) {

                    // Save to the document with: collection | user id | image path
                    const updatePhotosResult = await this.MIX_updateDocumentFieldsById(
                        'users', photoUploadResult.docLink, {profilePicFileURL: photoUploadResult.fileURL})

                    // Render confirmation box
                    if (updatePhotosResult.code === 1) {
                        t.isPhotoUploadDisabled = true
                        this.MIX_alert(1, 'Photo Added', updatePhotosResult.data, null)
                    } else {
                        this.MIX_alert(-1, 'Error Adding Photo', null, updatePhotosResult.error)
                    }
                }
            }, deep: true
        },
    },

    async mounted() {
        const t = this

        // Get collection data
        await t.getScheduleCollectionData()
        await t.getSitesCollectionData()
        await t.getUsersCollectionData()

    },

};
</script>

<style>
.expansion-panel-content-container > div {
    padding: 16px;
}
</style>
