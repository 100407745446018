<!--
Covid Answers

Render the Covid Answer user cards with filters.
-->
<template>
    <v-row class="fill-height" no-gutters>

        <!--Show loader whilst loading data-->
        <v-row v-if="isPageLoading" class="justify-center align-center fill-height" no-gutters>
            <v-progress-circular
                color="primary"
                indeterminate
                size="96"/>
        </v-row>

        <v-row v-if="!isPageLoading" class="pa-4" no-gutters>
            <v-col>

                <v-col class="d-flex pa-0">

                    <!--Page title-->
                    <page-title icon="icons8-coronavirus" page-title="Covid"/>

                    <app-text style="margin-right: 3px">
                        All
                    </app-text>

                    <!-- Toggle Covid Questions answered today -->
                    <v-switch v-model="filterByCovidAnsweredToday"/>

                    <app-text style="margin-right: 3px">
                        Answered
                    </app-text>

                </v-col>

                <!--Instructional text-->
                <v-col class="pa-0" cols="12">
                    <app-text>
                        Use this page to see which Users have passed or failed Covid-19 Screening today.
                        You can edit a failed Covid-19 Screening by clicking the 'COVID' button on the User card and then clicking the pen icon.
                    </app-text>
                </v-col>

                <!--Divider-->
                <v-col class="pa-0" cols="12">
                    <v-divider class="my-4"/>
                </v-col>

                <!--Filters-->
                <v-row no-gutters>

                    <!--Filter by Name-->
                    <v-col :class="$vuetify.breakpoint.width < 672 ? 'pr-0 mb-2' : 'pr-2 mb-2'"
                           :cols="$vuetify.breakpoint.width < 672 ? 12 : $vuetify.breakpoint.width < 1460 ? 6 : 3">

                        <app-input input-type="textInput"
                                   clearable
                                   label="Name"
                                   v-model="filterByName"/>

                    </v-col>

                    <!--User Type-->
                    <v-col :class="$vuetify.breakpoint.width < 672 ? 'pr-0 mb-2' : $vuetify.breakpoint.width < 1460 ? 'pl-2 pr-0 mb-2' : 'px-2'"
                           :cols="$vuetify.breakpoint.width < 672 ? 12 : $vuetify.breakpoint.width < 1460 ? 6 : 3">

                        <app-input input-type="select"
                                   clearable
                                   :items="userTypesOptionsDataExtended"
                                   label="User Type"
                                   v-model="filterByUserType"/>

                    </v-col>

                    <!--Position-->
                    <v-col :class="$vuetify.breakpoint.width < 672 ? 'pr-0 mb-2' : $vuetify.breakpoint.width < 1460 ? 'mb-0 pl-0 pr-2' : 'mb-0 px-2'"
                           :cols="$vuetify.breakpoint.width < 672 ? 12 : $vuetify.breakpoint.width < 1460 ? 6 : 3">

                        <app-input input-type="select"
                                   clearable
                                   :items="uniquePositions"
                                   label="Position"
                                   v-model="filterByPosition"/>

                    </v-col>

                    <!--SWAPP Status Covid Screening-->
                    <v-col :class="$vuetify.breakpoint.width < 672 ? 'pr-0' : 'pl-2'"
                           class="d-flex"
                           :cols="$vuetify.breakpoint.width < 672 ? 12 : $vuetify.breakpoint.width < 1460 ? 6 : 3">

                        <app-input input-type="select"
                                   class="pr-2"
                                   clearable
                                   :items="['In', 'Out']"
                                   label="SWAPP"
                                   style="flex-grow: 1"
                                   v-model="filterBySwappStatus"/>

                        <app-input input-type="select"
                                   class="pl-2"
                                   clearable
                                   :items="['Passed', 'Failed']"
                                   label="Covid Screening"
                                   style="flex-grow: 1"
                                   v-model="filterByCovidScreeningResult"/>

                    </v-col>

                </v-row>

                <!--Users-->
                <div class="cvd-userGrid">
                    <div v-for="user in computedFilteredUsers" :key="user.id">

                        <covid-user-card :user="user" :numberOfQuestions="calculateNumberOfQuestions()"/>

                    </div>
                </div>

            </v-col>
        </v-row>

    </v-row>
</template>

<script>
import covidUserCard from "./covidPlayerCard/covidUserCard";

export default {

    name: "covidAnswers",

    components: {
        covidUserCard,
    },

    data: () => ({

        answersCollectionData: [],
        filterByCovidAnsweredToday: true,
        filterByCovidScreeningResult: '',
        filterByName: '',
        filterByPosition: '',
        filterBySwappStatus: null,
        filterByUserType: '',
        isPageLoading: false,
        questionsCollectionData: [],
        uniquePositions: [],
        usersCollectionData: [],
        usersAndAnswersData: [],

    }),

    computed: {

        /**
         * Computed Filtered Users
         *
         * Filter the list of users and return the results.
         * Filters by:
         *  - Name search
         *  - User Type
         *  - Player Position (if applicable)
         *  - Swapp Status
         *  - Covid Screening Status (Passed or failed)
         *  - Answered Covid Screening Today
         * @returns {Array} list of filtered users to render
         */
        computedFilteredUsers() {
            const t = this
            let filteredUsers = t.usersAndAnswersData

            // Name search
            if (t.filterByName) {
                filteredUsers = filteredUsers.filter(
                    user => user.userName.toUpperCase().match(t.filterByName.toUpperCase()))
            }

            // User Type
            if (t.filterByUserType) {

                // Warriors (Men)
                if (t.filterByUserType === 'Warriors') {
                    filteredUsers = filteredUsers.filter(
                        user => user.userType === 'Player' && user.warriorsTeam === 'Mens')
                }

                // Warriors (Women)
                if (t.filterByUserType === 'Warriors Women') {
                    filteredUsers = filteredUsers.filter(
                        user => user.userType === 'Player' && user.warriorsTeam === 'Womens')
                }

                // Everyone else
                if (t.filterByUserType !== 'Warriors' && t.filterByUserType !== 'Warriors Women') {
                    filteredUsers = filteredUsers.filter(
                        user => user.userType === t.filterByUserType)
                }
            }

            // Position search
            if (t.filterByPosition) {
                const usersWithPlayerPosition = filteredUsers.filter(user => user.playerPosition)
                filteredUsers = usersWithPlayerPosition.filter(user => user.playerPosition === t.filterByPosition)
            }

            // Swapp Status
            if (t.filterBySwappStatus) {
                const ss = t.filterBySwappStatus === 'Out' ? 0 : 1
                filteredUsers = filteredUsers.filter(user => user.swappStatus === ss)
            }
            // Answered covid screening questions today
            if (t.filterByCovidAnsweredToday) {
                filteredUsers = filteredUsers.filter(user => user.answers.length > 0)

                // Successfully passed covid screening. (Users that have a temperature of <= 37.8)
                if(t.filterByCovidScreeningResult === 'Passed') {
                    filteredUsers = filteredUsers.filter(user => user.answers[0].temperature <= 37.8)
                }
                // Failed covid screening today. (Users with no temperature or a temperature of > 37.8)
                else if(t.filterByCovidScreeningResult === 'Failed') {
                    filteredUsers = filteredUsers.filter(user => user.answers[0].temperature > 37.8 || !user.answers[0].temperature)
                }
            }
            // Not answered covid screening today
            else if (!t.filterByCovidAnsweredToday) {
                filteredUsers = filteredUsers.filter(user => user.answers.length < 1)
            }

            // Sort by userName
            filteredUsers = filteredUsers.sort((a, b) => {
                return a.userName > b.userName ? 1 : -1
            })

            return filteredUsers
        }

    },

    methods: {

        /**
         * Get Answers Collection Data
         *
         * Fetch all the data from the collection and add to the data array.
         *
         * @returns {Promise<void>}
         */
        async getAnswersCollectionData() {
            const t = this
            const now = t.$moment()

            // Get the dateTime in milliseconds for 3am today.
            // This time is an hour after everyone is swapped out with a Firebase Function so it ensures only activity from today
            const dateTimeThisMorning = t.$moment().startOf('day').add(3, 'hours').format('x')

            // Fetch collection data
            await t.$firebase.db.collection('answers')
                .where('createdDateTime', '>', dateTimeThisMorning)
                .onSnapshot(snapshot => {

                    // Reset the data array to avoid duplications
                    t.answersCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        t.answersCollectionData.push(document)

                    })
                })
        },

        /**
         * Get Questions Collection Data
         *
         * Fetch all the data from the collection and add to the data array.
         *
         * @returns {Promise<void>}
         */
        async getQuestionsCollectionData() {
            const t = this

            // Fetch collection data
            await t.$firebase.db.collection('questions')
                .onSnapshot(snapshot => {

                    // Reset the data array to avoid duplications
                    t.questionsCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        t.questionsCollectionData.push(document)

                    })
                })
        },

        /**
         * Get Users Collection Data
         *
         * Fetch all the data from the collection and add to the data array.
         * Only add those that are not marked as deleted.
         *
         * @returns {Promise<void>}
         */
        async getUsersCollectionData() {
            const t = this

            // Fetch collection data
            await t.$firebase.db.collection('users')
                .onSnapshot(snapshot => {

                    // Reset the data array to avoid duplications
                    t.usersCollectionData = []

                    snapshot.forEach(doc => {

                        const document = doc.data()
                        document.id = doc.id

                        // Only add user that are not marked as deleted
                        if (!document.hasOwnProperty('delete')) {
                            t.usersCollectionData.push(document)
                        }

                    })
                })
        },

        /**
         * Match Answers To Users
         *
         * Take the User and Answers collection data and create a combined object for each user.
         * Also create the data to populate the Positions drop-down filter from the current selection.
         */
        matchAnswersToUsers() {
            const t = this
            let usersAndAnswersArr = []
            let userAndAnswersObj = {}
            let answers = []
            let positions = []

            // Jump out of the function if the collections haven't been fetched yet
            if (!t.answersCollectionData.length || !t.usersCollectionData.length) {
                return
            }

            // Loop through the Users collection
            t.usersCollectionData.forEach(user => {

                // If the user has a 'Player Position' field, push it to the array
                if (user.playerPosition) {
                    positions.push(user.playerPosition)
                }

                // Get all the answers for the current user
                answers = t.answersCollectionData.filter(answer => answer.createdUserData.userId === user.id)

                // Create an object with the user and answer data
                userAndAnswersObj = {...user, answers}

                // Push the new object for rendering
                usersAndAnswersArr.push(userAndAnswersObj)

            })

            // Create an array of unique Player Positions (this is for the Positions filter drop-down)
            t.uniquePositions = [...new Set(positions)].sort()

            // Assign the processed array to the data array
            t.usersAndAnswersData = usersAndAnswersArr

            // Cancel loading spinner
            t.isPageLoading = false

        },

        /**
         * Calculate Number Of Questions
         *
         * Calculate the current number of questions.
         * This is used to populate the users answers array/object with blank entries if they have not answered any
         * questions, otherwise, there will be nothing to display, which is bad for the UI and for UX.
         *
         * @returns {number} the number of current Covid Questions
         */
        calculateNumberOfQuestions() {
            const t = this

            return t.questionsCollectionData.length
        },

    },

    watch: {

        /**
         * Answers Collection Data
         *
         * Watch for the completion of fetching both collections so they can be processed.
         * N.B. This should've worked as the collection function calls are async/await but for some reason
         *      matchAnswersToUsers() is not waiting.
         */
        answersCollectionData() {
            const t = this

            if (t.answersCollectionData.length && t.usersCollectionData.length) {
                t.matchAnswersToUsers()
            }
        },

        /**
         * Users Collection Data
         *
         * Watch for the completion of fetching both collections so they can be processed.
         * N.B. This should've worked as the collection function calls are async/await but for some reason
         *      matchAnswersToUsers() is not waiting.
         */
        usersCollectionData() {
            const t = this

            if (t.answersCollectionData.length && t.usersCollectionData.length) {
                t.matchAnswersToUsers()
            }
        }

    },

    async mounted() {
        const t = this

        // Start the loading spinner, clears when matchAnswersToUsers() completes
        t.isPageLoading = true

        // Get collection data
        await t.getAnswersCollectionData()
        await t.getQuestionsCollectionData()
        await t.getUsersCollectionData()
        t.matchAnswersToUsers()

    }

}
</script>

<style>
.cvd-userGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: 16px;

    margin-top: 16px;
}
</style>
