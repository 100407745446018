<template>
    <v-expansion-panels v-if="GET_currentUser.userLevel !== 'VU'"
                        class="rounded-lg pa-0 mt-4" flat>
        <v-expansion-panel>

            <!--Icon | Title | Text -->
            <v-expansion-panel-header class="d-flex flex-column align-start px-4">

                <!--Icon | Title -->
                <div class="d-flex">

                    <!--Icon-->
                    <v-icon class="icons8-computer-support ma-n1" color="accent" size="48"/>

                    <!--Title-->
                    <app-text category="text-medium-bold" class="primary--text ml-4">
                        IT Support
                    </app-text>

                </div>

                <!--Message-->
                <app-text class="mt-4">
                    Request technical support
                </app-text>

            </v-expansion-panel-header>

            <!--Body-->
            <v-expansion-panel-content class="expansion-panel-content-container">

                <!--Button-->
                <div class="mt-4 text-right">
                    <app-btn
                        @click.native="MIX_go('/itSupport')"
                        color="primary"
                        label="Request Support"/>
                </div>

            </v-expansion-panel-content>

        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: "itSupportHomePageCard"
}
</script>

<style scoped>

</style>
